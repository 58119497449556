export const T_VALUES = ["T0", "T1", "T1a", "T1b", "T1c", "T2", "T2a", "T2b", "T2c", "T1c", "T3", "T3a", "T3b", "T4"];
export const N_VALUES = ["N0", "N1"];
export const M_VALUES = ["M0", "M1", "M1a", "M1b", "M1c"];

export const CANCER_TYPES = ["Breast Cancer", "Colon Cancer", "Lung Cancer", "Pancreatic Cancer", "Prostate Cancer", "Rectal Cancer"];

const POPULAR_GROUP = 'Popular Treatments';
const OTHER_GROUP = 'Other Treatments';
export const TREATMENTS = [
      {
        id: 't2',
        name: 'Radiotherapy: brachytherapy',
        group: POPULAR_GROUP,        
      },
      {
        id: 't3',
        name: 'Radiotherapy: external-beam radiation',
        group: POPULAR_GROUP,
      },
      {
        id: 't1',
        name: 'Surgery: prostatectomy',
        group: POPULAR_GROUP,
      },
      {
        id: 't4',
        name: 'Radiotherapy: external-beam radiation + Medical therapy: androgen-deprivation therapy',
        group: POPULAR_GROUP,
      },
      {
        id: 't5',
        name: 'Radiotherapy: external-beam radiation + Medical therapy: androgen-deprivation therapy2',
        group: OTHER_GROUP,
      }
];

export const TOOLTIP_CONTENTS = {
  profileMatch: {
      title: "About Profile Match",
      body: (
          <>
          Clinical studies are scientifically designed to have a set of specific criteria that individuals must meet in order to be considered eligible for participation, also known as eligibility criteria. Alpha Match, our AI-powered personal filter, automatically identifies studies for which you are eligible based on your specific diagnosis and/or treatment history, while excluding those for which you are not eligible.
          <br/>
          <br/>
          <b>Profile matched</b>: Clinical studies that match your specific diagnosis and/or treatment history. Your probability of being enrolled into the study is high. Note that you may undergo further screening by the study team for additional eligibility criteria.
          <br/>
          <br/>
          <b>Not eligible</b>: Clinical studies that do not match your specific diagnosis and/or treatment history. Your probability of being enrolled into the study is very low.
          <br/>
          <br/>
          <b>Lack of information</b>: Clinical studies whose eligibility criteria involve health information that is missing in your current health profile. You can reduce studies in this category by maintaining a complete and up-to-date health profile.
          </>
      )
  },
  recommendationLevel: {
      title: "About Recommendation Level",
      body: (
          <>
          Standard-of-care treatments are recommended at various levels in clinical guidelines by professional societies according to the magnitude and certainty of their benefits and risks, as well as the burdens they impose on patients. Treatments may receive a strong, moderate, or weak recommendation by balancing between benefits and risks/burdens.
          <br/>
          <br/>
          <b>Strong</b>: Benefits >>> risks/burdens (based on existing data from finished studies). Such treatments should be undertaken because the net benefit is substantial.
          <br/>
          <br/>
          <b>Moderate</b>: Benefits >> risks/burdens (based on existing data from finished studies). Such treatments should be undertaken because the net benefit is moderate.
          <br/>
          <br/>
          <b>Weak</b>: Benefits ≥ risks/burdens (based on existing data from finished studies). Such treatments may be considered, but the net benefit is unclear.

          </>
      )
  },
  socMatch: {
    title: "About Standard of Care Matching",
    body: (
        <>
        Standard of care refers to established treatments that are widely accepted and used by the medical community for specific patient situations. Alpha Match, our AI-powered personal filter, automatically identifies suitable standard-of-care treatments tailored to you, taking into account your medical history, current health status, and other relevant information.
        <br/>
        <br/>
        <b>Profile matched</b>: Standard-of-care treatments that match your health profile and are thus considered appropriate options for your situation.
        <br/>
        <br/>
        <b>Profile missing info</b>: Standard-of-care treatments for which key information regarding treatment suitability is currently missing in your health profile. Complete your health profile to see if the treatment is appropriate for your situation.
        <br/>
        <br/>
        <b>Not suitable</b>: Standard-of-care treatments that do not match your health profile and are thus not appropriate options for your situation.
        </>
    )
  },
  studyType: {
      title: "About Study Type",
      body: (
          <>
          Describes the nature of a clinical study. There are two main types of clinical studies: interventional studies and observational studies. Clinical trials are usually used to describe interventional studies, but it may be used broadly to describe all both types of clinical studies. Additionally, expanded access is sometimes set up for patients to gain access to investigational drugs, biologics, or medical devices outside clinical trials.
          <br/>
          <br/>
          <b>Interventional studies</b>: A type of clinical study in which participants are assigned to groups that receive one or more intervention/treatment (or no assigned intervention, only standard of care or placebo) so that researchers can evaluate the effects of the interventions on biomedical or health-related outcomes. Interventions include diagnostic, therapeutic, or other types.
          <br/>
          <br/>
          <b>Observational studies</b>: A type of clinical study in which participants, receiving standard-of-care without additional intervention, are observed and assessed for biomedical or health-related outcomes. Participants may receive diagnostic, therapeutic, or other types of interventions, but the research team does not influence what treatments participants receive.
          <br/>
          <br/>
          <b>Expanded access (compassionate use)</b>: A pathway for patients with serious diseases or conditions who cannot participate in a clinical trial to gain access to a medical product that has not been approved (thus not in the market yet) by the U.S. Food and Drug Administration (FDA).
          </>
      )
  },
  studyPhase: {
      title: "About Study Phase",
      body: (
          <>
          The stage of a clinical trial studying a drug or biological product, based on definitions developed by the U.S. Food and Drug Administration (FDA). It is based on the study’s objective, the number of participants, and other characteristics. There are five phases: Early Phase 1 (or Phase 0), Phase 1, Phase 2, Phase 3, and Phase 4. “Not applicable” is used to describe trials without FDA-defined phases, including trials of devices or behavioral interventions.
          <br/>
          <br/>
          <b>Early Phase 1</b>: A phase of research used to describe exploratory trials conducted before traditional phase 1 trials to investigate how or whether a drug affects the body. They involve very limited human exposure to the drug and have no therapeutic or diagnostic goals (for example, screening studies, microdose studies).
          <br/>
          <br/>
          <b>Phase 1</b>: A phase of research to describe clinical trials that focus on the safety of a drug. They are usually conducted with healthy volunteers, and the goal is to determine the drug's most frequent and serious adverse events and, often, how the drug is broken down and excreted by the body. These trials usually involve a small number of participants.
          <br/>
          <br/>
          <b>Phase 2</b>: A phase of research to describe clinical trials that gather preliminary data on whether a drug works in people who have a certain condition/disease (that is, the drug's effectiveness). For example, participants receiving the drug may be compared to similar participants receiving a different treatment, usually an inactive substance (called a placebo) or a different drug. Safety continues to be evaluated, and short-term adverse events are studied.
          <br/>
          <br/>
          <b>Phase 3</b>: A phase of research to describe clinical trials that gather more information about a drug's safety and effectiveness by studying different populations and different dosages and by using the drug in combination with other drugs. These studies typically involve more participants.
          <br/>
          <br/>
          <b>Phase 4</b>: A phase of research to describe clinical trials occurring after FDA has approved a drug for marketing. They include postmarket requirement and commitment studies that are required of or agreed to by the study sponsor. These trials gather additional information about a drug's safety, efficacy, or optimal use.
          </>
      )
  },
  intervention: {
      title: "About Intervention Type",
      body: (
          <>
          <b>Procedure/Surgery</b>: Involves a medical procedure or surgical operation. Examples include a biopsy, an endoscopy, or a surgical intervention.
          <br/>
          <br/>
          <b>Radiation</b>: Involves the use of high-energy radiation, such as X-rays or gamma rays, to kill cancer cells or other diseased tissue.
          <br/>
          <br/>
          <b>Drug</b>: Involves the administration of pharmaceutical substances for diagnosis, prevention, or treatment of a disease or condition. May be taken orally, injected, or applied to the skin.
          <br/>
          <br/>
          <b>Biological Product</b>: Involves the use of biological substances derived from living organisms, such as vaccines, antibodies, or gene therapies.
          <br/>
          <br/>
          <b>Diagnostic Test</b>: Involves the use of tests or procedures to identify or confirm the presence of a disease or condition. Examples include blood tests, imaging scans, or genetic testing.
          <br/>
          <br/>
          <b>Medical Device</b>: Involves the use of medical devices or equipment to prevent, diagnose, monitor, or treat a disease or condition. These can range from simple devices like blood pressure monitors to more complex ones like implantable pacemakers or prosthetics.
          <br/>
          <br/>
          <b>Genetic Test</b>: : Involve the manipulation or alteration of genetic material to understand, prevent, or treat diseases. Examples include gene therapy, gene editing techniques, or genetic screening.
          <br/>
          <br/>
          <b>Dietary Supplement</b>: Involves the use of vitamins, minerals, herbal products, or other nutritional substances.
          <br/>
          <br/>
          <b>Behavioral</b>: Behavioral interventions focus on modifying participants' behavior or psychological processes to promote health or prevent diseases. Examples include counseling, lifestyle interventions, cognitive-behavioral therapy, or educational programs.
          <br/>
          <br/>
          <b>Combination Product</b>: Involve the use of two or more different types of interventions (e.g., drug and device) in a single product or treatment approach. These interventions are designed to work together to achieve a specific outcome.
          <br/>
          <br/>
          <b>Other</b>: Interventions that do not fit into the aforementioned categories.
          </>
      )
  }
}