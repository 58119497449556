import React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Header from '../layouts/Header';
import SignInHandler from '../signin/SignInHandler';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from "@mui/material/Stack";
import { lightBlue } from '@mui/material/colors';
import { ThemeProvider } from '@mui/material/styles';
import { logoTheme } from '../../components/MUIOverrides';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useHistory } from "react-router-dom";
import { UserContext } from '../../App';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import { CANCER_TYPES } from "../utils/Constants";
import { Hidden } from '@mui/material';

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const HomeMain = ({handleAuthSuccess, handleSignOut}) => {
  const userContext = React.useContext(UserContext);
  const [condition, setCondition] = React.useState("Prostate Cancer");


  const isMobileView = useMediaQuery('(max-width:600px)');
  const history = useHistory();

  const onClickStandardOfCare = () => {
    const selectedCondition = condition.length === 0 ? CANCER_TYPES[0] : condition;
    history.push({
      pathname: 'standardOfCare',
      search: '?condition=' + selectedCondition
    })
  }

  const onClickTrials= () => {
    const selectedCondition = condition.length === 0 ? CANCER_TYPES[0] : condition;
    history.push({
      pathname: 'trials',
      search: '?condition=' + selectedCondition
    })
  }

  const onClickEditProfile = () => {
    history.push({
      pathname: '/profile'
    });
  }

  const onClickSignIn = () => {
    history.push({pathname: "/signin"})
  }


  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const theme = useTheme();
  

  const onConditionChange = (event) => {
    const {
      target: { value },
    } = event;
    setCondition(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };



  return (
    <>
      <Header handleSignOut={handleSignOut} />
      <SignInHandler handleAuthSuccess={handleAuthSuccess} />
      <Box sx={{
            height: isMobileView ? '18em':'26em',
            marginLeft: 0, marginRight: 0,
            background: `linear-gradient(${lightBlue[900]} 0%,  #FFF 100%)`,
      }}>
        <Grid container spacing={2}>
          <Grid item xs={1}>
          </Grid>
          <Grid item xs={10} md={8} sx={{align: 'center'}}>
            <Typography variant={isMobileView? "h4": "h2"} sx={{fontWeight: "bold", marginTop: isMobileView? '0.5em' : '1em', color: '#FFF'}}>
            Stay informed
            <br/>
            Stay healthy
            </Typography>
            <p/>
            <Typography variant={isMobileView? "body1": "h5"} sx={{color: '#FFF'}}>
            Explore cancer treatment options tailored to tumor biopsy, stage, treatment history, and more
            </Typography>
          </Grid>
          <Grid item xs={1} md={3}>            
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={1} md={1}>
          </Grid>
          <Grid item xs={10} md={8} sx={{align: 'center'}}>
            <p/>
            <ThemeProvider theme={logoTheme}>
              <Box>
                { userContext &&
                  <Button variant="contained" color="secondary" size="large" sx={{textTransform: 'none'}} onClick={onClickEditProfile}>Edit Profile</Button>
                }
                {
                  !userContext &&
                  <Button variant="contained" color="secondary" size="large" sx={{textTransform: 'none'}} onClick={onClickSignIn}>Sign Up</Button>
                }
              </Box>
            </ThemeProvider>
          </Grid>
          <Grid item xs={1} md={3}>            
          </Grid>
        </Grid>
      </Box> 
      <Hidden mdDown>
      <Box>
        <Grid container spacing={2} sx={{textAlign: 'center'}}>
            <Grid item xs={1} >
            </Grid>
            <Grid item xs={10} >
              <Typography variant="h6">Choose a condition of interest</Typography>
              <FormControl sx={{ m: isMobileView? 0.5: 1, width: 300, mt: 1, mb: 1 }}>
                <Select
                  displayEmpty
                  value={condition}
                  onChange={onConditionChange}
                  input={<OutlinedInput />}
                  sx={{ boxShadow: 3, borderRadius: 10, align: "center"}}
                  MenuProps={MenuProps}
                  size="small"
                  inputProps={{ 'aria-label': 'Without label' }}
                >                                     

                  {CANCER_TYPES.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      style={getStyles(name, condition, theme)}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1} >
            </Grid>
        </Grid>
      </Box>
      </Hidden>
      <ThemeProvider theme={logoTheme}>
      <Stack
        direction={{ xs: "column", md: "row" }}
        spacing={{ xs: 2, md: 4 }}
        sx={{width: '100%', marginTop: isMobileView ? 1 : 3}}
        alignItems="center"
        justifyContent="center"
      >
        <Hidden mdUp>
        <Typography variant="h6">Choose a condition of interest</Typography>
              <FormControl sx={{ m: 1, width: 300, mt: 3 }}>
                <Select
                  displayEmpty
                  value={condition}
                  onChange={onConditionChange}
                  input={<OutlinedInput />}
                  sx={{ boxShadow: 3, borderRadius: 10, textAlign: 'center'}}
                  MenuProps={MenuProps}
                  inputProps={{ 'aria-label': 'Without label' }}
                >                                     

                  {CANCER_TYPES.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      style={getStyles(name, condition, theme)}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
        </Hidden>
        <Button variant="contained" color="primary" size="large" sx={{textTransform: 'none', width: '20em'}} onClick={onClickStandardOfCare}>View standard-of-care treatments</Button>
        <Button variant="contained" color="primary" size="large" sx={{textTransform: 'none', width: '20em'}} onClick={onClickTrials}>View clinical trials</Button>
      </Stack>
      </ThemeProvider>

        <Grid container spacing={2} sx={{textAlign: 'center', marginTop: isMobileView? 3: 3}}>
            <Grid item xs={1} >
            </Grid>
            <Grid item xs={10} >
            <Stack
        direction={{ xs: "column", md: "row" }}
        spacing={{ xs: 2, md: 10 }}
        sx={{width: '100%'}}
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="subtitle1">200+ standard-of-care treatments</Typography>
        <Typography variant="subtitle1">5+ medical societies</Typography>
        <Typography variant="subtitle1">3k+ recruiting clinical trials</Typography>
        <Typography variant="subtitle1">4k+ research institutions</Typography>
         </Stack>            
            </Grid>
            <Grid item xs={1} >
            </Grid>
        </Grid>
      <Box>

      </Box>
    </>    
  );
}

export default HomeMain;