import { createTheme } from '@mui/material/styles';
import { lightBlue, grey, teal, lime, red, deepPurple, orange } from '@mui/material/colors';

//https://belong.life/
export const logoTheme = createTheme({
  palette: {
    primary: {
      main: lightBlue[900],
    },
    secondary: {
      main: '#FFF',
      dark: grey[100],
      contrastText: lightBlue[900],
    },
    info: {
      main: lime[500],
    },
    success: {
      main: '#64B6D2',
    }
  },
});


export const trialChipTheme = createTheme({
  palette: {
    primary: {
      main: lightBlue[200],
    },
    secondary: {
      main: lime[200],
    },
    error: {
      main: red[500],
    },
    success: {
      main: lime[500],
    },
    warning: {
      main: orange[700],
    },
    info: {
      main: deepPurple[100],
    },
  },
});

export const trialEligibilityTheme = createTheme({
    palette: {
      primary: {
        main: teal[500],
      },
      secondary: {
        main: lightBlue[900],
      },
      error: {
        main: red[500],
      },
      warning: {
        main: orange[700],
      },
      info: {
        main: teal[500],
      },
    },
});
