import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { CONDITION_LIST } from './ConditionList';

const getAvailableConditions = (currentConditions) => {
  return CONDITION_LIST.filter(condition => !currentConditions.includes(condition));
}

export default function Conditions({profile, handleProfileUpdate}) {
 
  const cancerConditions = profile.userConditions ? profile.userConditions.filter(condition => condition.type === 'cancer') : [];
  const initialCurrentConditions = profile.userConditions ? profile.userConditions.filter(condition => condition.type === 'other').map(condition => condition.name) : [];
  const [currentConditions, setCurrentConditions] = React.useState(initialCurrentConditions);
  const [selectedCondition, setSelectedCondition] = React.useState(null)
  const [availableConditions, setAvailableConditions] = React.useState(getAvailableConditions(initialCurrentConditions));
    
    const onConditionChange = (event, value) => {
      setSelectedCondition(value);
    }

    const onClickAdd = () => {
      if (!currentConditions.includes(selectedCondition)) {
        currentConditions.push(selectedCondition);
      }
      setAvailableConditions(getAvailableConditions(currentConditions));
      setSelectedCondition(null);
      save(currentConditions);
    }

    const onClickDelete = (condition) => {
      // Need to update currentConditions' pointer, because react only do a shallow comparison for state change
      const updatedConditions = currentConditions.filter(currentCondition => currentCondition !== condition);
      setCurrentConditions(updatedConditions);
      setAvailableConditions(getAvailableConditions(updatedConditions));
      save(updatedConditions);
    }

    const save = (conditions) => {
      const result = conditions.map(condition => {return {name: condition, type: 'other'}});
      result.push.apply(result, cancerConditions);
      handleProfileUpdate({
        userConditions: result,
      })
    }

  return (
    <Box sx={{ width: '100%', maxWidth: '800px'}}>
    <Autocomplete
      disablePortal
      getOptionLabel={option => option || ""}
      options={availableConditions}
      onChange= {onConditionChange}
      autoComplete={true}
      value={selectedCondition}
      size="small"
      sx={{ width: 300, display: 'inline-block'}}
      renderInput={(params) => <TextField {...params} label="Select a condition to add"  />}
    />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    { selectedCondition && <Button size="small" variant="contained" onClick={onClickAdd} sx={{verticalAlign: 'bottom', textTransform: "none"}}>Add</Button>}
    <p/>
    
    <TableContainer>
      <Table sx={{ width: 500 }}>
        <TableBody>
          {currentConditions.map((condition) => (
            <TableRow
              key={condition}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" sx={{width: "40px"}}>
                <GppMaybeIcon fontSize="small" />
              </TableCell>
              <TableCell align="left" sx={{width: "300px"}}><Typography variant="body1" gutterBottom>{condition}</Typography></TableCell>
              <TableCell align="left"><Button size="small" variant="contained" onClick={() => onClickDelete(condition)} sx={{verticalAlign: 'bottom', textTransform: "none"}}>Delete</Button></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>    
    </Box>

  );
}

