import React from 'react';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import BiopsiesModal from './BiopsiesModal';
import { splitItems } from '../../utils/Util';
import Box from '@mui/material/Box';

export default function Biposies({profile, handleProfileUpdate}) {
    const initialBiopsies = profile.userBiopsies ? profile.userBiopsies : [];
    const biopsiesSplit = splitItems(initialBiopsies); 
    
    const [firstBiopsy, setFirstBiopsy] = React.useState(biopsiesSplit[0]);
    const [remainingBiopsies, setRemainingBiopsies] = React.useState(biopsiesSplit[1]);
    const [allBiopsies, setAllBiopsies] = React.useState(initialBiopsies);
    const [selectedBiopsy, setSelectedBiopsy] = React.useState(null);
    
    const [editMode, setEditMode] = React.useState(false);

    const handleBiopsyEditSave = (info, addNew) => {
        let newAllBiopsies = [];
            if (addNew) {
                newAllBiopsies = [...allBiopsies];
                newAllBiopsies.push(info);
            } else {
                newAllBiopsies =  allBiopsies.map(bio => {
                    if (bio.id === info.id) {
                        return info
                    } else {
                        return bio
                    }
                });
            }
            setAllBiopsies(newAllBiopsies);
            const bioSplit = splitItems(newAllBiopsies);                
            setFirstBiopsy(bioSplit[0]);
            setRemainingBiopsies(bioSplit[1]);
        
        setEditMode(false);
        setSelectedBiopsy(null);
        save(newAllBiopsies);
    }

    const handleBiopsyEditCancel = () => {
        setEditMode(false);
    }

    const onClickEdit = (bio) => {
        setSelectedBiopsy(bio);
        setEditMode(true);
    }

    const onClickDelete = (bio) => {
        const newAllBiopsies = allBiopsies.filter(biopsy => biopsy.id !== bio.id);
        setAllBiopsies(newAllBiopsies);
        const bioSplit = splitItems(newAllBiopsies);
        setFirstBiopsy(bioSplit[0]);
        setRemainingBiopsies(bioSplit[1]);
        save(newAllBiopsies);
    }

    const onClickAdd = () => {
        setEditMode(true);
    }

    const save = (allBiopsies) => {
        handleProfileUpdate({
            userBiopsies: allBiopsies,
        });
    }

    return (
        <Box sx={{ width: '100%', maxWidth: '800px'}}>
        <div>Prostate biopsy&nbsp;&nbsp;
            <Button 
                size="small" variant="contained" onClick={onClickAdd} 
                sx={{verticalAlign: 'bottom', textTransform: 'none'}}>
                    Add a new biopsy result
            </Button>
        </div>
        <p/>
        { firstBiopsy &&
        <TableContainer>
            <Table sx={{ maxWidth: 800, 'td, th': {fontSize: "1rem"} }}>
                <TableHead>
                <TableRow key="headerRow">         
                    <TableCell>Gleason score</TableCell>
                    <TableCell>Histopathologic type</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell></TableCell>
                </TableRow>
                </TableHead>
                <TableBody sx={{ 'td, th': { border: 0 } }}>
                    <TableRow key="firstRow">
                        <TableCell align="left">{firstBiopsy.gleasonPrimary}+{firstBiopsy.gleasonSecondary}</TableCell>
                        <TableCell align="left">{firstBiopsy.histoType}</TableCell>
                        <TableCell>{firstBiopsy.date}</TableCell>
                        <TableCell align="left">
                            <Button size="small" variant="contained" onClick={() => onClickEdit(firstBiopsy)} sx={{verticalAlign: 'bottom'}}>Edit</Button>&nbsp;&nbsp;
                            <Button size="small" variant="contained" onClick={() => onClickDelete(firstBiopsy)} sx={{verticalAlign: 'bottom'}}>Delete</Button>
                        </TableCell>
                    </TableRow>
                    
                    { remainingBiopsies && remainingBiopsies.map(bio => (
                        <TableRow key={bio.id}>
                            <TableCell align="left">{bio.gleasonPrimary}+{bio.gleasonSecondary}</TableCell>
                            <TableCell>{bio.histoType}</TableCell>
                            <TableCell>{bio.date}</TableCell>
                            <TableCell align="left">
                                <Button size="small" variant="contained" onClick={() => onClickEdit(bio)} sx={{verticalAlign: 'bottom', textTransform: "none"}}>Edit</Button>&nbsp;&nbsp;
                                <Button size="small" variant="contained" onClick={() => onClickDelete(bio)} sx={{verticalAlign: 'bottom', textTransform: "none"}}>Delete</Button>
                            </TableCell>
                        </TableRow>

                    ))}
                </TableBody>
            </Table>
        </TableContainer>  
    }

    {editMode && <BiopsiesModal editMode={editMode} selectedBiopsy={selectedBiopsy} handleBiopsyEditSave={handleBiopsyEditSave} handleBiopsyEditCancel={handleBiopsyEditCancel}/>}
    </Box>
    );
}