export const getBioposyString = (profile) => {
    let latestDate = new Date("1900-01-01");
    let latestBiopsy = null;
    if (profile.userBiopsies && profile.userBiopsies.length > 0) {
        profile.userBiopsies.forEach(biopsy => {
            const biopsyDate = new Date(biopsy.date);
            if (biopsyDate > latestDate) {
                latestDate = biopsyDate;
                latestBiopsy = biopsy;
            }
        })
    }
        if (!latestBiopsy) {
            return "";
        }
        return `Biopsy on ${latestBiopsy.date}: Gleason ${latestBiopsy.gleasonPrimary}+${latestBiopsy.gleasonSecondary}`;
}

export const getTNMString = (profile) => {
    let latestDate = new Date("1900-01-01");
    let latestTNM = null;
    if (profile.userTNMs && profile.userTNMs.length > 0) {
        profile.userTNMs.forEach(tnm => {
            const tnmDate = new Date(tnm.date);
            if (tnmDate > latestDate) {
                latestDate = tnmDate;
                latestTNM = tnm;
            }
        })

        

    }
    if (!latestTNM) {
        return "";
    }
    return `Tumor stage on ${latestTNM.date}: ${latestTNM.t_StagePrimary}${latestTNM.t_StageSecondary} ${latestTNM.n_StagePrimary}${latestTNM.n_StageSecondary} ${latestTNM.m_StagePrimary}${latestTNM.m_StageSecondary}`;
}

export const getPSAString = (profile) => {
    let latestDate = new Date("1900-01-01");
    let latestPSA = null;
    if (profile.userLabs && profile.userLabs.length > 0) {
        profile.userLabs.filter(lab => lab.name === 'TPSA').forEach(lab => {
            const psaDate = new Date(lab.date);
            if (psaDate > latestDate) {
                latestDate = psaDate;
                latestPSA = lab;
            }
        })
    }
        if (!latestPSA) {
            return "";
        }
        return `PSA level on ${latestPSA.date} ${latestPSA.level} ${latestPSA.unit}`;

}

export const getTreatmentString = (profile) => {
    let latestDate = new Date("1900-01-01");
    let latestTreatment = null;
    let treatmentType = "";
    let latestDateString = "";
    if (profile.userSurgeries && profile.userSurgeries.length > 0) {
        profile.userSurgeries.forEach(treatment => {
            const treatmentDate = new Date(treatment.date);
            if (treatmentDate > latestDate) {
                latestDate = treatmentDate;
                latestTreatment = treatment.name;
                treatmentType = "Surgery";
                latestDateString = treatment.date;
            }
        })
    }
    if (profile.userRadiotherapies && profile.userRadiotherapies.length > 0) {
        profile.userRadiotherapies.forEach(treatment => {
            const treatmentDate = new Date(treatment.startDate);
            if (treatmentDate > latestDate) {
                latestDate = treatmentDate;
                latestTreatment = treatment.name;
                treatmentType = "Radiotherapy";
                latestDateString = treatment.startDate;
            }
        })
    }

    if (profile.userDrugs && profile.userDrugs.length > 0) {
        profile.userDrugs.forEach(treatment => {
            const treatmentDate = new Date(treatment.startDate);
            if (treatmentDate > latestDate) {
                latestDate = treatmentDate;
                latestTreatment = treatment.name;
                treatmentType = "Drug";
                latestDateString = treatment.startDate;
            }
        })
    }
        if (!latestTreatment) {
            if (profile.userConditions && profile.userConditions.length > 0) {
                const condition = profile.userConditions.filter(condition => condition.name === 'prostate cancer' && condition.type === 'cancer');
                if (condition.treatmentStatus === 'pretreatment') {
                    return "Have not received any surgical, radiation, or medical treatment";
                }
            }
            return "";
        }
        return `Treatment on ${latestDateString}: (${treatmentType}) ${latestTreatment}`;

    }