import * as React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { getModalStyle } from '../utils/ModalUtil';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import FiberManualRecordTwoToneIcon from '@mui/icons-material/FiberManualRecordTwoTone';
import Divider from '@mui/material/Divider';
import { UserContext } from '../../App';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';

const hasTherapeuticCriteriaContent = (
    <>InfoReach predicts that this trial matches your health profile. See below for detailed eligibility criteria.</>
)

const hasNoTherapeuticCriteriaContent = (
    <>InfoReach predicts that this trial matches your health profile. See below for detailed eligibility criteria.</>
)

export default function EligiblityCriteriaModal({hasTherapeuticCriteria, criteria, eligibilityResult, handleEligiblityClose}) {
    const userContext = React.useContext(UserContext);
    const [showModal, setShowModal] = React.useState(true);
    const history = useHistory();

    const isMobileView = useMediaQuery('(max-width:600px)');


    const onClickClose = () => {
        setShowModal(false);
        handleEligiblityClose();
    }

    const buildCriterionText = (item) => {
        return (
            <>
            {item.indentation === 1 && <><FiberManualRecordIcon sx={{fontSize: '10px'}} />&nbsp;</>}
            {item.indentation === 2 && <><FiberManualRecordOutlinedIcon sx={{marginLeft: '10px', fontSize: '10px'}} />&nbsp;</>}
            {item.indentation === 3 && <><FiberManualRecordTwoToneIcon sx={{marginLeft: '20px', fontSize: '10px'}} />&nbsp;</>}
            {item.criterion}
            </>
        )
    }

    const onClickEditProfile = () => {
        history.push({
          pathname: '/profile'
        });
    }

    return (
        <Modal
            open={showModal}
            onClose={onClickClose}
          >
            <Box sx={getModalStyle(isMobileView ? '90vw' : '550', true)}>
                { userContext && <>
                    {eligibilityResult.eligibility === 'PROFILE_MATCHED' && hasTherapeuticCriteria && eligibilityResult.nullCount === 0 && hasTherapeuticCriteriaContent}
                    {eligibilityResult.eligibility === 'PROFILE_MATCHED' && !hasTherapeuticCriteria && eligibilityResult.nullCount === 0 &&  hasNoTherapeuticCriteriaContent}
                    {eligibilityResult.eligibility === 'PROFILE_MATCHED' && eligibilityResult.nullCount > 0 &&  
                    <>InfoReach predicts that this trial
                    matches your health profile. However, additional eligibility criteria (see
                    below) require a more complete health profile. Please complete your health
                    profile for more precise matching. <p/>
                    <Button variant="contained" onClick={onClickEditProfile} sx={{textTransform: 'none'}}>Edit Profile</Button>                    
                    </>
                    }
                    {eligibilityResult.eligibility === 'LACK_OF_INFO' && <>
                    Please complete your health profile to see if you meet the eligibility criteria of this trial (see below). <p/>
                    <Button variant="contained" onClick={onClickEditProfile} sx={{textTransform: 'none'}}>Edit Profile</Button>
                    </> }
                    {eligibilityResult.eligibility === 'NOT_ELIGIBLE' && <>
                    Based on your health profile, InfoReach predicts that you do not meet the eligibility criteria of this trial (see below).
                    </> }
                    <p/>
                    <Divider/>
                    </>
                }
                
                <List>
                    {criteria[0].map((item, index) => 
                    <ListItem key={`criterion${index}`} >
                        <ListItemText>{buildCriterionText(item)}</ListItemText>
                    </ListItem>
                    )}
                </List>     
            </Box>
          </Modal>
    );

}