import React from 'react';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ImageTestModal from './ImageTestModal';
import { splitItems } from '../../utils/Util';

const getInitialImageTests = (profile) => {
    if (profile.userTNMs) {
        return profile.userTNMs;
    }
    return [];
}
export default function ImageTests({profile, handleProfileUpdate}) {
    const initialImageTests = getInitialImageTests(profile);
    const testSplit = splitItems(initialImageTests); 

    
    const [firstImageTest, setFirstImageTest] = React.useState(testSplit[0]);
    const [remainingImageTests, setRemainingImageTests] = React.useState(testSplit[1]);
    const [allImageTests, setAllImageTests] = React.useState(initialImageTests);
    const [selectedImageTest, setSelectedImageTest] = React.useState(null);
    const [editMode, setEditMode] = React.useState(false);

    const handleImageTestEditSave = (info, addNew) => {
        let newAllImageTests = [];
        if (addNew) {
            newAllImageTests = [...allImageTests];
            newAllImageTests.push(info);
        } else {
            newAllImageTests =  allImageTests.map(test => {
                if (test.id === info.id) {
                    return info
                } else {
                    return test
                }
            });
        }
        setAllImageTests(newAllImageTests);
        const testSplit = splitItems(newAllImageTests);                
        setFirstImageTest(testSplit[0]);
        setRemainingImageTests(testSplit[1]);
    
        setEditMode(false);
        setSelectedImageTest(null);
        save(newAllImageTests);
    }

    const handleImageTestEditCancel = () => {
        setEditMode(false);
    }

    const onClickEdit = (test) => {
        setSelectedImageTest(test);
        setEditMode(true);
    }

    const onClickDelete = (test) => {
        const newAllImageTests = allImageTests.filter(imageTest => imageTest.id !== test.id);
        setAllImageTests(newAllImageTests);
        const testSplit = splitItems(newAllImageTests);
        setFirstImageTest(testSplit[0]);
        setRemainingImageTests(testSplit[1]);
        save(newAllImageTests);
    }

    const onClickAdd = () => {
        setEditMode(true);
    }

    const save = (imageTests) => {
        handleProfileUpdate({
            userTNMs: imageTests,
        });
    }

    return (
    <>
        <div>Prostate cancer TNM staging&nbsp;&nbsp;
            <Button 
                size="small" variant="contained" onClick={onClickAdd} 
                sx={{verticalAlign: 'bottom', textTransform: 'none'}}>
                    Add a new staging result
            </Button>
        </div>
        <p/>
        { firstImageTest && 
        <TableContainer>
      <Table sx={{ maxWidth: 1000, 'td, th': {fontSize: "1rem"} }}>
        <TableHead>
          <TableRow key="headerRow">         
            <TableCell sx={{width: '200px'}}>Primary tumor (T)</TableCell>
            <TableCell>Pelvic lymph nodes (N)</TableCell>
            <TableCell>Distant metastasis (M)</TableCell>
            <TableCell>Date</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ 'td, th': { border: 0 } }}>
            <TableRow key="firstRow">
                <TableCell component="th" scope="row">{firstImageTest.t_StagePrimary}{firstImageTest.t_StageSecondary}</TableCell>
                <TableCell align="left">{firstImageTest.n_StagePrimary}{firstImageTest.n_StageSecondary}</TableCell>
                <TableCell>{firstImageTest.m_StagePrimary}{firstImageTest.m_StageSecondary}</TableCell>
                <TableCell>{firstImageTest.date}</TableCell>
                <TableCell align="left">
                    <Button size="small" variant="contained" onClick={() => onClickEdit(firstImageTest)} sx={{verticalAlign: 'bottom', textTransform: "none"}}>Edit</Button>&nbsp;&nbsp;
                    <Button size="small" variant="contained" onClick={() => onClickDelete(firstImageTest)} sx={{verticalAlign: 'bottom', textTransform: "none"}}>Delete</Button>
                </TableCell>
            </TableRow>

            { remainingImageTests && remainingImageTests.map(test => (
                        <TableRow key={test.id}>
                            <TableCell align="left">{test.t_StagePrimary}{test.t_StageSecondary}</TableCell>
                            <TableCell>{test.n_StagePrimary}{test.n_StageSecondary}</TableCell>
                            <TableCell>{test.m_StagePrimary}{test.m_StageSecondary}</TableCell>
                            <TableCell>{test.date}</TableCell>
                            <TableCell align="left">
                                <Button size="small" variant="contained" onClick={() => onClickEdit(test)} sx={{verticalAlign: 'bottom', textTransform: "none"}}>Edit</Button>&nbsp;&nbsp;
                                <Button size="small" variant="contained" onClick={() => onClickDelete(test)} sx={{verticalAlign: 'bottom', textTransform: "none"}}>Delete</Button>
                            </TableCell>
                        </TableRow>

                    ))}
        </TableBody>
      </Table>
    </TableContainer>  
    }
    {editMode && <ImageTestModal editMode={editMode} selectedImageTest={selectedImageTest} handleImageTestEditSave={handleImageTestEditSave} handleImageTestEditCancel={handleImageTestEditCancel}/>}
    </>
    );
}