export const getModalStyle = (width = 500, enableScroll = false) => {
    const styleMap = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: width,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    }

    if (enableScroll) {
        styleMap["maxHeight"] = 800;
        styleMap["overflowY"] = "scroll";
    }
    return styleMap;
};