import * as React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { getModalStyle } from '../../utils/ModalUtil';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Divider from '@mui/material/Divider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { isValidDate } from '../../utils/Util';

const TESTS = {
    'TPSA': {
        label: 'PSA, total',
        unit: ['ng/ml', 'mcg/L'],
    },
    'Creatinine': {
        label: 'Creatinine',
        unit: ['mg/dL', 'ummol/L'],
    },
}

export default function LabTestModal({selectedType, selectedLabTest, handleLabTestEditCancel, handleLabTestEditSave}) {
    // if selected type is not null, then it comes from the Add action
    // if selected lab test is not null, then it comes from edit action
    const testType = selectedLabTest ? selectedLabTest.name : selectedType;

    const getInitialUnit = () => {
        if (selectedLabTest) {
            return selectedLabTest.unit;
        }
        return TESTS[testType]['unit'][0];
    }


    const [editMode, setEditMode] = React.useState(true);
    const [date, setDate] = React.useState(selectedLabTest ? dayjs(selectedLabTest.date) : null);
    const [unit, setUnit] = React.useState(getInitialUnit());
    const [level, setLevel] = React.useState(selectedLabTest ? selectedLabTest.level : "");
    const [invalidDate, setInvalidDate] = React.useState(false);
    const [invalidLevel, setInvalidLevel] = React.useState(false);
    const [saveClicked, setSaveClicked] = React.useState(false);
    const headerText = selectedLabTest ? 'Edit lab test result' : 'Add lab test result';
    

    const onClose = () => {
        handleLabTestEditCancel();
        setEditMode(false);
    }

    const onUnitChange = (event, value) => setUnit(value);

    const onClickSave = () => {
        setSaveClicked(true);
    
        var validLevel = !(level == null || level === "" || isNaN(level));
        var validDate = isValidDate(date)
        
        setInvalidLevel(!validLevel);
        setInvalidDate(!validDate);

        if (validLevel && validDate) {
            handleLabTestEditSave({
                id: selectedLabTest == null ? new Date().getTime() : selectedLabTest.id,
                date: date.format('YYYY-MM-DD').toString(),
                level: level,
                unit: unit,
                name: testType,
            }, selectedLabTest == null);
            setEditMode(false);
        }

    };

    const onLevelChange = (event) => {
        const level = event.target.value;
        setLevel(level);
        setInvalidLevel(false);
    }

    const onClickCancel = () => {
        setDate(null);
        setUnit(getInitialUnit());
        setLevel(null);
        handleLabTestEditCancel();
        setEditMode(false);
    };

    return (
        <Modal
            open={editMode}
            onClose={onClose}
          >
            <Box sx={getModalStyle(600)}>
                {headerText}
                <p/>
                <Divider />
                <TableContainer>
                    <Table sx={{ minWidth: 500, 'td, th': {fontSize: "1rem"} }}>
                        <TableBody sx={{ 'td, th': { border: 0 } }}>
                            <TableRow key="date">
                                <TableCell component="th" scope="row" align="right" sx={{width: "150px"}}>Date:</TableCell>
                                <TableCell align="left" sx={{ '.MuiInputBase-root': {height: '40px'}}}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            inputFormat="MM/DD/YYYY"
                                            value={date}
                                            disableFuture
                                            onChange={(newValue) => 
                                                {
                                                    setDate(newValue);
                                                    setInvalidDate(false)
                                                }
                                            }
                                            renderInput={(params) => <TextField {...params} 
                                                error={(invalidDate && saveClicked)}
                                                helperText={(invalidDate && saveClicked) ? "Invalid Date" : ""}
                                            /> }
                                            sx={{'input': {height: '1rem'}}}
                                            />
                                    </LocalizationProvider>
                                </TableCell>
                            </TableRow>
                            <TableRow key="level">
                                <TableCell component="th" scope="row" align="right" >{TESTS[testType]['label']}:</TableCell>
                                <TableCell align="left">
                                    <TextField
                                        size="small"
                                        value={level}
                                        sx={{width: "100px"}}
                                        onChange={onLevelChange}
                                        error={(invalidLevel && saveClicked)}
                                        helperText={(invalidLevel && saveClicked) ? "Invalid level" : ""}
                                    />&nbsp;&nbsp;
                                    <ToggleButtonGroup
                                    color="primary"
                                    value={unit}
                                    exclusive
                                    onChange={onUnitChange}
                                    size="small"
                                    >
                                    { 
                                        TESTS[testType]['unit'].map(unitOption => (
                                            <ToggleButton key={unitOption} value={unitOption} sx={{textTransform: 'none'}}>{unitOption}</ToggleButton>
                                        ))
                                    }
                                </ToggleButtonGroup>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <p/>
                <Divider />
                <p/>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                    </Grid>
                    <Grid item xs={6}>
                        <Button size="large" variant="contained" onClick={onClickSave} sx={{textTransform: "none"}}>Save</Button>&nbsp;&nbsp;
                        <Button size="large" variant="outlined" onClick={onClickCancel} sx={{textTransform: "none"}}>Cancel</Button>&nbsp;&nbsp;
                    </Grid>
                </Grid>
            </Box>
          </Modal>
    );

}