import * as React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { getModalStyle } from '../../utils/ModalUtil';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { T_VALUES, N_VALUES, M_VALUES } from '../../utils/Constants';
import { isValidDate } from '../../utils/Util';


export default function ImageTestModal({editMode, selectedImageTest, handleImageTestEditSave, handleImageTestEditCancel}) {
    const headerText = selectedImageTest ? "Edit an Imaging TNM result" : "Add an Imaging TNM result";
    const initialTValue = selectedImageTest ? selectedImageTest.t_StagePrimary + selectedImageTest.t_StageSecondary : "T0";
    const initialNValue = selectedImageTest ? selectedImageTest.n_StagePrimary + selectedImageTest.n_StageSecondary : 'N0';
    const initialMValue = selectedImageTest ? selectedImageTest.m_StagePrimary + selectedImageTest.m_StageSecondary : 'M0';

    const [date, setDate] = React.useState(selectedImageTest ? dayjs(selectedImageTest.date) : null);
    const [tValue, setTValue] = React.useState(initialTValue);
    const [nValue, setNValue] = React.useState(initialNValue);
    const [mValue, setMValue] = React.useState(initialMValue);
    const [invalidDate, setInvalidDate] = React.useState(false);

    const onClose = () => {
        handleImageTestEditCancel();
    }

    const onClickSave = () => {

        var validDate = isValidDate(date);
        setInvalidDate(!validDate);

        if (validDate) {
            handleImageTestEditSave(
            {
                id: selectedImageTest ? selectedImageTest.id : new Date().getTime(),
                date: date.format('YYYY-MM-DD').toString(),
                t_StagePrimary: tValue.substring(0, 2),
                t_StageSecondary: tValue.substring(2),
                n_StagePrimary: nValue.substring(0, 2),
                n_StageSecondary: nValue.substring(2),
                m_StagePrimary: mValue.substring(0, 2),
                m_StageSecondary: mValue.substring(2),
                t_Method: 'c',
                n_Method: 'c',
                m_Method: 'c',
            },
            selectedImageTest == null);
        }
    };


    const onClickCancel = () => {
        handleImageTestEditCancel();
    };


    const onTValueChange = (event) => {
        setTValue(event.target.value);
    }

    const onNValueChange = (event) => {
        setNValue(event.target.value);
    }


    const onMValueChange = (event) => {
        setMValue(event.target.value);
    }



    return (
        <Modal
            open={editMode}
            onClose={onClose}
          >
            <Box sx={getModalStyle(700)}>
                {headerText}
                <p/>
                <Divider />
                <TableContainer>
                    <Table sx={{ minWidth: 500, 'td, th': {fontSize: "1rem"} }}>
                        <TableBody sx={{ 'td, th': { border: 0 } }}>
                            <TableRow key="date">
                                <TableCell component="th" scope="row" align="right" sx={{width: "250px"}}>Date:</TableCell>
                                <TableCell align="left" sx={{ '.MuiInputBase-root': {height: '40px'}}}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            inputFormat="MM/DD/YYYY"
                                            value={date}
                                            disableFuture
                                            onChange={
                                                (newValue) => {
                                                    setDate(newValue);
                                                    setInvalidDate(false);
                                                }
                                            }
                                            renderInput={(params) => 
                                                <TextField {...params} 
                                                    error={invalidDate}
                                                    helperText={invalidDate ? "Invalid date" : ""}
                                                /> 
                                            }
                                            sx={{'input': {height: '1rem'}}}
                                            />
                                    </LocalizationProvider>
                                </TableCell>
                            </TableRow>
                            <TableRow key="tValueMethod">
                                <TableCell component="th" scope="row" align="right" >Primary tumor (T):</TableCell>
                                <TableCell align="left">
                                    <div style={{display: 'flex'}}>
                                    <FormControl fullWidth 
                                        sx={{ '.MuiInputBase-root': {height: '40px', width: '80px'}, flex: 1}}>
                                        <Select
                                        value={tValue}
                                        onChange={onTValueChange}                                        
                                        >
                                        {T_VALUES.map(value => <MenuItem key={value} value={value}>{value}</MenuItem> )}
                                        </Select>
                                    </FormControl>
                                    </div>
                                </TableCell>
                            </TableRow>
                            <TableRow key="nValueMethod">
                                <TableCell component="th" scope="row" align="right">Regional lymph nodes (N) :</TableCell>
                                <TableCell align="left">
                                    <div style={{display: 'flex'}}>
                                    <FormControl fullWidth 
                                        sx={{ '.MuiInputBase-root': {height: '40px', width: '80px'}, flex: 1}}>
                                        <Select
                                        value={nValue}
                                        onChange={onNValueChange}                                        
                                        >
                                        {N_VALUES.map(value => <MenuItem key={value} value={value}>{value}</MenuItem> )}
                                        </Select>
                                    </FormControl>
                                    </div>
                                </TableCell>
                            </TableRow>     
                            <TableRow key="mValueMethod">
                                <TableCell component="th" scope="row" align="right" >Distant metastasis (M):</TableCell>
                                <TableCell align="left">
                                    <div style={{display: 'flex'}}>
                                    <FormControl fullWidth 
                                        sx={{ '.MuiInputBase-root': {height: '40px', width: '80px'}, flex: 1}}>
                                        <Select
                                        value={mValue}
                                        onChange={onMValueChange}                                        
                                        >
                                        {M_VALUES.map(value => <MenuItem key={value} value={value}>{value}</MenuItem> )}
                                        </Select>
                                    </FormControl>
                                    </div>
                                </TableCell>
                            </TableRow>                         
                        </TableBody>
                    </Table>
                </TableContainer>
                <p/>
                <Divider />
                <p/>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                    </Grid>
                    <Grid item xs={6}>
                        <Button size="large" variant="contained" onClick={onClickSave} sx={{textTransform: "none"}}>Save</Button>&nbsp;&nbsp;
                        <Button size="large" variant="outlined" onClick={onClickCancel} sx={{textTransform: "none"}}>Cancel</Button>&nbsp;&nbsp;
                    </Grid>
                </Grid>
     
            </Box>
          </Modal>
    );

}