import * as React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { getModalStyle } from '../utils/ModalUtil';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import { UserContext } from '../../App';
import Divider from '@mui/material/Divider';

export default function GuidelineModel({guideline, handleGuidelineClose, eligibility}) {
    const [showModal, setShowModal] = React.useState(true);
    const userContext = React.useContext(UserContext);
    const history = useHistory();

    const onClickClose = () => {
        setShowModal(false);
        handleGuidelineClose();
    }

    const isMobileView = useMediaQuery('(max-width:600px)');

    const onClickEditProfile = () => {
        history.push({
          pathname: '/profile'
        });
    }

    return (
        <Modal
            open={showModal}
            onClose={onClickClose}
          >
            
            <Box sx={getModalStyle(isMobileView ? '90vw' : '550', true)}>
            { userContext && <>
                    {eligibility === 'PROFILE_MATCHED' && <>Based on your health profile, InfoReach predicts that this treatment is suitable for your situation. See below for details on clinical guideline recommendations</>}
                    {eligibility === 'LACK_OF_INFO' && <>
                    Please complete your health profile to see if this treatment is suitable for your situation. <p/>
                    <Button variant="contained" onClick={onClickEditProfile} sx={{textTransform: 'none'}}>Edit Profile</Button>
                    </> }
                    {eligibility === 'NOT_ELIGIBLE' && <>
                    Based on your health profile, InfoReach predicts that this treatment is not suitable for your situation. See below for details on clinical guideline recommendations.
                    </> }
                    <p/>
                    <Divider/>
                    <p/>
                    </>
                }
                <Typography variant="body1">From the {guideline.publicationDate.substring(0,4)} Clinical Practice Guideline by {guideline.organization}:</Typography>
                <br/>
                <Typography variant="body1">{guideline.excerpt}</Typography>
            </Box>
          </Modal>
    );

}