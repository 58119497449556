import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';


export default function TreatmentList({treatmentName, handleTreatmentChange, treatmentNames}) {
  const onTreatmentChange=(event, selectedTreatmentName) => {
    handleTreatmentChange(selectedTreatmentName);    
  };

  return (
    <Autocomplete
      disablePortal
      getOptionLabel={option => option || ""}
      options={treatmentNames}
      onChange= {onTreatmentChange}
      autoComplete={true}
      value={treatmentName ? treatmentNames.filter(name => name === treatmentName)[0] : null}
      size="small"
      sx={{ width: {
        xs: '90vw', 
        sm: '90vw',
        md: 600, 
        lg: 600, 
        xl: 600, 
      }, display: 'inline-block', marginLeft: 1}}
      renderInput={(params) => <TextField {...params} label="Select a specific treatment"  />}
      />
  );
}

