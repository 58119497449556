import { Auth } from "aws-amplify";
import { useHistory } from 'react-router-dom';
import React from 'react';
import { UserContext } from '../../App';
import { getEndpoint } from "../utils/BackendAppConfig";

const shouldUpdateProfile = (profile) => {
    // TODO:
    return false;
}

export default function SignInHandler({ handleAuthSuccess }) {
    const [idToken, setIdToken] = React.useState(null);
    const history  = useHistory();
    const userContext = React.useContext(UserContext);
  
    React.useEffect(() => {
        async function fn() {
            const idToken = (await Auth.currentSession()).getIdToken();
            setIdToken(idToken);
            console.log("cogito auth complete");
        }
        if (!userContext) {
            setIdToken(null);
            console.log("attempting to auth");
            fn();
        }       
                
    },[userContext]);
   

    React.useEffect(() => {
        if (idToken && !userContext) {
            const username = idToken.payload['cognito:username'];
            const email = idToken.payload['email'];
            const name = idToken.payload['name'];
            const jwtToken = idToken.getJwtToken();
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${jwtToken}`
                },
            };            
  
            fetch(`${getEndpoint("queryUserProfile")}?userProfileId=${username}`, requestOptions)
            .then(response => response.json())
            .then(data => {    
                console.log("received profile");   
                    if (data.error && 
                        data.error.startsWith("UserDoesNotExistException")) {
                        const userProfile = {
                            userIdentification: {
                                userProfileId: username,
                                email: email,
                            },
                        }

                        
                        handleAuthSuccess({
                            userProfile: userProfile,
                            jwtToken: jwtToken,
                            name: name,
                        })
                        history.push({
                            pathname: '/profile'
                        });
                    } else {
                        console.log("updating profile");
                        handleAuthSuccess({
                            userProfile: data,
                            jwtToken: jwtToken,
                            name: name,
                        });
                        if (shouldUpdateProfile(data)) {
                            history.push({
                                pathname: '/profile'
                            });
                        }
                    }

                })
            .catch(error => {
                console.log(error);                    
            })

            setIdToken(null);
        }
    },[handleAuthSuccess, idToken, userContext, history]);

}