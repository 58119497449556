import React from 'react';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import { UserContext } from '../../App';
import Button from '@mui/material/Button';
import SignOutPopover from '../signin/SignOutPopover';
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useHistory, useLocation } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import { logoTheme, blendInTheme } from '../../components/MUIOverrides';
import { Hidden, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Drawer from '@mui/material/Drawer';
import { grey } from '@mui/material/colors';
import LogoMenu from './LogoMenu';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link } from 'react-router-dom';
import Stack from "@mui/material/Stack";


function getStyles(isMobileView) {
  return {
    fontWeight: isMobileView? "0.8em": "1em",
    marginRight: 20,
    color: grey[500],
    textDecoration: 'none'
  };
}

const Header = ({handleSignOut}) => {
  const history = useHistory();
  const location = useLocation();
  const user = React.useContext(UserContext);
  const [userDrawerOpen, setUserDrawerOpen] = React.useState(false);  
  
  const getPathname = () => {
      return location.pathname;
  }
  
  const onClickUpdateProfile = () => {
    if (getPathname() !== "/profile") {
      history.push({
        pathname: '/profile'
      });
    }
  }

  const onClickSignIn = () => {
    history.push("/signin");
  }


  const toggleDrawer = (event, callback) => {

      if (
        event.type === 'keydown' &&
        (event.key === 'Tab' ||
          event.key === 'Shift')
      ) {
        return;
      }
  
      callback();
  }

  const mobileViewLeftSize = 6;
  const mobileViewRightSize = 12 - mobileViewLeftSize;
  const isMobileView = useMediaQuery('(max-width:600px)');



  return (
    <Box sx={{marginBottom: '20px', marginTop: '20px'}}>
    
    
      <Grid container spacing={10} sx={{ 
        '.MuiListItemText-root': {fontSize: '16px'}, 
        '.MuiTypography-root': {fontSize: '16px'},
        '.MuiGrid-root': {fontSize: '16px'}}}>
        <Grid item xs={mobileViewLeftSize} md={6} sx={{paddingTop: 0}}>
          { 
          //I have a hard time aligning the elements thus using a table to do so 
          }
          <LogoMenu />
            
        </Grid>

        <Grid item xs={mobileViewRightSize} md={6}>
        <Stack
        direction="row"
        spacing={{ xs: 2, md: 4 }}
        sx={{width: '100%'}}
        alignItems="center"
        justifyContent="right"
      >
        
        <Link to=""
          style={getStyles(isMobileView)}
        >Home
        </Link>
        <Link to="faqs"
          style={getStyles(isMobileView)}
        >FAQs
        </Link>
        <ThemeProvider theme={logoTheme}>
          { user && <>
            <Hidden mdDown>
            <PopupState variant="popover" popupId="demo-popup-popover">
              {(signoutState) => (
                <>
                  <Chip 
                      icon={<PersonOutlineOutlinedIcon color="success" />}
                      label={user.userProfile.userIdentification.email} 
                      color="success"
                      variant="outlined"
                      {...bindTrigger(signoutState)}/>
                  <Popover
                    {...bindPopover(signoutState)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <div style={{"margin": "10px", "textAlign": "center"}}>
                    <Button variant="outlined" onClick={onClickUpdateProfile} sx={{textTransform: 'none'}}>Edit Profile</Button>
                    <p/>
                    <SignOutPopover handleSignOut={handleSignOut} />
                    </div>
                    
                  </Popover>
                </>
              )}
            </PopupState>
            </Hidden>
            <Hidden mdUp>
              <PersonOutlineOutlinedIcon color="success" sx={{fontSize: '24px'}} onClick={() => setUserDrawerOpen(true)}/>
              <Drawer
                anchor="right"
                open={userDrawerOpen}
                onClose={(event) => toggleDrawer(event, () => setUserDrawerOpen(false))}
                >
                <Chip 
                      label={user.userProfile.userIdentification.email} 
                      color="success"
                      variant="outlined"
                      sx={{margin: 1}}
                />

                <List sx={{color: grey[500]}}>
                    <ListItemButton onClick={onClickUpdateProfile}>
                      <ListItemText primary="Edit profile" />
                    </ListItemButton>
                    <SignOutPopover handleSignOut={handleSignOut} />                    
                </List>
              </Drawer> 

            </Hidden>
        </>
          }
          {
            !user && <Button variant="contained" size={isMobileView? "small": "large"} color="success" onClick={onClickSignIn} sx={{textTransform: 'none'}}>Sign In</Button>
          }
          &nbsp;
          &nbsp;
          &nbsp;
          </ThemeProvider>
          
      </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Header;
