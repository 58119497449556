import awsExports from '../../aws-exports';
import awsExports_staging from '../../aws-exports-staging';
import awsExports_local from '../../aws-exports-local';

const stage = process.env.REACT_APP_ENV_STAGE;

let cognitoUserPoolWebClientId = awsExports["aws_user_pools_web_client_id"];
if (stage === 'staging') {
    cognitoUserPoolWebClientId = awsExports_staging["aws_user_pools_web_client_id"];
} else {
    // local
    cognitoUserPoolWebClientId = awsExports_local["aws_user_pools_web_client_id"];
}

export const hasSignedIn = () => localStorage.getItem(`CognitoIdentityServiceProvider.${cognitoUserPoolWebClientId}.LastAuthUser`) !== null;


