import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import LabTestModal from './LabTestModal';


const TEST_TYPES = [
    {
        id: 'TPSA',
        label: 'Prostate-specific antigen (PSA), total',
    },
    {
        id: 'Creatinine',
        label: 'Creatinine',
    },
];

const splitTests = (tests) => {
    let firstTest = null;
    let remainingTests = []
    if (tests.length > 0) {
        firstTest = tests[0];
        if (tests.length > 1) {
            remainingTests = tests.slice(-(tests.length-1));
        }
    }
    return [firstTest, remainingTests];

}

const renderModal = (selectedType, selectedLabTest, handleLabTestEditCancel, handleLabTestEditSave) => {
    return <LabTestModal 
        selectedType={selectedType} 
        selectedLabTest={selectedLabTest} 
        handleLabTestEditCancel={handleLabTestEditCancel} 
        handleLabTestEditSave={handleLabTestEditSave} />
}

export default function LabTests({profile, handleProfileUpdate}) {
    const initialPSATests = profile.userLabs ? profile.userLabs.filter(lab => lab.name === 'TPSA') : [];
    const psaSplit = splitTests(initialPSATests); 
    const initialCreatinineTests = profile.userLabs ? profile.userLabs.filter(lab => lab.name === 'Creatinine') : [];
    const creatinineSplit = splitTests(initialCreatinineTests);

    const [selectedType, setSelectedType] = React.useState(null);
    const [allPSATests, setAllPSATests] = React.useState(initialPSATests);
    const [firstPSATest, setFirstPSATest] = React.useState(psaSplit[0]);
    const [remainingPSATests, setRemainingPSATests] = React.useState(psaSplit[1]);
    const [allCreatineTests, setAllCreatinineTests] = React.useState(initialCreatinineTests);
    const [firstCreatinineTest, setFirstCreatinineTest] = React.useState(creatinineSplit[0]);
    const [remainingCreatinineTests, setRemainingCreatinineTests] = React.useState(creatinineSplit[1]);
    const [editMode, setEditMode] = React.useState(false);
    const [selectedLabTest, setSelectedLabTest] = React.useState(null);


    const handleLabTestEditCancel = () => {
        setSelectedLabTest(null);
        setEditMode(false);
    }

    const onClickAdd = () => {
        setEditMode(true);
    }
 

    const onTypeChange = (event, value) => {
        setSelectedType(value.id);
      }

    const save = (psaTests, creatinineTests) => {
        handleProfileUpdate({
            userLabs: creatinineTests.concat(psaTests),
        });
    }

    const onClickDelete = (labTest) => {
        if (labTest.name === 'TPSA') {
            const newAllPSATests = allPSATests.filter(test => test.id !== labTest.id);
            setAllPSATests(newAllPSATests);
            const psaSplit = splitTests(newAllPSATests);
            setFirstPSATest(psaSplit[0]);
            setRemainingPSATests(psaSplit[1]);
            save(newAllPSATests, allCreatineTests);
        } else {
            const newAllCreatininTests = allCreatineTests.filter(test => test.id !== labTest.id);
            setAllCreatinineTests(newAllCreatininTests);
            const creatinineSplit = splitTests(newAllCreatininTests);
            setFirstCreatinineTest(creatinineSplit[0]);
            setRemainingCreatinineTests(creatinineSplit[1]);
            save(allPSATests, newAllCreatininTests);
        }
      }

    const onClickEdit = (labTest) => {
        setEditMode(true);
        setSelectedLabTest(labTest);
    }
    
    const handleLabTestEditSave = (info, addNew) => {
        if (selectedType === 'TPSA' || info.name === 'TPSA') {
            let newAllPSATests = [];
            if (addNew) {
                newAllPSATests = [...allPSATests];
                newAllPSATests.push(info);
            } else {
                newAllPSATests =  allPSATests.map(test => {
                    if (test.id === info.id) {
                        return info
                    } else {
                        return test
                    }
                });
            }
            setAllPSATests(newAllPSATests);
            const psaSplit = splitTests(newAllPSATests);                
            setFirstPSATest(psaSplit[0]);
            setRemainingPSATests(psaSplit[1]);  
            save(newAllPSATests, allCreatineTests);          
        } else {
            let newAllCreatininTests = [];
            if (addNew) {
                newAllCreatininTests = [...allCreatineTests];
                newAllCreatininTests.push(info);
            } else {
                newAllCreatininTests = allCreatineTests.map(test => {
                    if (test.id === info.id) {
                        return info
                    } else {
                        return test
                    }
                });
            }
            const creatinineSplit = splitTests(newAllCreatininTests);
            setAllCreatinineTests(newAllCreatininTests);
            setFirstCreatinineTest(creatinineSplit[0]);
            setRemainingCreatinineTests(creatinineSplit[1]);
            save(allPSATests, newAllCreatininTests);
        }
        setEditMode(false);
        setSelectedLabTest(null);
    }

  return (
    <Box sx={{ width: '100%', maxWidth: '800px'}}>
    <Autocomplete
      disablePortal
      getOptionLabel={(option) => option.label|| ""}
      options={TEST_TYPES}
      onChange= {onTypeChange}
      size="small"
      sx={{ width: 300, display: 'inline-block'}}
      renderInput={(params) => <TextField {...params} label="Select a lab test to add"  />}
    />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    { selectedType && <Button size="small" variant="contained" onClick={onClickAdd} sx={{verticalAlign: 'bottom', textTransform: "none"}}>Add</Button>}
    <p/>
    { (firstPSATest || firstCreatinineTest ) &&
    <TableContainer>
      <Table sx={{ minWidth: 500, 'td, th': {fontSize: "1rem"} }}>
        <TableHead>
          <TableRow key="dd">         
            <TableCell sx={{width: '200px'}}>Lab test</TableCell>
            <TableCell>Result</TableCell>
            <TableCell>Date</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ 'td, th': { border: 0 } }}>
            {firstPSATest && (
            <TableRow key={firstPSATest.id}>
                <TableCell component="th" scope="row">PSA, total</TableCell>
                <TableCell align="left">{firstPSATest.level} {firstPSATest.unit}</TableCell>
                <TableCell>{firstPSATest.date}</TableCell>
                <TableCell align="left">
                    <Button size="small" variant="contained" onClick={() => onClickEdit(firstPSATest)} sx={{verticalAlign: 'bottom', textTransform: "none"}}>Edit</Button>&nbsp;&nbsp;
                    <Button size="small" variant="contained" onClick={() => onClickDelete(firstPSATest)} sx={{verticalAlign: 'bottom', textTransform: "none"}}>Delete</Button>
                </TableCell>
            </TableRow>
            )}
          {remainingPSATests && remainingPSATests.map(lab => (
            <TableRow key={lab.id}>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell align="left">{lab.level} {lab.unit}</TableCell>
                <TableCell>{lab.date}</TableCell>
                <TableCell align="left">
                    <Button size="small" variant="contained" onClick={() => onClickEdit(lab)} sx={{verticalAlign: 'bottom', textTransform: "none"}}>Edit</Button>&nbsp;&nbsp;
                    <Button size="small" variant="contained" onClick={() => onClickDelete(lab)} sx={{verticalAlign: 'bottom', textTransform: "none"}}>Delete</Button>
                </TableCell>
            </TableRow>
          ))}
          {firstCreatinineTest && (
            <TableRow key={firstCreatinineTest.id}>
                <TableCell component="th" scope="row">Creatinine</TableCell>
                <TableCell align="left">{firstCreatinineTest.level} {firstCreatinineTest.unit}</TableCell>
                <TableCell>{firstCreatinineTest.date}</TableCell>
                <TableCell align="left">
                    <Button size="small" variant="contained" onClick={() => onClickEdit(firstCreatinineTest)} sx={{verticalAlign: 'bottom', textTransform: "none"}}>Edit</Button>&nbsp;&nbsp;
                    <Button size="small" variant="contained" onClick={() => onClickDelete(firstCreatinineTest)} sx={{verticalAlign: 'bottom', textTransform: "none"}}>Delete</Button>
                </TableCell>
            </TableRow>
          )}
          {remainingCreatinineTests && remainingCreatinineTests.map(lab => (
            <TableRow key={lab.id}>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell align="left">{lab.level} {lab.unit}</TableCell>
                <TableCell>{lab.date}</TableCell>
                <TableCell align="left">
                    <Button size="small" variant="contained" onClick={() => onClickEdit(lab)} sx={{verticalAlign: 'bottom', textTransform: "none"}}>Edit</Button>&nbsp;&nbsp;
                    <Button size="small" variant="contained" onClick={() => onClickDelete(lab)} sx={{verticalAlign: 'bottom', textTransform: "none"}}>Delete</Button>
                </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>  
    }

       {editMode && renderModal(selectedType, selectedLabTest, handleLabTestEditCancel, handleLabTestEditSave)}


       </Box>

    
  );
}
