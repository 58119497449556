import * as React from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { UserContext } from '../../App';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { ThemeProvider } from '@mui/material/styles';
import { logoTheme } from '../../components/MUIOverrides';
import TooltipModal from '../layouts/TooltipModal';
import Chip from '@mui/material/Chip';


import { TREATMENT_TYPE_MAP } from './TrialsConstants';
import { TOOLTIP_CONTENTS } from '../utils/Constants';
import { useHistory } from "react-router-dom";

export default function TrialFilter({handleFiltersUpdate, filters}) {
    const history = useHistory();

    const updatedFilters = Object.assign({}, filters);
    const userContext = React.useContext(UserContext);
    const [showToolTip, setShowToolTip] = React.useState(false);
    const [toolTipTitle, setToolTipTitle] = React.useState(null);
    const [toolTipBody, setToolTipBody] = React.useState(null);

    const onCheckboxChanged = (event) => {
        updatedFilters[event.target.defaultValue] = event.target.checked;
        handleFiltersUpdate(updatedFilters);
    }

    const onClickToolTip = (type) => {
        setShowToolTip(true);
        setToolTipTitle(TOOLTIP_CONTENTS[type].title);
        setToolTipBody(TOOLTIP_CONTENTS[type].body);
    }

    const onClickSignIn = () => {
        history.push("/signin");
    }

    const handleToolTipClose = () => setShowToolTip(false);


    return <>
        <ThemeProvider theme={logoTheme}>
            <Box sx={{ marginLeft: '20px', marginTop: '20px', overflow: "auto", '&. MuiTypography-root': '240px',  '& .MuiCheckbox-root': {paddingTop: 0, paddingBottom: 0}}}>
            <Typography variant="body2">
                { userContext && <>
                <Box sx={{ margin: 1, padding: 1, boxShadow: 3, borderRadius: 2}}>
                <Chip label="Match my health profile" color="primary" variant="outlined" sx={{marginBottom: 1}} />&nbsp;&nbsp;
                    <InfoRoundedIcon color="primary" sx={{fontSize: '16px', verticalAlign: 'middle'}} onClick={() => onClickToolTip("profileMatch")}/>
                    
                        <br/>
                        <Checkbox defaultChecked value="eligible" onChange={onCheckboxChanged} size="small" />Profile matched
                        <br/>
                        <Checkbox defaultChecked value="noteligible" onChange={onCheckboxChanged} size="small" />Not eligible
                        <br/>
                        <Checkbox defaultChecked disabled={userContext ? false : true} value="lackofinfo" onChange={onCheckboxChanged} size="small" />Profile missing info
                </Box>
                </>}

                <Box sx={{ margin: 1, padding: 1, boxShadow: 3, borderRadius: 2}}>
                <Chip label="Study type" color="primary" variant="outlined" sx={{marginBottom: 1}}/>&nbsp;&nbsp;
                <InfoRoundedIcon color="primary" sx={{fontSize: '16px', verticalAlign: 'middle'}} onClick={() => onClickToolTip("studyType")}/>
                <br/>
                <Checkbox defaultChecked value="observational" onChange={onCheckboxChanged} size="small" />Observational
                <br/>
                <Checkbox defaultChecked value="interventional" onChange={onCheckboxChanged} size="small" />Interventional
                <br/>
                <Checkbox defaultChecked value="expandedaccess" onChange={onCheckboxChanged} size="small" />Expanded access
                </Box>

                <Box sx={{ margin: 1, padding: 1, boxShadow: 3, borderRadius: 2}}>
                <Chip label="Study phase" color="primary" variant="outlined" sx={{marginBottom: 1}}/>&nbsp;&nbsp;
                <InfoRoundedIcon color="primary" sx={{fontSize: '16px', verticalAlign: 'middle'}} onClick={() => onClickToolTip("studyPhase")}/>
                <br/>
                <Checkbox defaultChecked value="phase1" onChange={onCheckboxChanged} size="small" />Phase 1
                <br/>
                <Checkbox defaultChecked value="phase2" onChange={onCheckboxChanged} size="small" />Phase 2
                <br/>
                <Checkbox defaultChecked value="phase3" onChange={onCheckboxChanged} size="small" />Phase 3
                <br/>
                <Checkbox defaultChecked value="phase4" onChange={onCheckboxChanged} size="small" />Phase 4
                <br/>
                <Checkbox defaultChecked value="phasena" onChange={onCheckboxChanged} size="small" />Not Applicable
                </Box>

                <Box sx={{ margin: 1, padding: 1, boxShadow: 3, borderRadius: 2}}>
                <Chip label="Intervention type" color="primary" variant="outlined" sx={{marginBottom: 1}}/>&nbsp;&nbsp;
                <InfoRoundedIcon color="primary" sx={{fontSize: '16px', verticalAlign: 'middle'}} onClick={() => onClickToolTip("intervention")}/>
                { Object.entries(TREATMENT_TYPE_MAP).map( entry => (<>
                    <br/>
                    <Checkbox defaultChecked value={entry[0]} onChange={onCheckboxChanged} size="small" />{entry[1].displayName}
                </>))}
                </Box>

         </Typography>
        </Box>
        </ThemeProvider>
        { showToolTip && <TooltipModal showToolTip={showToolTip} title={toolTipTitle} body={toolTipBody} handleToolTipClose={handleToolTipClose} /> }
    </>

}