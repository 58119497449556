import { Link } from 'react-router-dom';

export const HOME_PAGE_CONTENTS = {
    "/researchers": {
        title: "For Researchers",
        body: (
            <>
            <p><b>What is InfoReach?</b>
            <br/>
            InfoReach is an online platform built with the goal of harnessing the power of large language models to increase the accessibility, efficiency, and social impact of clinical research. We develop AI-powered digital tools to keep patients informed about clinical research and assist clinical study teams in finding eligible patients for their studies. We envision that more informed patients will accelerate patient enrollment for clinical trials, enabling new treatments to reach patients faster than ever before.
            </p>
            <p>
            <b>How can InfoReach help increase enrollment efficiency in my studies?</b>
            <br/>
            First, patient users on InfoReach receive personalized clinical trial alerts and proactively apply for studies of interest. Second, clinical study teams can conduct recruitment campaigns to reach out to patients who are already interested in clinical research, using customizable filters to target those who are a diagnostic and/or therapeutic match for their studies. Last but not least, with the text-to-query capability of our fine-tuned large language model, study teams can easily deploy online pre-screeners to assess patient candidates from other sources before manual screening. Collectively, our AI-powered digital tools bring more patient candidates and reduce screen failures, saving valuable time and efforts for both patients and study teams.
            </p>
            <p><b>How can InfoReach help increase diversity and inclusion in my studies?</b>
            <br/>
            As of today, most clinical trial participants are white individuals living near large academic centers, while people of color and those in rural communities are underrepresented. InfoReach is addressing several barriers to clinical trial participation, such as lack of awareness of available studies, limited understanding of informed consent, and insufficient communication on benefits versus risks. Filling these information gaps may particularly encourage participation from communities that have been historically underrepresented in clinical trials. We firmly believe that it’s critical for clinical trial participants to reflect real-world populations so that treatments are tested for all who need them.
            </p>
            </>

        )
    },
    "/clinics": {
        title: "For Hospitals and Clinics",
        body: (
            <>
                <p>
                <b>What is InfoReach?</b>
                <br/>
                InfoReach is an internet platform built with the goal of harnessing the power of large language models to increase the accessibility, efficiency, and social impact of clinical research. We develop AI-powered digital tools to keep patients informed about clinical research and assist clinical study teams in finding eligible patients for their studies. We envision that more informed patients will accelerate patient enrollment for clinical trials, enabling new treatments to reach patients faster than ever before.
                </p>
                <p><b>How can InfoReach help clinical care teams?</b>
                <br/>
                Clinical trials should be part of treatment options communicated to patients - sometimes they are the best option. Unfortunately, most patients miss the opportunity to consider clinical trials at critical points of their healthcare because the care team lacks research personnel support to find applicable studies for every patient. InfoReach offers EHR-based clinical trial alert for hospitals/clinics, which informs clinical care teams about clinical trial opportunities for each of their patients when he/she becomes a diagnostic and therapeutic match for a study.
                </p>

            </>

        )
    },
    "/aboutus": {
        title: "About Us",
        body: (
            <>
                    <p>
                    InfoReach is an online platform built with the goal of harnessing the power of large language models to increase the accessibility, efficiency, and social impact of clinical research. Clinical studies, also known as clinical trials, drive healthcare innovation and advancements to save or improve patient lives. We are dedicated to solving an important problem plaguing the clinical research community today: the lack of efficiency and diversity in patient enrollment. Approximately 80% of clinical studies fail to enroll enough patients, resulting in substantial extensions (often years) of the study timeline. Meanwhile, racial and ethnic minorities make up about 40% of the U.S. population but constitute only 5-20% of clinical study participants.
                    </p>

                    <p>
                    InfoReach is tackling this problem with AI-powered patient recruitment for clinical studies. Our machine reading algorithm for clinical studies combines the reasoning capabilities of large language models and expert domain knowledge in clinical research. It translates clinical study eligibility criteria written in natural language into structured queries to precisely identify individuals who are a match for a study according to their diagnostic and therapeutic information. The custom read/search engine powers a spectrum of digital tools developed for the clinical research community, such as personalized clinical trial alert for individuals, EHR-based clinical trial alert for hospitals/clinics, and smart pre-screener deployment for clinical study teams.
                    </p>

                    <p>
                    If you would like to know more about InfoReach or partner with us in our mission to inform
                    patients and accelerate medicine, please contact <b>info@inforeach.org</b>.
                    </p>
            </>

        )
    },
    "/breastcancer": {
        title: "To breast cancer patients",
        body: (
            <>
               Breast cancer is the most common cancer in American women (except for skin cancer). 
               We are actively working to test our digital offerings for breast cancer survivors. 
               Join the email list by sending a message to <b>info@inforeach.org</b> to get notified when Alpha for Breast Cancer goes live.
            </>

        )
    },
    "/lungcancer": {
        title: "To lung cancer patients",
        body: (
            <>
               Lung cancer is the leading cause of cancer death in both men and women in the U.S. 
               We are actively working to test our digital offerings for lung cancer survivors. 
               Join the email list by sending a message to <b>info@inforeach.org</b> to get notified when Alpha for Lung Cancer goes live.
            </>

        )
    },
    "/learnmore": {
        title: "Learn More",
        body: (
            <>
                    <p>
                    <b>What is clinical research?</b>
                    <br/>
                    Clinical research refers to scientific studies conducted
                    with human participants to investigate the safety, effectiveness, and benefits
                    of medical treatments. It plays a crucial role in advancing medical knowledge,
                    informing healthcare practices, and evaluating new therapies and interventions.
                    </p>
                    <p>
                    <b>Why is clinical research important to everyone?</b>
                    <br/>
                    Clinical studies generate valuable data about disease prognoses
                    and benefits and risks of different treatment options. Staying informed about clinical
                    research findings empowers you with the best available data to make informed
                    decisions in your personal healthcare. By participating in clinical studies
                    yourself, you gain access to cutting-edge treatments that are not yet widely
                    available, receive close medical monitoring and support from the research team,
                    and simultaneously contribute to improving healthcare for patients like you.
                    </p>
                    <p>
                    <b>What information can I get from InfoReach?</b>
                    <br/>
                    InfoReach extracts personalized clinical research
                    information from medical journals, FDA databases, and clinical trial registries
                    based on your health profile. Personalized clinical trial alert and health risk
                    prediction are available to our users while other features are under active development.
                    </p>
                    <p>
                    <b>What differentiates InfoReach from other clinical trial search websites?</b>
                    <br/>
                    Today, there are over 25,000 open clinical trials in the US. 
                    While several clinical trial search websites support basic searches by condition, 
                    geolocation, and study type, they have limited capability to filter trials based on patient characteristics, resulting in time-consuming manual searches for trials that match users’ health profiles.
                    </p>
                    <p>
                    InfoReach stands out from other clinical trial search
                    websites by offering personalized filtering based on users’ detailed diagnosis
                    and treatment history. By maintaining an up-to-date health profile, you receive
                    personalized clinical trial alerts 24/7 without the need for manual searches.
                    </p>
            </>

        )
    },
    "/faqs": {
        title: "FAQs",
        body: (
            <>
            <b>What is InfoReach?</b><br/>
            InfoReach is a personalized search platform for health information. Our platform harnesses the power of large language models—a type of AI—to help cancer patients stay informed about the latest treatment options, along with their benefits and risks, to facilitate data-informed decision-making.
            <p/>

            <b>Why should cancer patients and families sign up?</b><br/>
                Cancer care is evolving rapidly, making it hard to stay current with the latest developments suitable for individuals. Not everyone has the resources to seek care at a national cancer center, resulting in inequities in cancer care quality and clinical outcomes.
                InfoReach allows users to quickly explore cancer treatment options tailored to their tumor biopsy, stage, treatment history, and other health information. Cancer patients and families can benefit from this service in several ways, including:
            <ul>
            <li>Review all suitable treatment options so that you won’t miss any that may be of interest.</li>
            <li>Check if your planned treatment is consistent with national clinical guidelines. This is like seeking a second opinion but more convenient compared to traveling to another cancer center.</li>
            <li>Learn about the benefits and risks of different options through data from completed clinical trials, which will simplify decision-making on your health journey.</li>
            <li>Search for clinical trials that are open to participation, gaining access to cutting-edge treatments that are not yet widely available.</li>
            </ul>
            Our goal is that every cancer patient, no matter where they live, will receive the optimal treatment to date and stay healthy.
            <p/>
            <b>What standard-of-care treatments are covered by InfoReach?</b><br/>
            InfoReach provides information on a comprehensive range of cancer treatments that are endorsed by the latest clinical guidelines. This includes surgeries, radiotherapies, targeted therapies, chemotherapies, immunotherapies, and more. By staying updated with the latest guidelines and research, InfoReach ensures that users have access to current and evidence-based treatment options relevant to their specific condition and treatment history.
            <p/>
            <b>What kind of clinical trials can users find through InfoReach?</b><br/>
            Users can find both observational and interventional clinical trials through InfoReach, including those focused on new cancer treatments, diagnostic methods, and supportive care strategies. All cancer clinical trials that are registered with clinicaltrials.gov are covered. By matching users with suitable clinical trials, InfoReach helps enhance patient enrollment diversity and efficiency in clinical studies.
            <p/>
            <b>What differentiates InfoReach from other clinical trial search websites?</b><br/>
            Today, there are over 25,000 open clinical trials in the US. While several clinical trial search websites support basic searches by condition, geolocation, and study type, they have limited capability to filter trials based on cancer characteristics and treatment history.
            InfoReach stands out from other clinical trial search websites by offering personalized filtering based on users’ specific diagnoses and treatment histories. Moreover, clinical trial opportunities are time-sensitive, and patients’ eligibility for specific studies may change as his/her condition changes. By maintaining an up-to-date health profile, you will receive personalized clinical trial alerts without the need for repeated manual searches.
            <p/>
            <b>How does InfoReach protect user data?</b><br/>
            InfoReach is committed to ensuring the security and privacy of user data within our service. Our platform adheres to the highest standards of data protection, incorporating a multi-layered approach to safeguard user data.
            First, our service is HIPAA-compliant to ensure that user data is handled with the utmost care and confidentiality. We have robust authentication and authorization mechanisms, guaranteeing that only authorized individuals have access to their own data. Second, our platform is designed with advanced encryption and security protocols. All data transmissions occur over secure channels utilizing TLS (Transport Layer Security) and HTTPS (Hypertext Transfer Protocol Secure) protocols, ensuring that any data exchanged between users and our platform remains confidential and protected from potential eavesdropping or tampering. Third, our data storage leverages the advanced security features of AWS (Amazon Web Services), ensuring that user data remains shielded from unauthorized access.
            <p/>
            <b>How does InfoReach use AI to match patients with treatments?</b><br/>
            InfoReach has developed advanced AI algorithms for cancer care to match patients with various treatment options. Our machine reading algorithm translates the eligibility criteria of clinical studies, written in natural language, into structured queries. These queries precisely identify individuals who meet the study requirements based on their diagnostic and therapeutic information.
            <p/>
            <b>How can I get in touch with InfoReach?</b><br/>
            If you need any support or would like to know more about InfoReach, please email info@inforeach.org.
        </>
        )

    },
    "/termofservice": {
        title: "Term of Service",
        body: (
            <>
               InfoReach Inc. (“<b>InfoReach</b>”, the “<b>Company</b>”, “<b>we</b>”, “<b>our</b>”, or “<b>us</b>”) welcomes you (the “<b>User</b>”, or “<b>you</b>”) to our service platform (the “Platform”), including our website at: https://www.inforeach.org (the “<b>Site</b>”).<p/>
                You may use the Service (or any part thereof) only in accordance with the terms and conditions hereunder.<p/>
                <b>IMPORTANT:</b><br/>
                <b>This Service provides information only and does not provide any medical advice. InfoReach is not a healthcare provider, and the Service is provided for general informational and educational knowledge and consideration only and does not provide or constitute as professional or medical advice, diagnosis, treatment, consultation, or healthcare service in any way, and must not be relied upon as such.</b><p/>
                <b>Further, this Service cannot be used for medical decisions as it is an information platform. As such, it is not regulated by any governmental agency, including the Food and Drug Administration (the “FDA”).</b><p/>
                <b>1. Acceptance of the Terms</b><br/>
                By entering to, connecting to, accessing or using the Service, you acknowledge that: (a) you have read and understood the following Terms of Service including the terms of our Privacy Policy available at https://www.inforeach.org/privacy-policy (collectively, the “Terms”); (b) you agree to be bound by them and to comply with all applicable laws and regulations regarding your use of the Service.
                If you do not agree to these Terms, please do not enter, connect to, access, or use the Service.<p/>
                <b>2. The Service</b><br/>
                <b>Informational Service</b>. The Service is an informational service that enables Users to track health and obtain personalized health information to better manage existing conditions.<b/>
                <b>Personalized Information</b>. In accordance with the information you provide InfoReach, or due to the fact that you are a User of the Site, the Service may offer you personalized information and notifications, which are based on aggregated statistics and/or information provided to us by other users and/or third parties.<p/>
                <b>Information about Relevant Clinical Trials</b>. As part of the Service, InfoReach may inform you of clinical trials that might be relevant to you in accordance with our Privacy Policy. If you are interested in receiving more information or applying to such clinical trials, InfoReach may provide your contact details to the applicable third-party conducting or otherwise administering participation in the trial, subject to your explicit consent.<p/>
                The Service is not allowed for use by individuals who are under the age of eighteen (18) years old (see Section 8 below).<p/>
                <b>3. User Account</b><br/>
                In order to use the Service, you must register and open a User account (the “Account”). Registration must be done by providing us with certain details as described in our Privacy Policy at https://www.inforeach.org/privacy-policy.<p/>
                You are solely and fully responsible for maintaining the confidentiality of the login credentials of your Account and for all activities that occur under your Account. You must notify us immediately of any unauthorized use of your Account of login cre d entials or any other breach of security. We cannot and will not be liable for any loss or damage arising from any failure of yours to comply with the Terms or from any unauthorized use of your Account or any other breach of security. You may be liable for the losses of InfoReach and/or others due to any such use.<p/>
                To help protect the privacy of data and personally identifiable information you transmit through use of our service, we maintain physical, technical and administrative safeguards. We update and test our security technology on an ongoing basis. We restrict access to your personal data to those employees who need to know that information to provide benefits or services to you. In addition, we train our employees about the importance of confidentiality and maintaining the privacy and security of your information. We commit to taking appropriate disciplinary measures to enforce our employees’ privacy responsibilities.<p/>
                If you wish to modify your Account information, or if you wish to terminate your Account, you may do so by contacting us at: info@inforeach.org. Your Account will be terminated within a reasonable time following your request in accordance with the Privacy Policy, and from that date you will no longer be able to access your Account, and without derogating from any right or claim available to InfoReach, any and all permissions, rights and licenses granted to you under these Terms shall be instantly terminated and revoked.<p/>
                <b>4. User Representations and Undertakings</b><br/>
                Your use of the Service (or any part thereof) is dependent on the fact that you hereby represent and warrant that:<br/>
                <ul>
                    <li>You possess the knowledge and judgment necessary to decide whether to use the Service or otherwise rely on any information available therein;</li>
                    <li>You will not rely on InfoReach or the Service in any way, as you fully understand that we do not provide any professional or medical advice, diagnosis or treatment or any healthcare service, nor do we provide any endorsement, support, representation or guarantee regarding the accuracy, reliability, truthfulness, legality or completeness of any service, information or content that may be available to you on or in connection with the Service;</li>
                    <li>You will obtain appropriate professional advice (including medical advice) regarding any information you receive on this Service. You acknowledge that there is no professional relationship (including any doctor-patient or advisor-advisee relationship) between you and InfoReach, and that you may not solely or fundamentally rely on any information, content or any features you find on or through the Service;</li>
                    <li>You acknowledge that there are risks in using the Service and/or relying solely on information available on or through the Service and that InfoReach cannot and does not guarantee any specific outcomes from such use and/or reliance or ignorance, and you hereby assume all such risks, liabilities and/or harm of any kind arising in connection with and/or resulting from such use, reliance and/or ignorance. Such risks may include, among others, inaccurate or incomplete representation or misrepresentation of information, goods or services, breach of warranty and/or contract, violation of rights and any consequent claims;</li>
                    <li>You possess the legal authority to enter into these Terms and to form a binding agreement under any applicable law, to use the Service in accordance with these Terms, and to fully perform your obligations hereunder;</li>
                    <li>If you connect to, access or use the Service on behalf of any third party, family member or dependent, you represent and warrant that you are duly authorized under any applicable law to represent such third party, family member or dependent in connection with these Terms and to commit it to be bound by these Terms, and hereby make all representations and warranties herein on both your and its behalf;</li>
                    <li>You possess any and all consents required under any applicable laws, regarding the posting of any personal information of others to the Service, and shall adhere to any applicable laws regarding such information;</li>
                    <li>The execution of the Terms does not and will not violate any other agreement to which you are bound or any law, rule, regulation, order or judgment to which you are subject;</li>
                    <li>You acknowledge that you are solely responsible for complying with applicable laws regarding your use of the Service;</li>
                    <li>By using the Service, you herby agree to its terms of use and thereby waive in advance any claim and/or demand and/or legal suit of any kind towards InfoReach relating to the Service’s use.</li>
                </ul>
                <b>5. Use Restrictions</b><br/>
                There are certain conducts which are strictly prohibited on and/or with respect to the Service. Please read the following restrictions carefully. Your failure to comply with the provisions hereunder may result (at InfoReach’s sole discretion) in the termination of your access to the Service and may also expose you to civil and/or criminal liability.<p/>
                <b>You may not, whether by yourself or anyone on your behalf:</b><br/>
                <ul>
                    <li>copy, modify, create derivative works of, reverse engineer, adapt, emulate, translate, compile, decompile or disassemble the Service (or any part thereof), and/or permit others to do so;</li>
                    <li>copy, modify, create derivative works of, adapt, emulate, translate, reverse engineer, compile, decompile or disassemble any portion of the content on the Service, material and data available on the Service, (collectively, the “Content”) in any way, or publicly display, perform, or distribute the Content, without InfoReach’s prior written consent; </li>
                    <li>make any use of the Content on any other website or networked computer environment for any purpose, or replicate or copy the Content without InfoReach’s prior written consent;</li>
                    <li>create a browser or border environment around the Service and/or Content, link, including in-line linking, to elements on the Service, such as images, posters and videos, and/or frame or mirror any part of the Service, unless as expressly permitted hereunder;</li>
                    <li>transmit or otherwise make available in connection with the Service, and/or use the Service to distribute and/or otherwise transmit any virus, worm, Trojan Horse, time bomb, web bug, spyware, or any other computer code, file, or program that may or is intended to damage or hijack the operation of any hardware, software, or telecommunications equipment, or any other actually or potentially harmful, disruptive, or invasive code or component;</li>
                    <li>interfere with or disrupt the operation of the Service, or the servers or networks that host the Service or make the Service available, or disobey any requirements, procedures, policies, or regulations of such servers or networks;</li>
                    <li>sell, license, or exploit for any commercial purposes any use of or access to the Content and/or Service;</li>
                    <li>create a database by systematically downloading and storing all or any of the Content, or forward any data generated from the Service without the prior written consent of InfoReach;</li>
                    <li>bypass any measures which may be used to prevent or restrict access to the Service and/or certain functionalities therein;</li>
                    <li>use the Content and/or the Service for any illegal, immoral or unauthorized purpose;</li>
                    <li>use the Service and/or the Content for non-personal or commercial purposes without InfoReach’s prior express written authorization;</li>
                    <li>infringe or violate any of these Terms.</li>
                </ul>
                <b>6. Privacy Policy</b><br/>
                We respect the privacy of our Users and are committed to protect the information you share with us in connection with the Service. Our policy and practices and the type of information collected are described in our Privacy Policy at <Link to="privacypolicy" target="_blank" rel="Privacy Policy">Privacy Policy</Link>. If you intend to connect to, access or use the Service you must first read and agree to the Privacy Policy.<p/>
                <b>7. Intellectual Property</b><br/>
                The Service (including the Site), the InfoReach’s proprietary software, algorithms and any and all intellectual property rights pertaining thereto, including, without limitation, inventions, patents and patent applications, trademarks, trade names, logos, icons, copyrightable materials, graphics, text, images, designs (including the “look and feel” of the Service and any part thereof), specifications, methods, procedures, information, know-how, data, technical data, interactive features, source and object code, files, interface, GUI and trade secrets, whether or not registered and/or capable of being registered (collectively, “Intellectual Property”), are owned and/or licensed to InfoReach, and are subject to copyright and other applicable intellectual property rights under U.S. laws, foreign laws, and international conventions. You may not copy, distribute, display, execute publicly, make available to the public, emulate, reduce to human readable form, decompile, disassemble, adapt, sublicense, make any commercial use, sell, rent, lend, process, compile, reverse engineer, combine with other software, translate, modify or create derivative works of any material that is subject to InfoReach’s proprietary rights, including InfoReach’s Intellectual Property, either by yourself or by anyone on your behalf, in any way or by any means, unless expressly permitted in the Terms.<p/>
                InfoReach hereby grants you, and you accept, a personal, non-exclusive, non-commercial, non-transferable, non-sublicensable, fully revocable, limited license to use the Site and Content, solely for the limited purpose of using the Service for a personal, non-commercial needs in accordance with the Terms.<p/>
                “InfoReach” and all logos and other proprietary identifiers used by InfoReach in connection with the Service are all trademarks and/or trade names of InfoReach, whether or not registered. All other trademarks, service marks, trade names and logos which may appear on or with respect to the Service belong to their respective owners (“<b>Third-Party Marks</b>”). No right, license, or interest to InfoReach Trademarks and/or to the Third-Party Marks is granted hereunder, and you agree that no such right, license, or interest shall be asserted by you with respect to InfoReach Trademarks or the Third-Party Marks and therefore you will avoid using any of those marks, unless expressly permitted herein.<p/>
                You are hereby prohibited from removing or deleting any and all copyright notices, restrictions and signs indicating proprietary rights of InfoReach and/or its licensors, including copyright mark [©] or trademark [® or ™] contained in or accompanying the Service, and you represent and warrant that you will abide by all applicable laws in this respect. You are further prohibited from using, diluting or staining any name, mark or logo that is identical, or confusingly similar to any of InfoReach marks and logos, whether registered or not.<p/>
                <b>8. Minors</b><br/>
                You may use the Service only if you are at least eighteen (18) years old. You represent that you are at least eighteen (18) years of age and if you are under the age of eighteen (18), you may only use the Service under the supervision, or with the direct consent, of a parent or legal guardian who has agreed to any agreement you enter into while using the Service, including these Terms. We reserve the right to require you at any stage to provide proof of age, and, if applicable, approval of your use of the Service by your parent or legal guardian, so that we can verify that only eligible Users are using the Service. In the event that it comes to our knowledge that a person under the age of eighteen (18) is using the Service, and we have not received your parent’s or legal guardian’s consent, we reserve the right to prohibit and block such User from accessing the Service and will make all efforts to promptly delete any Personal Information (as such term is defined in our Privacy Policy at https://www.inforeach.org/privacy-policy with respect to such User).<p/>
                <b>9. Third Party Components</b><br/>
                The Service may use or include third party software, files and components that are subject to open source and third party license terms (“Third Party Components”). Your right to use such Third Party Components as part of, or in connection with, the Service is subject to any applicable acknowledgements and license terms accompanying such Third Party Components, contained therein or related thereto. If there is a conflict between the licensing terms of such Third Party Components and these Terms, the licensing terms of the Third Party Components shall prevail only in connection with the related Third Party Components. These Terms do not apply to any Third Party Components accompanying or contained in the Service and InfoReach disclaims all liability related thereto. You acknowledge that InfoReach is not the author, owner or licensor of any Third Party Components, and that InfoReach makes no warranties or representations, express or implied, as to the quality, capabilities, operations, performance or suitability of Third Party Components. Under no circumstances shall the Service or any portion thereof (except for the Third Party Components contained therein) be deemed to be “open source” or “publicly available” software.<p/>
                <b>10. Availability</b><br/>
                The Service’s availability and functionality depend on various factors, such as communication networks, software, hardware, and InfoReach’s service providers and contractors. InfoReach does not warrant or guarantee that the Service will operate and/or be available at all times without disruption or interruption, or that it will be immune from unauthorized access error-free.<p/>
                <b>11. Changes to the Service</b><br/>
                InfoReach reserves the right to modify, correct, amend, enhance, improve, make any other changes to, or discontinue, temporarily or permanently, the Service (or any part thereof) without notice, at any time. In addition, you hereby acknowledge that the Content available through the Service may be changed, modified, edited or extended in terms of content and form or removed at any time without any notice to you. You agree that InfoReach shall not be liable to you or to any third party for any modification, suspension, error, malfunction or discontinuance of the Service (or any part thereof). In addition, InfoReach may, at any time and in its sole discretion, elect to charge a fee in consideration for providing the Services.<p/>
                <b>12. Disclaimer and Warranties</b><br/>
                InfoReach does not warrant or make any representations regarding the use, the liability or use or operate, or the results of the use or operation of the Service (or any part thereof).<p/>
                The Service (and any part thereof), including without limitation any content, data and information related thereto, are provided on an “as is” and “as available” basis, without any warranties of any kind, express or implied, including warranties of title or non-infringement or implied warranties of use, merchantability or fitness for a particular purpose or use.<p/>
                InfoReach and its affiliates, including any of their officers, directors, shareholders, employees, sub-contractors, agents, parent companies, subsidiaries and other affiliates (collectively, “InfoReach affiliates”), jointly and severally, disclaim and make no representations or warranties as to the usability, accuracy, quality, availability, reliability, suitability, completeness, truthfulness, usefulness, or effectiveness of any content, data, results, or other information obtained or generated in connection with your or any user’s use of the service.<p/>
                InfoReach does not warrant that the operation of the service is or will be secure, accurate, complete, uninterrupted, without error, or free of viruses, worms, other harmful components, or other program limitations. InfoReach may, at its sole discretion and without an obligation to do so, correct, modify, amend, enhance, improve and make any other changes to the service at any time, or discontinue displaying or providing any content or features without any notice to you.<p/>
                <b>You agree and acknowledge that the use of the service, including use of and/or reliance on any content available through the service, is entirely, or otherwise to the maximum extent permitted by applicable law, at your own risk.</b><p/>
                <b>13. Limitation of Liability</b><br/>
                In no event shall InfoReach and/or any of the InfoReach affiliates be liable for any damages whatsoever, including direct, indirect, special, incidental or consequential damages of any kind, resulting from or arising out of the service, use or inability to use the service, failure of the service to perform as represented or expected, loss of goodwill, data or profits, the performance or failure of InfoReach to perform under these terms, and any other act or omission of InfoReach or by any other cause whatsoever, including without limitation damages arising from the conduct of any users and/or third party services.<p/>
                No action may be brought by you for any breach of these terms more than one (1) year after the accrual of such cause of action. As some states do not allow the exclusion or limitation of incidental or consequential damages, then such limitations only may not apply to a user residing in such states.<p/>
                Such limitations, exclusions and disclaimers shall apply to all claims for damages, whether based in an action of contract, warranty, strict liability, negligence, tort, or otherwise. You hereby acknowledge and agree that these limitations of liability are agreed allocations of risk constituting in part the consideration for InfoReach’s services to you, and such limitations will apply notwithstanding the failure of essential purpose of any limited remedy, and even if InfoReach and/or any InfoReach affiliates has been advised of the possibility of such liabilities and/or damages. The foregoing limitation of liability shall apply to the fullest extent permitted by law in the applicable jurisdiction and in no event shall InfoReach’s cumulative liability to you exceed amounts paid to InfoReach for use of the service. If you have not made any payments to InfoReach for the use of the service, then InfoReach shall not have any liability towards you.<p/>
                <b>14. Indemnification</b><br/>
                You agree to defend, indemnify and hold harmless InfoReach and any InfoReach affiliates from and against any and all claims, damages, obligations, losses, liabilities, costs, debts, fines, late fees, cancellation fees and expenses (including attorney’s fees) arising directly or indirectly from: (i) your use of the Service (or any part thereof); (ii) breach of any term of these Terms by you or anyone using your Account; (iii) any damage of any sort, whether direct, indirect, special or consequential, you may cause to any third party which relates to your use  of (or inability to use) the Service; (iv) your violation of any third party intellectual property rights, privacy rights or other rights through your use of the Service; and (v) your violation of any applicable law or regulation.<p/>
                <b>15. Amendments to the Terms</b><br/>
                InfoReach may change the Terms from time to time, at its sole discretion and without any notice, including the Privacy Policy at: https://www.inforeach.org/privacy-policy. Substantial changes of these Terms will be first notified on the Service and/or by sending you an e-mail regarding such changes to the e-mail address that is registered under your Account. Such substantial changes will take effect seven (7) days after such notice was provided on any of the abovementioned methods. Otherwise, all other changes to these Terms are effective as of the stated “Last Revised” date and your continued use of the Service after the Last Revised date will constitute acceptance of, and agreement to be bound by, those changes. Please note that in the event that the Terms should be amended to comply with any legal requirements, such amendments may take effect immediately and without any prior notice, as may be required by law.<p/>
                <b>16. Termination of your Account and Termination of the Service</b><br/>
                At any time, InfoReach may block your access to the Service and/or temporarily or permanently limit, suspend or terminate your Account, for any reason, at its sole discretion, in addition to any other remedies that may be available to InfoReach under any applicable law. Such actions may be taken if InfoReach deems that you have breached any of these Terms in any manner.<p/>
                Additionally, InfoReach may at any time, at its sole discretion, cease the operation of the Service or any part thereof, temporarily or permanently, without giving any prior notice. You agree and acknowledge that InfoReach does not assume any responsibility or liability with respect to, or in connection with the termination of the Service and/or loss of any data.<p/>
                <b>17. General</b><br/>
                <ul>
                    <li>Any heading, caption or section title contained herein is inserted only as a matter of convenience, and in no way defines or explains any section or provision hereof.</li>
                    <li>These Terms do not, and shall not be construed to create any relationship, partnership, joint venture, employer-employee, agency, or franchisor-franchisee relationship between InfoReach and you.</li>
                    <li>These Terms, and the rights and remedies provided hereunder, and any and all claims, disputes and controversies arising hereunder or related hereto and/or to the Service, their interpretation, or the breach, termination or validity thereof, the relationships which result from these Terms, or any related transaction shall be governed by, construed under and enforced in all respects solely and exclusively in accordance with the U.S. laws without reference to its conflict-of-laws principles, and shall be brought in, and you hereby consent to exclusive jurisdiction and venue in, the competent courts in the U.S.</li>
                    <li>InfoReach may assign its rights and obligations hereunder and/or transfer ownership rights and title in the Service to a third party without your consent or prior notice to you. Your rights and obligations under the Terms are not assignable. Any attempted or actual assignment thereof without InfoReach’s prior explicit and written consent will be null and void.</li>
                    <li>If any provision of these Terms is found to be unlawful, void, or for any reason unenforceable, then that provision will be deemed severable from these Terms and will not affect the validity and enforceability of any remaining provisions.</li>
                    <li>No waiver by either party of any breach or default hereunder will be deemed to be a waiver of any preceding or subsequent breach or default.</li>
                    <li>These Terms constitute the entire terms and conditions between you and InfoReach relating to the subject matter herein and supersede any and all prior or contemporaneous agreements, understandings, promises, conditions, negotiations, covenants or representations, whether written or oral, between InfoReach and you, including, without limitation, those made by or between any of our respective representatives, with respect to the Service. You agree that you will make no claim at any time or place that these Terms have been orally altered or modified or otherwise changed by oral communication of any kind or character. You further agree that you are not relying upon any promise, inducement, representation, statement, disclosure or duty of disclosure of InfoReach in entering into these Terms.</li>
                    <li>The provisions of Section 4 (User Representations and Undertakings), Section 5 (Use Restrictions), Section 6 (Privacy Policy, including the Privacy Policy referred to therein), Section 7 (Intellectual Property), Section 8 (Minors), Section 9 (Third Party Services), Section 10 (Availability), Section 11 (Disclaimer and Warranties), Section 13 (Limitation of Liability), Section 14 (Indemnification), Section 16 (Termination of your Account and Termination of the Service) and Section 17 (General Sections), will survive the termination or expiration of these Terms.</li>
                </ul>
                <b>18. Contact</b><br/>
                If you have any questions (or comments) concerning the Terms or the Service, you are welcome to send us an email to info@inforeach.org, and we will make an effort to reply as soon as possible.<p/>
                By contacting us, you represent that you are free to do so and that you will not knowingly provide InfoReach with information that infringes upon third parties’ rights, including any intellectual property rights. You further acknowledge that notwithstanding anything herein to the contrary, any and all rights, including intellectual property rights in such information provided, shall belong exclusively to InfoReach, and InfoReach may use or refrain from using any such information at its sole discretion.

            </>

        )
    },
    "/privacypolicy": {
        title: "Privacy Policy",
        body: (
            <>
            InfoReach Inc. (“<b>InfoReach</b>”, the “<b>Company</b>”, “<b>we</b>”, or “<b>us</b>”) is a digital health company that provides patients access to personalized health information to better manage existing conditions. We are a US company located in Bellevue, WA. We respect the privacy of the users of our platform (the “<b>Platform</b>”), including our website at: https://www.inforeach.org (the “<b>Site</b>”), and are committed to protect the personal information that users share with us in connection with the use of the Service.<p/>
            This Privacy Policy (the “<b>Privacy Policy</b>”) is intended to describe our practices regarding the information we may collect from you (the “<b>User</b>”, or “<b>you</b>”) when you use the Service (or any part thereof), the manners in which we may use such information, and the options and rights available to you.<p/>
            Capitalized terms which are not defined herein, shall have the meaning ascribed to them in our Terms of Service (the “<b>TOS</b>”) available at https://www.inforeach.org/terms-of-service, which this Privacy Policy is incorporated thereto by reference.<p/>
            <b>1. Legal bases for collection and use of personal information</b>
            We collect, process, and use your information for the purposes described in this Privacy Policy, based at least on one of the following legal grounds:  <br/>
            <ul>
                <li>With your consent: We ask for your agreement to process your information for specific purposes and you have the right to withdraw your consent at any time. We will always ask for your additional, explicit consent before sharing identifying information with clinical trial study teams or our Partners.</li>
                <li>When performing this Agreement: We collect and process your personal information in order to provide you with the Service, following your acceptance of this Privacy Policy; to maintain and improve our Service; to develop new services and features for our Users; and to personalize the services in order for you to get a better user experience.</li>
                <li>Legitimate interests: We process your information for our legitimate interests while applying appropriate safeguards that protect your privacy. This means that we process your information for things like detecting, preventing, or otherwise addressing fraud, abuse, security, usability, functionality or technical issues with our services, protecting against harm to the rights, property or safety of our properties, or our Users, or the public as required or permitted by law; enforcing legal claims, including investigation of potential violations of this Privacy Policy; in order to comply and/or fulfil our obligation under applicable laws, regulation, guidelines, industry standards and contractual requirements, legal process, subpoena or governmental request, as well as our TOS.</li>
            </ul>
            <b>IMPORTANT:</b><br/>
            Please note: You are not obligated to provide us with any personal or healthcare information. You hereby acknowledge and agree that you are providing us with such information at your own free will, for the purposes described in Section 4 below, and that we may retain such personal information in accordance with this Privacy Policy and any applicable laws and regulations.<p/>
            <b>2. Which information we may collect on our Users?</b>
            We may collect three types of data and information from our Users:<br/>
            <ul>
                <li>Personal Information, which is individually identifiable information, that identifies an individual or may with reasonable effort identify an individual, either alone or in combination with other information, or may be of private or sensitive nature. Examples are User ID assigned by the Platform, date of birth, email address, and IP addresses.</li>
                <li>Non-personal Information, which is non-identifying and non-identifiable information, without particular reference to the identity of the User from which such information was collected.</li>
                <li>Healthcare Information, is any medical information, including diagnosis, treatment, prescription, device, therapy, test, result, counseling, laboratory and any other information related to services or supplies related to your health. Provision of healthcare information is purely voluntary.</li>
            </ul>
            For the avoidance of doubt, any Non-personal Information and/or Healthcare Information connected or linked to any Personal Information shall be deemed as Personal Information as long as such connection or linkage exists. InfoReach may aggregate or statistically analyze Personal Information from more than one User, in which case such resulting aggregated or statistically analyzed data will not be treated as Personal Information.<p/>
            Information which is being gathered in connection with the Service may consist of the following:<br/>
            <ul>
                <li>If a User registers to the Service (via the Site), such User may voluntarily provide, but is under no obligation to provide, date of birth, biological sex, email address, medical conditions of interest, and other information about medical conditions.</li>
                <li>You may also choose to provide Healthcare Information such as blood and other test results, biopsy results, therapist summaries, treatment history, etc.</li>
                <li>As the User enters and/or uses the Service, he/she may automatically provide us with his/her IP address or other persistent user and/or mobile device token, advertising ID (if applicable to user who is watching or otherwise disclosed to a campaign, advertising content, etc), which is used mainly for enhancing the User’s experience and for geolocation, personalization and security purposes, as further detailed below.</li>
                <li>The User of the Service may also automatically provide us with certain technical Non-personal Information and aggregated usage information, such as the User’s device type, operating system, browser type and version, screen resolution, browser and keyboard language, the User’s “click-stream” and activities on the Service, the period of time the User visited the Service and related time stamps, etc.</li>
                <li>Please note that to use the Service, you must at least provide such information that is clearly marked as “Required”, while any other information may be withheld or provided by you voluntarily and at your discretion, however it may help us improve the accuracy and reliability of our Service, and your overall experience from using the Service. You hereby agree and acknowledge that any Personal or Health Information is provided at Your own free will and pursuant to these privacy practices.</li>
            </ul>
            Please note that to use the Service, you must at least provide such information that is clearly marked as “Required”, while any other information may be withheld or provided by you voluntarily and at your discretion, however it may help us improve the accuracy and reliability of our Service, and your overall experience from using the Service. You hereby agree and acknowledge that any Personal or Health Information is provided at Your own free will and pursuant to these privacy practices.<p/>
            We will not collect any Personal Information from you or related to you without your approval, which is obtained, inter alia, through your active acceptance of the TOS and this Privacy Policy and your active interaction with the Service.<p/>
            <b>3. How do we collect Information on our Users?</b><br/>
            We use the following methods of collection:<br/>
            <ul>
                <li>We collect Non-personal Information through your use of the Service. In other words, we may be aware of your use of the Service, and may gather, collect, and record the information relating to such usage, either independently or through the help of third-party services as detailed below. We may also collect Non-personal Information through the processing, analysis, and anonymization of Personal Information provided by you.</li>
                <li>We collect Personal and Health Information which you provide us voluntarily. For example, we collect Personal Information during your use of the Service, including when you register and open an Account by completing the registration form, when you provide us additional information during your use of the Service, or when you contact us directly.</li>
            </ul>
            We store the Non-personal, Personal, and Health Information either independently or with the help of our authorized third-party service providers as detailed below.<p/>
            <b>4. What are the Purposes of the Collection of Information?</b><br/>
            We collect the above mention information, using advanced technics, in order to provide and operate the Service, and to give our Users information to use as applicable; to study the Information, for personalized services, notifications; to use on an aggregated, statistical basis to discover patterns and anomalies; to enable us to further develop, customize and improve the Service based on Users’ common preferences, uses, attributes and anonymized data; to enable us to provide our Users with a better user experience, with more relevant and accurate information, services, third party services, features and functionalities, statistical and research purposes, and to deliver advertising and other commercial or sponsored content from InfoReach’s authorized partners which may be valuable to our Users, etc.<p/>
            We may share aggregated data (such as statistics about common metastasizes) with third parties for research purposes and to discover patterns and anomalies regarding such things as complications and treatment issues or to help to develop better treatments and potentially manage complications or for other purposes. If required by any applicable laws, FDA regulations or other obligations, we may share information about adverse events or product problems provided voluntarily by Users. We will not share any identifying information regarding any particular User without such User prior consent.  <p/>
            <b>5. Where Do We Store Personal Information?</b><br/>
            Information regarding the Users will be maintained, processed and stored by InfoReach and our authorized affiliates and service providers, and as necessary, in secured cloud storage, provided by our third-party service providers.<p/>
            While the data protection laws in the above jurisdictions may be different than the laws of your residence or location, please know that InfoReach, its affiliates and service providers that store or process your Personal Information on InfoReach’s behalf are each committed to keep it protected and secured, pursuant to this Privacy Policy and industry standards, regardless of any lesser legal requirements that may apply in their jurisdiction.<p/>
            You hereby accept the place of storage and the transfer of information as described above.<p/>
            For more information regarding this compliance, you can visit the following links: https://aws.amazon.com/privacy/<p/>
            <b>6. Sharing Information with Third Parties</b><br/>
            InfoReach will not share any Personal or Health Information it collects with any third party, except in the following cases: (a) to provide the Services, at your request and choice, including certain features you elect to use that enable communications between Users and clinical trial study teams and InfoReach’s third-party authorized partners; (b) to satisfy any applicable law, regulation, legal process, subpoena or governmental request; (c) to enforce this Privacy Policy and/or the TOS, including investigation of potential violations thereof; (d) to detect, prevent, or otherwise address fraud, security or technical issues; (d) to respond to User’s support requests; (e) to respond to claims that any content available on the Service violates the rights of third-parties; (f) to protect the rights, property, or personal safety of InfoReach, any of its Users, or the general public; (g) when InfoReach is undergoing any change in control, including by means of merger, acquisition or purchase of all or substantially all of its assets; (h) to collect, hold use and/or manage your Personal Information through InfoReach’s authorized third-party service providers, as reasonable for business purposes (including for purposes of providing and promoting the Services); (i) to provide advertising and other commercial or sponsored content from InfoReach’s authorized partners which may be valuable to you or (j) pursuant to your explicit approval prior to the disclosure.<p/>
            For avoidance of doubt, InfoReach may transfer and disclose to third-parties or otherwise use Non-personal Information at its own discretion.<p/>
            <b>7. Modification or Deletion of Personal Information</b><br/>
            InfoReach will maintain your Personal and Health Information as long as you are using our Services. If for any reason you wish to modify, update, revise and/or delete your Personal or Health Information stored with us and identifies you, you may do so on the Service by editing your information on your Account, or by deleting your Account from the Service, in which case we will delete any such Personal Information pursuant to any applicable privacy laws. Note that we may retain your Personal Information for a minimum period required to provide the Services. We will also retain your personal information as necessary to comply with our legal obligations, to resolve disputes, to prevent fraud and abuse, to enforce our agreements and/or protect our legitimate interests.<p/>
            Where we no longer need to process your personal information for the purposes set out in this Privacy Policy, or following your request to delete your Personal Information, we will delete your Personal Information from our systems and\or depersonalize your information. Please note that InfoReach may also depersonalize (and un-identifiable) your Service activity history for the purpose of enhancing and improving our Services. <p/>
            If you would like, we can provide you all your personal information which you provided to us in a structured, commonly used and machine-readable format. To do so, see our contact information below.<p/>
            <b>8. Minors</b><br/>
            To use the Service, you must be over the age of eighteen (18). InfoReach does not knowingly collect Personal Information from children under the age of eighteen (18), unless such Personal Information was provided by the User’s family member who is over the age of eighteen (18) and has all of the required consents, approvals, and rights to upload Personal Information on the minor’s behalf. We reserve the right to request proof of age at any stage so that we can verify that minors under the age of eighteen (18) are not using the Service. In the event that it comes to our knowledge that a person under the age of eighteen (18) is using the Service, we reserve the right to prohibit and block such User from accessing the Service.<p/>
            <b>9. Cookies & Local Storage</b><br/>
            A cookie is a small data file that a website saves on your computer or mobile device when you visit the website. Cookies are widely used for record-keeping purposes in order to make website work, or work more efficiently, as well as to provide information to the owners of the website.<p/>
            Like many Websites and applications, when you access or use the Service, we may use industry-wide technologies such as “cookies” or similar technologies, which stores certain information on your computer (“Local Storage”) and which will allow us to enable automatic activation of certain features and make your Service experience much more convenient and effortless. It is easy to prohibit the Local Storage, erase cookies from your computer’s hard drive, block acceptance of cookies, or receive a warning before a cookie is stored, by instructing your browser or device and changing its options. However, if you block or erase cookies your online experience may be limited.<p/>
            We may also use certain third-party cookies. These are a different kind of cookies, stored on your computer by third parties, rather than by us. This kind of cookie is read-only each time you visit the Service, and also when you visit certain websites or applications that use similar cookies. Third-party cookies usually store only a Non-personal Information, such as the web pages you have visited, the duration of your browsing, etc. For more information regarding those third-party read section 12.<p/>
            <b>10. Direct Marketing</b><br/>
            You hereby agree that we may use your contact details for the purpose of informing you regarding our products and services (which may interest you, and to send to you other marketing material, including news, updates and relevant medical data) as part of the Services, and/or transmitted to the e-mail address or phone number you have provided or by displaying you this information on the Application to you.<p/>
            As part of the Service, InfoReach may also contact you for the purpose of informing you of clinical trials that might be relevant to you. Such information shall not be considered as medical advice, as further detailed in the TOS.<p/>
            You may withdraw your consent via sending a written notice to InfoReach by email to the following address info@inforeach.org.<p/>
            Please note that InfoReach may also contact you with important information regarding our Services. For example, we may notify you (through any of the means available to us) of changes or updates to our Services, service maintenance, etc. You will not be able to opt-out of receiving such service messages.<p/>
            <b>11. Security</b><br/>
            We take a great care in implementing and maintaining the security of the Service, and our Users’ Personal Information. The Personal Information, or parts thereof, is hosted on AWS Amazon servers, which provide advanced security features. InfoReach employs industry standard procedures and policies to ensure the safety of its Users’ Personal Information, and prevent unauthorized use of any such information, including encryption, Secure Socket Layer (SSL) and Transport Layer Security (TLS). However, we do not and cannot guarantee that unauthorized access will never occur.<p/>
            We recommend using the strongest password combination available on your mobile device to protect unauthorized access to your mobile device.<p/>
            <b>12. Transfer of Your Information / Third-Party Software/Service</b>
            InfoReach has partnered with selected service providers, whose services and solutions complement, facilitate and enhance our own. These include hosting, database and server co-location services (such as AWS) and our business, legal and financial advisors (collectively, “Third-Party Services“). Such Third-Party Services may receive or otherwise have access to our Users’ personal information controlled, collected and/or processed by us under this Privacy Policy, depending on each of their particular roles and purposes in facilitating and enhancing our Services and business, and may only use it for such purposes. We, as the controller of the information collected from its Users in connection with the use of the Services, use reasonable effort to review the personal information processing practicing done by Third-Party Services on our behalf, and to the extent required under applicable law we are responsible for such processing to the extent that it is not outside of our reasonable control. By providing such information, you expressly consent to such transfer and use, including transfers outside of the jurisdiction in which the information was provided.<p/>
            <b>13. Third-Party Websites</b><br/>
            While using the Service you may encounter links to third-party websites and/or services and/or products. Please be advised that such third-party websites and/or services and/or products are independent from InfoReach, and may use cookies and other web-tracking technologies to collect Non-Personal and/or Personal Information about you. We assume no responsibility or liability whatsoever with regard to privacy matters or any other legal matter with respect to such third-party websites and/or services and/or products. We encourage you to carefully read the privacy policies and the terms of use of such third-party websites and/or services, as their terms, not ours, will apply to any of your interactions with such third parties. InfoReach has no control over the use of any information you provide by these third-party services.<p/>
            <b>14. Changes to the Privacy Policy</b>
            InfoReach reserves the right to change this Privacy Policy at any time, so please re-visit this page frequently. We will provide notice of substantial changes of this Privacy Policy on the Service and/or we will send you an e-mail regarding such changes to the email address that is registered with your Account. Such substantial changes will take effect seven (7) days after such notice was provided on any of the above mentioned methods. Otherwise, all other changes to this Privacy Policy are effective as of the stated “Last Revised” date, and your continued use of the Service after the Last Revised date will constitute acceptance of, and agreement to be bound by, those changes.<p/>
            <b>15. Compliance with USA Privacy Regulations</b><br/>
            InfoReach is not a Covered Entity or Business Associate of any Covered Entity. According, any Healthcare Information you provide voluntarily is not protected by the Health Insurance Portability and Accountability Act of 1996, and regulations promulgated there-under, including the Standards for Security  and Privacy of Individually Identifiable Health Information at 45 Code of Federal Regulations Parts 160 through 164 (“Privacy and Security Regulations”), as amended from time to time, in addition to the Health Information Technology for Economic and Clinical Health Act, enacted as part of the American Recovery and Reinvestment Act of 2009, Public Law 111-005.  Moreover, this information-focused Service is not a Medical Device, and thereby is not regulated by the Federal Food, Drug & Cosmetic Act.<p/>
            <b>16. Have any Questions?</b><br/>
            If you have any questions (or comments) concerning this Privacy Policy or if you would like to submit a request of any kind, you are welcome to send us an email at info@inforeach.org and we will make an effort to reply as soon as possible. We may ask you to provide us certain personal information in order for us to identify you.<p/>
            By contacting us, you represent that you are free to do so and that you will not knowingly provide InfoReach with information that infringes upon third parties’ rights, including any intellectual property rights. You further acknowledge that notwithstanding anything herein to the contrary, any and all rights, including intellectual property rights in such information provided, shall belong exclusively to InfoReach, and InfoReach may use or refrain from using any such information at its sole discretion.

            </>
        )
    },
}