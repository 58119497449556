import React, {useEffect} from 'react';
import StandardOfCare from './StandardOfCare';
import Pagination from '@mui/material/Pagination';
import Box from '@mui/material/Box';
import { TREATMENT_TYPE_MAP } from '../trials/TrialsConstants';

export default function StandardOfCares({allStandardOfCares, treatmentName, filters, setFilteredStandardOfCaresCallBack}) {
    
    const [pages, setPages] = React.useState(null);    
    const [pageIndex, setPageIndex] = React.useState(0);

    // useEffect does the callback whenever allTrials state changes
    // Need to use callback because state change is async
    useEffect(() => {
        const filterStandardOfCares = () => {

            const eligibilityMatch = (soc) => {
                if (!soc.eligibilityResult) {
                    return true;
                }
                const eligibility = soc.eligibilityResult.eligibility;
                if (!filters.matched && eligibility === 'PROFILE_MATCHED')
                    return false;
                if (!filters.notsuitable && eligibility === 'NOT_ELIGIBLE')
                    return false;
                if (!filters.lackofinfo && eligibility === 'LACK_OF_INFO')
                    return false;
                return true;
            }
    
            const recommendationMatch = (soc) => {
                
                if (!filters.strong && soc.recommendation === "strong")
                    return false;
                if (!filters.moderate && soc.recommendation === "moderate")
                    return false;
                if (!filters.weak && soc.recommendation === "weak")
                    return false;
                if (!filters.notRecommended && soc.recommendation === "notRecommended")
                    return false;
                return true;
            };
    
            
            const treatmentTypeMatch = (soc, treatmentTypeFilters) => {
                const includedTreatmentTypes = soc.treatmentDetails.filter(detail => treatmentTypeFilters.includes(detail.treatmentType));
                
                return includedTreatmentTypes.length > 0;
            }

            const treatmentNameMatch = (soc) => {
                var match = false;
                soc.treatmentDetails.forEach(item => {
                    item.typeahead.forEach( t => {
                        if (t === treatmentName) {
                           match = true;
                        }
                    })
                });
                return match;
            }

            const treatmentTypeFilters = Object.entries(TREATMENT_TYPE_MAP).filter( entry => filters[entry[0]]).map(entry => entry[1].rawName);

            return allStandardOfCares.filter(soc => {
                const filterResult1 = recommendationMatch(soc) && treatmentTypeMatch(soc, treatmentTypeFilters) && eligibilityMatch(soc);
                const filterResult2 = treatmentName ? treatmentNameMatch(soc) : true;
                return filterResult1 && filterResult2;
            })
        }
        const refreshPage = () => {
            if (allStandardOfCares)  { 
                const socs = filterStandardOfCares(); 
                updatePages(socs);                
                setFilteredStandardOfCaresCallBack(socs);
            }
        }
        refreshPage()
    }, [allStandardOfCares, filters, treatmentName, setFilteredStandardOfCaresCallBack])

    const handlePaginationChange = (event, value) => setPageIndex(value - 1);

 

    const updatePages = (socs) => {
        const pageSize = 50;

        const tempPages = [];
        const pageCount = Math.ceil(socs.length / pageSize);
        for(let i = 0; i < pageCount; i++) {
            const itemCount = Math.min(pageSize, socs.length - i * pageSize);
            const page = [];
            for(let j=0; j<itemCount; j++) {
                const soc = socs[ i * pageSize + j];
                
                page.push(<><p/><StandardOfCare standardOfCare={soc} /><p/></>);
        
            }
            tempPages.push(page);
        }
        setPages(tempPages);
        setPageIndex(0);
    }

    return (
        <Box sx={{ width: '100%'}}>
        {pages && <Pagination count={pages.length} onChange={handlePaginationChange} />}
        <Box sx={{overflow: 'scroll', height: '800px'}} >
        {pages && pages[pageIndex]}
        </Box>
        </Box>
    );
}
