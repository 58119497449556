export const CONDITION_LIST = [
"abdominal aortic aneurysm",
"abdominal colic",
"abscess of breast",
"acanthosis nigricans",
"achalasia",
"acid reflux",
"acne",
"acoustic neuroma",
"acquired immunodeficiency syndrome",
"acromegaly",
"actinic keratoses",
"actinic keratosis",
"actinomycosis",
"acute cholecystitis",
"acute laryngitis",
"acute lower respiratory tract infection",
"acute lymphoblastic leukaemia",
"acute lymphocytic leukemia",
"acute myeloid leukaemia",
"acute pancreatitis",
"acute respiratory distress syndrome",
"addison disease",
"addisons disease",
"adrenal cushing's syndrome",
"adult non-hodgkin lymphoma",
"adult type dermatomyositis",
"age related macular degeneration",
"aids",
"albinism",
"alcohol-related liver disease",
"alcoholic relative",
"alkaptonuria",
"allergic rhinitis",
"allergic rhinitis (disorder)",
"alopecia",
"altitude sickness",
"amblyopia",
"ambulatory status - oxygen therapy",
"amyloidosis",
"anal cancer",
"anal carcinoma",
"anal fissure",
"anaphylaxis",
"androgen insensitivity syndrome",
"androgen-insensitivity syndrome",
"anemia",
"anemia, sickle cell",
"angelman syndrome",
"angelman's syndrome",
"ankylosing spondylitis",
"anosmia",
"antiphospholipid syndrome",
"appendicitis",
"arrythmia",
"arteritis, temporal",
"arthritis",
"arthritis, bacterial",
"arthritis, psoriatic",
"arthritis, reactive",
"articular hypermobility syndrome",
"asbestosis",
"aspergillosis",
"asthma",
"astigmatism",
"ataxia",
"atherosclerosis",
"athletes foot",
"atopic eczema",
"atrial fibrillation",
"atrial fibrillation, ctcae",
"autosomal dominant",
"autosomal dominant multiple pterygium syndrome",
"autosomal dominant polycystic kidney disease",
"autosomal recessive polycystic kidney disease",
"avian flu",
"back pain",
"back pain, ctcae 3.0",
"bacteremia",
"bacterial vaginosis",
"balanitis",
"bartholin's cyst",
"bedbugs",
"behcet syndrome",
"behcets disease",
"bell palsy",
"bells palsy",
"benign brain tumour",
"benign neoplasm of brain, unspecified",
"benign prostate enlargement",
"benign prostatic hyperplasia",
"bilateral cataracts (disorder)",
"bile duct cancer",
"biliary cirrhosis, primary",
"bird flu",
"black eye",
"bladder cancer",
"bladder pain syndrome",
"bladder stones",
"blepharitis",
"blindness",
"blisters",
"block, heart",
"blood in semen",
"blood in urine",
"blood poisoning",
"blood pressure",
"blood pressure finding",
"body odor",
"body odour",
"boils",
"bone cancer",
"bone diseases",
"bone spur",
"bowel cancer",
"bowel incontinence",
"bowen's disease",
"bowens disease",
"brain abscess",
"brain aneurysm",
"brain cancer",
"brain neoplasms",
"brain tumour",
"brain tumours",
"breast abscess",
"breast cancer",
"breast carcinoma",
"breast lumps",
"broken",
"bronchiectasis",
"bronchiolitis",
"bronchitis",
"brucellosis",
"brugada syndrome",
"brugada syndrome (disorder)",
"bulging eyes",
"bulla",
"bullous pemphigoid",
"bursitis",
"buttock pain",
"buttock pain, ctcae 3.0",
"cancer",
"cancer in children",
"cancer of nose",
"cancer, eye",
"cancer, kidney",
"cancer, mouth",
"cancer, nose",
"cancer, skin",
"cancer, stomach",
"cancer, thyroid",
"cancer, tongue",
"carcinoid syndrome",
"cardiac arrhythmia",
"cardiomyopathy",
"cardiovascular disease",
"cardiovascular diseases",
"carpal tunnel syndrome",
"cataracts",
"cavernoma",
"cavernous sinus thrombosis",
"celiac disease",
"cell disease, sickle",
"central neuroblastoma",
"cerebellar ataxia",
"cerebral abscess",
"cerebral palsy",
"cervical cancer",
"cervical spondylosis",
"cervix carcinoma",
"charcot marie tooth disease",
"charcot-marie-tooth disease",
"charles bonnet syndrome",
"chest infection",
"chickenpox",
"chilblains",
"childhood myelodysplastic syndrome",
"cholangiocarcinoma",
"cholecystitis",
"cholecystitis, ctcae 3.0",
"cholera",
"cholesteatoma",
"choriocarcinoma",
"chronic fatigue",
"chronic fatigue syndrome",
"chronic interstitial cystitis",
"chronic kidney disease",
"chronic kidney diseases",
"chronic lymphocytic leukaemia",
"chronic lymphocytic leukemia",
"chronic myelogenous leukemia, bcr-abl1 positive",
"chronic myeloid leukemia",
"chronic obstructive airway disease",
"chronic obstructive pulmonary disease",
"chronic traumatic encephalopathy",
"cirrhosis",
"classical phenylketonuria",
"clostridium difficile infection",
"cluster headache",
"cluster headaches",
"coeliac disease",
"cold sores",
"colic",
"colitis, ulcerative",
"colon cancer",
"color blindness",
"color vision defect",
"colour blindness, nos",
"colour vision deficiency, nos",
"coma",
"comatose",
"common cold",
"communicable diseases",
"compartment syndrome",
"compartment syndromes",
"complete trisomy 18 syndrome",
"complex regional pain syndrome",
"complex regional pain syndromes",
"condylomata acuminata",
"confusion",
"confusion, ctcae 3.0",
"congenital hip dislocation",
"conjunctivitis",
"consent type - hysterectomy",
"constipation",
"constipation, ctcae",
"contact dermatitis",
"cornea transplant",
"coronary heart disease",
"corticobasal degeneration",
"costochondritis",
"cough",
"cough, ctcae",
"covid 19",
"covid-19",
"crack",
"cracked",
"cradle cap",
"creutzfeldt-jacob disease",
"creutzfeldt-jakob disease",
"crohn disease",
"crohns disease",
"croup",
"cumulative trauma disorders",
"cushing syndrome",
"cutaneous melanoma",
"cyclical vomiting syndrome",
"cyclical vomiting syndrome (disorder)",
"cyst of bartholin's gland duct",
"cyst of skin",
"cystic fibrosis",
"cystitis",
"cystitis, ctcae 3",
"dandruff",
"deafness",
"decompression sickness",
"decreased libido",
"deep vein thrombosis",
"deficiency, iron",
"degenerative polyarthritis",
"dehydration",
"delayed puberty",
"delayed puberty, ctcae",
"dementia with lewy bodies",
"dementia, vascular",
"dengue",
"dengue fever",
"dental abscess",
"dental caries",
"dermatitis, atopic",
"dermatomyositis nos",
"detached retina",
"developmental dysplasia of the hip",
"diabetes",
"diabetes in pregnancy",
"diabetes insipidus",
"diabetes mellitus",
"diabetes mellitus, insulin-dependent",
"diabetes mellitus, non-insulin-dependent",
"diabetic",
"diabetic ketoacidosis",
"diabetic retinopathy",
"diaper rash",
"diarrhea",
"diarrhea, ctcae",
"difficulty speaking",
"difficulty talking",
"digeorge syndrome",
"digeorge's syndrome",
"diplopia",
"discoid eczema",
"disease",
"disease, hirschsprung's",
"disease, lyme",
"disease, polycystic kidney",
"disease, weil's",
"disorder",
"disorders",
"diverticular disease",
"diverticular diseases",
"diverticulitis",
"dizziness",
"dizziness, ctcae 3.0",
"does pull",
"does swallow",
"double vision",
"down syndrome",
"downs syndrome",
"dry lips",
"dry lips (finding)",
"dry mouth",
"dupuytren contracture",
"dupuytrens contracture",
"dvt",
"dysenteric diarrhea",
"dysentery",
"dyshidrotic eczema",
"dysmenorrhea",
"dysphagia",
"dysphagia, ctcae",
"dystrophy, muscular",
"ear infections",
"early menopause",
"ebola virus disease",
"ectopic beats",
"ectropion",
"eczema",
"edema",
"edema:finding:point in time:^patient:ordinal",
"edwards syndrome",
"ehlers-danlos syndrome",
"ehlers-danlos syndromes",
"elbow, tennis",
"embolism, nos",
"embolus",
"empyema",
"encephalitis",
"encephalitis caused by tick-borne encephalitis virus",
"encephalitis, japanese",
"encephalitis, tick-borne",
"endocarditis",
"endometrial cancer",
"endometrial carcinoma",
"endometriosis",
"epididymitis",
"epiglottitis",
"epilepsy",
"erectile dysfunction",
"erectile dysfunction, ctcae",
"erythema multiforme",
"erythema nodosum",
"erythrocytosis",
"erythromelalgia",
"esophageal achalasia",
"essential tremor",
"ever diagnosed by a doctor as having an abdominal aortic aneurysm:finding:point in time:^patient:ordinal",
"ever told by doctor or nurse that you have high blood pressure:finding:point in time:^patient:ordinal",
"ever told by doctor that you had deep venous thrombosis:finding:point in time:^patient:ordinal",
"ewings sarcoma",
"exanthema subitum",
"excessive hair growth",
"excessive sweating",
"exophthalmos",
"exostoses",
"eye floaters",
"eye infection",
"face blindness",
"failure, kidney",
"family history of stroke",
"family history: cardiomyopathy (situation)",
"fasciitis, plantar",
"febrile convulsions",
"febrile seizures",
"fecal incontinence",
"feeling suicidal (finding)",
"female breast carcinoma",
"fever",
"fever in children",
"fever symptoms (finding)",
"fever, glandular",
"fever, q",
"fever, rheumatic",
"fever, scarlet",
"fever, typhoid",
"fever, yellow",
"fibroids",
"fibromyalgia",
"fibrosis, idiopathic pulmonary",
"finding of corneal transplant",
"finding of tear meniscus (eye)",
"fish odour syndrome",
"flashes",
"flatulence",
"flatulence, ctcae",
"floaters",
"flu",
"folate deficiency",
"folate deficiency anaemia",
"folic acid deficiency",
"food intolerance",
"food intolerance (disorder)",
"foot and mouth disease",
"foot drop",
"foot-and-mouth disease",
"footdrop",
"frontotemporal dementia",
"frozen shoulder",
"fungal infection",
"fungal nail infection",
"furuncle",
"fusion of labia (finding)",
"gallbladder cancer",
"gallbladder carcinoma",
"gallbladder pain",
"gangrene",
"gastritis",
"gastritis, ctcae",
"gastroenteritis",
"gastroesophageal reflux disease",
"gastrointestinal mucositis",
"gastrooesophageal reflux disease",
"gastroparesis",
"genital herpes",
"genital warts",
"german measles",
"gestational diabetes",
"gestational trophoblastic neoplasms",
"giant cell arteritis",
"giardiasis",
"gigantism",
"gilbert disease (disorder)",
"gilbert's syndrome",
"gilles de la tourette syndrome",
"gingival diseases",
"glomerulonephritis",
"glucose in blood specimen above reference range",
"glue ear",
"glutaric aciduria type 1",
"glutaric aciduria, type 1",
"goiter",
"goitre",
"gonorrhea",
"gonorrhoea",
"gout",
"granuloma annulare",
"granulomatosis with polyangiitis",
"gray skin",
"grey skin",
"guillain barré syndrome",
"guillain-barre syndrome",
"gum disease",
"haemophilia",
"hair diseases",
"hair disorder",
"hair loss",
"hairy cell leukaemia",
"hairy cell leukemia",
"hay fever",
"hayfever",
"head and neck cancer",
"hearing loss",
"hearing loss, partial",
"heart attack",
"heart block",
"heart disease, nos",
"heart diseases",
"heart failure",
"heart palpitations",
"heat rash",
"heat, prickly",
"helminthiasis",
"hemangioma, cavernous",
"hematochezia",
"hematuria",
"hemophilia, nos",
"hemorrhagic fever, ebola",
"hemorrhoids",
"hemorrhoids, ctcae 3.0",
"hemospermia",
"henoch-schoenlein purpura",
"henoch-schönlein purpura",
"hepatitis",
"hepatitis a",
"hepatitis b",
"hepatitis c",
"hepatobiliary disorder",
"hereditary haemorrhagic telangiectasia",
"hereditary hemorrhagic telangiectasia",
"hereditary neuropathy",
"hereditary pancreatitis",
"hereditary spastic paraplegia",
"herpes",
"herpes labialis",
"herpes nos",
"herpes simplex infections",
"herpes zoster disease",
"herpetic whitlow",
"hiatal hernia",
"hiatus hernia",
"hiccough",
"hiccups",
"hidradenitis suppurativa",
"high blood pressure",
"high blood sugar",
"high cholesterol",
"hip dislocation, congenital",
"hirschsprung disease",
"hirsutism",
"hives",
"hodgkin disease",
"hodgkin lymphoma",
"homocystinuria",
"hook worm",
"hookworm infections",
"hordeolum externum",
"hydatidiform mole",
"hydrocephalus",
"hydronephrosis",
"hypercholesterolemia result",
"hyperglycemia",
"hyperhidrosis disorder",
"hyperidrosis",
"hyperopia",
"hyperparathyroidism",
"hypersomnia",
"hypertension",
"hypertension, ctcae",
"hyperthyroidism",
"hypertrichosis",
"hypoglycemia",
"hypoparathyroidism",
"hypotension",
"hypothermia",
"hypothermia, natural",
"hypothyroidism",
"hysterectomy",
"ichthyoses",
"icthyosis",
"idiopathic chronic inflammatory bowel disease",
"idiopathic pulmonary fibrosis",
"impetigo",
"implants",
"impotence",
"incontinence",
"incontinence, urinary",
"increased sweating",
"infected",
"infection of ear",
"infection of kidney",
"infection, nos",
"infection, sinus",
"infectious mononucleosis",
"infective vaginitis",
"infestation by bed bug",
"inflammatory bowel disease",
"influenza",
"influenza in birds",
"ingrown hairs",
"ingrown toenail",
"inherited neuropathy",
"insomnia",
"insomnia, ctcae 3.0",
"interstitial cystitis",
"intestinal cancer",
"intolerance, lactose",
"intracranial hypertension",
"iron deficiency",
"iron deficiency anaemia nos",
"iron deficiency anemia",
"irregular menstruation",
"irregular periods",
"irritable bowel syndrome",
"isovalericacidemia",
"isovaleryl-coa dehydrogenase deficiency",
"japanese encephalitis",
"jaundice newborns",
"jet lag syndrome",
"jetlag",
"joint laxity, familial",
"joint pain",
"joint pain, ctcae 3",
"kaposi sarcoma",
"kaposi's sarcoma",
"kawasaki disease",
"keratosis pilaris",
"kidney calculi",
"kidney failure",
"kidney infection",
"kidney stones",
"knock",
"kyphosis",
"labial fusion",
"labyrinthitis",
"lack of libido",
"lactose intolerance",
"lambert eaton myasthenic syndrome",
"lambert-eaton myasthenic syndrome",
"lameness",
"large prostate",
"laryngeal cancer",
"laryngitis",
"lazy eye",
"leg ulcer",
"legionnaires disease",
"legionnaires' disease",
"leptospirosis",
"leucoplakia",
"leukemia",
"leukemia, myelocytic, acute",
"leukoplakia",
"lewy body disease",
"lichen planus",
"lichen sclerosus",
"lichen sclerosus et atrophicus",
"limping",
"lipedema",
"lipoedema",
"lipoma",
"listeriosis",
"liver and intrahepatic biliary tract carcinoma",
"liver cancer",
"liver disease",
"long q-t syndrome",
"long qt syndrome",
"longsightedness",
"loss of libido",
"low blood pressure",
"low blood sugar",
"low sex drive",
"low white blood cell count",
"lumps",
"lung cancer",
"lupus",
"lupus erythematosus, discoid",
"lyme disease",
"lymphadenopathy",
"lymphoma, non-hodgkin",
"macular degeneration",
"macular hole",
"macular holes",
"malaria",
"malignant brain tumour",
"malignant carcinoid syndrome",
"malignant childhood neoplasm",
"malignant head and neck neoplasm",
"malignant mesothelioma",
"malignant neoplasm of brain",
"malignant neoplasm of esophagus",
"malignant neoplasm of kidney",
"malignant neoplasm of larynx",
"malignant neoplasm of mouth",
"malignant neoplasm of ovary",
"malignant neoplasm of penis",
"malignant neoplasm of skin",
"malignant neoplasm of stomach",
"malignant neoplasm of tongue",
"malignant neoplasm of urinary bladder",
"malignant neoplasm of vulva",
"malignant testicular germ cell tumor",
"malignant tumor of colon",
"malignant tumor of eye",
"malnutrition",
"maple syrup urine disease",
"mass in breast",
"mass of body structure",
"mastitis",
"mastocytosis",
"mastoiditis",
"measles",
"megaloblastic anemia due to folate deficiency",
"melanoma",
"meniere disease",
"meningitis",
"meniscus tear",
"menopause",
"menopause present (finding)",
"menstrual pain",
"mesothelioma",
"metabolic syndrome",
"metabolic syndrome x",
"middle east respiratory syndrome",
"migraine",
"migraine disorders",
"miliaria",
"miliaria rubra",
"missed period",
"missed periods",
"molar pregnancy",
"molluscum contagiosum",
"morton neuroma",
"morton's neuroma",
"motion sickness",
"motor neuron disease",
"motor neurone disease nos",
"mouth ulcers",
"mucocutaneous lymph node syndrome",
"mucositis",
"multiple myeloma",
"multiple sclerosis",
"mumps",
"muscular atrophy, spinal",
"muscular dystrophy",
"myalgic encephalomyelitis",
"myalgic encephalomyelitis syndrome",
"myasthenia gravis",
"mycobacterium infection",
"mycobacterium infections",
"mycoses",
"myelodysplastic syndrome",
"myeloma",
"myocardial infarction",
"myopia",
"myositis",
"ménières disease",
"nail problem",
"nail problems",
"nail-patella syndrome",
"nails, ingrown",
"nance-horan syndrome",
"nappy rash",
"narcolepsy",
"nasal polyps",
"nasopharyngeal cancer",
"nasopharyngeal carcinoma",
"nausea",
"nausea, ctcae 3.0",
"neck pain",
"neck pain, ctcae 3.0",
"necrotizing fasciitis",
"necrotizing fascitis",
"neonatal herpes",
"neonatal jaundice",
"nephrotic syndrome in children",
"neuralgia, pudendal",
"neuralgia, trigeminal",
"neuroblastoma",
"neuroendocrine tumors",
"neuroendocrine tumours",
"neurofibromatosis 1",
"neurofibromatosis 2",
"neurofibromatosis type i",
"neurofibromatosis type ii",
"neuromyelitis optica",
"newborn jaundice",
"newborn respiratory distress syndrome",
"ngu",
"nhs",
"noise sensitivity",
"non-alcoholic fatty liver disease",
"non-allergic rhinitis",
"non-gonococcal urethritis",
"non-gonococcal urethritis (ngu)",
"non-hodgkin lymphoma",
"nonalcoholic fatty liver disease",
"nonhodgkin lymphoma",
"noonan syndrome",
"noonan's syndrome",
"nummular eczema",
"obesity",
"obesity, ctcae",
"obstructive sleep apnoea",
"ocular screening",
"oesophageal cancer",
"onychomycosis",
"oral candidiasis",
"oral thrush",
"oral ulcer",
"osteitis deformans",
"osteoarthritis",
"osteomalacia",
"osteomyelitis",
"osteopathy nos",
"osteophyte",
"osteoporosis",
"osteosarcoma of bone",
"otitis media with effusion",
"otosclerosis",
"outlet syndrome, thoracic",
"ovarian cancer",
"ovarian cyst",
"ovarian cysts",
"overactive thyroid",
"oxygen therapy",
"paget's disease of the nipple",
"paget's disease, mammary",
"pagets disease of bone",
"pain nos",
"pain nos, ctcae",
"painful bladder syndrome",
"palpitations",
"palsies",
"pancreatic cancer",
"pancreatic carcinoma",
"pancreatitis",
"pancreatitis, acute",
"pancreatitis, chronic",
"pancreatitis, ctcae 3.0",
"paralysed",
"paralysis",
"paralysis, sleep",
"parkinson disease",
"parkinsons disease",
"patau syndrome",
"patau's syndrome",
"pelvic inflammatory disease",
"pelvic organ prolapse",
"pemphigus vulgaris",
"penile cancer",
"pericardial knock",
"pericarditis",
"pericarditis, ctcae",
"period pain",
"peripheral arterial disease",
"peripheral arterial diseases",
"peripheral neuropathy",
"peritonsillar abscess",
"persistent trophoblastic disease",
"pharyngitis",
"phenylketonuria",
"pheochromocytoma",
"phimosis",
"photopsia",
"piles",
"pityriasis rosea",
"pityriasis versicolor",
"pleurisy",
"pms",
"pneumonia",
"polycystic kidney diseases",
"polycystic kidney, autosomal dominant",
"polycystic ovary syndrome",
"polymorphic light eruption",
"polymorphous light eruption",
"polymyalgia rheumatica",
"polymyositis",
"pompholyx",
"post polio syndrome",
"postherpetic neuralgia",
"postpoliomyelitis syndrome",
"postural orthostatic tachycardia syndrome",
"prader-willi syndrome",
"pregnancy in diabetics",
"premature cardiac complex",
"premature menopause",
"premenstrual syndrome",
"pressure",
"pressure (finding)",
"pressure sores",
"pressure ulcer",
"pressure ulcers",
"priapism",
"prickly heat",
"primary biliary cholangitis",
"primary malignant neoplasm",
"primary malignant neoplasm of lung",
"problem of prostate",
"procedure implants:finding:point in time:^patient:narrative",
"progressive supranuclear palsy",
"prolapse",
"prolonged qt interval",
"prosopagnosia, hereditary",
"prostate cancer",
"prostate carcinoma",
"prostate enlargement",
"prostate problem",
"prostatitis",
"pseudofolliculitis barbae (disorder)",
"psoriasis",
"ptosis",
"pudendal neuralgia",
"pulling",
"pulmonary fibrosis",
"pulmonary hypertension",
"pulmonary hypertension, ctcae",
"pyoderma gangrenosum",
"q fever",
"quinsy",
"rectal bleeding",
"rectal cancer",
"rectal carcinoma",
"recurrent urinary tract infection",
"repetitive strain injury",
"respiratory distress syndrome, adult",
"respiratory distress syndrome, newborn",
"respiratory tract infections",
"restless legs syndrome",
"retinal detachment",
"retinal detachment, ctcae 3.0",
"retinal migraine",
"retinoblastoma",
"reye syndrome",
"reyes syndrome",
"rhesus disease",
"rhesus hemolytic disease of the newborn",
"rheumatic fever",
"rheumatoid arthritis",
"rhinitis",
"rhythm",
"rickets",
"ringworm",
"roseola",
"rubella",
"sarcoidosis",
"sarcoma",
"scabies",
"scabies <infestation>",
"scar tissue",
"scarlet fever",
"scars",
"schistosomiasis",
"scleroderma",
"sclerosis, tuberous",
"seborrheic dermatitis of scalp",
"seizure",
"seizure, ctcae 3.0",
"self-harm",
"septic arthritis",
"septicaemia, nos",
"septicemia",
"severe",
"severe (severity modifier)",
"sexually transmitted diseases",
"sexually transmitted infections",
"shingles",
"short sightedness",
"shoulder impingement",
"sialolithiasis",
"sick building syndrome",
"sids",
"silicosis",
"sinus cancer",
"sinusitis",
"sjogren's syndrome",
"sjögren's syndrome",
"skin cancer, melanoma",
"skin cyst",
"skin tag",
"skin tags",
"sleep apnea syndromes",
"sleep apnea, obstructive",
"sleep apnoea",
"sleep paralysis",
"sleepwalking",
"smells of urine",
"smells urine",
"smelly feet",
"soft tissue sarcomas",
"solar keratosis",
"somnambulism",
"sore throat",
"sound sensitivity",
"spastic paraplegia, hereditary",
"spinal muscular atrophy",
"spleen problem",
"spondylolisthesis",
"squamous cell carcinoma",
"squint",
"staph infection",
"staphylococcal infections",
"stasis dermatitis",
"stevens johnson syndrome",
"stevens-johnson syndrome",
"still birth",
"stillbirth",
"stomach ulcer",
"stomach ulcer, ctcae 3",
"stones, salivary gland",
"strabismus",
"strep",
"streptococcal infections",
"stroke",
"stye",
"subarachnoid hemorrhage",
"sudden infant death syndrome",
"suicidal thoughts",
"superficial thrombophlebitis",
"supraventricular tachycardia",
"supraventricular tachycardia, ctcae",
"swallowing",
"sweat rash",
"sweating",
"swelling",
"swellings",
"swollen glands",
"syndrome",
"syndrome, anti-phospholipid",
"syndrome, hughes",
"syphilis",
"systemic scleroderma",
"tachycardia syndrome, postural",
"temporal arteritis",
"temporomandibular disorder",
"temporomandibular joint disorders",
"tendinitis",
"tendonitis",
"tennis elbow",
"tension headache",
"tension headaches",
"testicle pain",
"testicle pain, ctcae 3.0",
"testicular cancer",
"testicular lump",
"testicular mass",
"thalassaemia nos",
"thalassemia",
"thoracic outlet syndrome",
"thrombophilia",
"thyroid carcinoma",
"thyroiditis",
"tick-borne encephalitis",
"tickborne encephalitis",
"tietze's syndrome",
"tight foreskin",
"tinea",
"tinea pedis",
"tinea versicolor",
"tinnitus",
"tinnitus, ctcae 3.0",
"tongue white",
"tonsillitis",
"too much",
"tooth decay",
"toothache",
"toothache, ctcae",
"tourettes syndrome",
"toxic shock syndrome",
"toxocariasis",
"toxoplasmosis",
"transient ischaemic attack",
"transient ischemic attack",
"tremor",
"tremor, ctcae 3.0",
"trichomonas infections",
"trichomoniasis",
"trigeminal neuralgia",
"trigger finger",
"trigger finger disorder",
"trimethylaminuria",
"trisomy 18",
"tuberculosis",
"tuberous sclerosis",
"turner syndrome",
"turners syndrome",
"type 2 diabetes",
"type i diabetes",
"typhoid fever",
"typhus",
"ulcerative colitis",
"underactive thyroid",
"unintentional weight loss",
"urethritis",
"urinary bladder calculi (disorder)",
"urinary incontinence, ctcae 3.0",
"urinary tract infections",
"urticaria",
"uterine fibroids",
"uveitis",
"uveitis, ctcae",
"vagina carcinoma",
"vaginal cancer",
"vaginal dryness",
"vaginal dryness, ctcae 3.0",
"vaginitis nos",
"varicose eczema",
"varicose ulcer",
"varicose veins",
"varicosity",
"vasculitis",
"vasculitis, ctcae",
"venous leg ulcer",
"verrucas",
"vesicular eczema of hands and/or feet",
"vestibular neuritis",
"vestibular neuronitis",
"vestibular schwannoma",
"vision loss",
"vitiligo",
"vitiligo-associated multiple autoimmune disease susceptibility 1 (finding)",
"vitreous floaters",
"vomiting",
"vomiting, ctcae",
"von willebrand disease",
"vulval cancer",
"vulval pain",
"vulvodynia",
"wartlike skin lesion",
"warts, nos",
"weight decreased",
"weight loss",
"weight loss, ctcae",
"weightloss",
"weil disease",
"west nile viral infection",
"west nile virus",
"white blood cell count decreased",
"white tongue",
"whooping cough",
"whooping cough due to unspecified organism",
"wolff-parkinson-white syndrome",
"wolff-parkinson-white syndrome, ctcae",
"women breast cancer",
"worms nos",
"xerostomia",
"yellow fever"
];