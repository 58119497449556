import * as React from 'react';
import { useHistory } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { getModalStyle } from '../utils/ModalUtil';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { getBioposyString, getTNMString, getPSAString, getTreatmentString } from '../utils/ProfileUtil';

const TNM_EMPTY_STRING = "Not entered. (Note that 83% clinical trials include tumor stage information in eligibility criteria)";
const BIOPSY_EMPTY_STRING = "Not entered. (Note that 91% clinical trials include biopsy information in eligibility criteria)";
const PSA_EMPTY_STRING = "Not entered. (Note that 86% clinical trials include PSA level in eligibility criteria)";
const TREATMENT_EMPTY_STRING = "Not entered. (Note that 93% clinical trials require to know treatment history)";

export default function StatusModalPage2({profile, handleStatusCheck2Update, handleStatusCheckBackTo1}) {
    const biopsyString = getBioposyString(profile);
    const tnmString = getTNMString(profile);
    const psaString = getPSAString(profile);
    const treatmentString = getTreatmentString(profile);
    const [open, setOpen] = React.useState(true);
    const history = useHistory();

    const onClose = () => {};

    const onClickBack = () => {
        setOpen(false);
        handleStatusCheckBackTo1();
    }

    const onClickUpdate = () => {
        setOpen(false);
    };
   

    return (
        <Modal
            open={open}
            onClose={onClose}
          >
            <Box sx={getModalStyle(700)}>
            Please check below and see if your <b>Prostate Cancer</b> profile is up to date.
            <p/>
            {biopsyString.length > 0 && <><CheckCircleOutlineIcon sx={{verticalAlign: "middle"}} color="success" /> {biopsyString} </>}
            {biopsyString.length === 0 && <><CancelOutlinedIcon sx={{verticalAlign: "middle"}} color="error" /> Latest Biospy: {BIOPSY_EMPTY_STRING} </>}
            <p/>
            {tnmString.length > 0 && <><CheckCircleOutlineIcon sx={{verticalAlign: "middle"}} color="success" />{tnmString} </>}
            {tnmString.length === 0 && <><CancelOutlinedIcon sx={{verticalAlign: "middle"}} color="error" /> Latest tumor stage: {TNM_EMPTY_STRING} </>}
            <p/>
            {psaString.length > 0 && <><CheckCircleOutlineIcon sx={{verticalAlign: "middle"}} color="success" />{psaString} </>}
            {psaString.length === 0 && <><CancelOutlinedIcon sx={{verticalAlign: "middle"}} color="error" /> Latest PSA level: {PSA_EMPTY_STRING} </>}
            <p/>
            {treatmentString.length > 0 && <><CheckCircleOutlineIcon sx={{verticalAlign: "middle"}} color="success" />{treatmentString} </>}
            {treatmentString.length === 0 && <><CancelOutlinedIcon sx={{verticalAlign: "middle"}} color="error" /> Treatment history: {TREATMENT_EMPTY_STRING} </>}
            <p/>
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                    </Grid>
                    <Grid item xs={8}>
                        <Button size="large" variant="outlined" onClick={onClickBack} sx={{textTransform: "none"}}>Back</Button>&nbsp;&nbsp;
                        <Button size="large" variant="contained" onClick={onClickUpdate} sx={{textTransform: "none"}}>View/edit Profile</Button>                        
                    </Grid>
                </Grid>
            </Box>
          </Modal>
    );

}