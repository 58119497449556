import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Button from '@mui/material/Button';
import { FormHelperText } from '@mui/material';
import Divider from '@mui/material/Divider';
import { isValidDate, isValidZipcode } from '../../utils/Util';

export default function Demographics({profile, handleProfileUpdate}) {

    const [dateOfBirth, setDateOfBirth] = React.useState(profile.userDemographics && profile.userDemographics.dateOfBirth ? dayjs(profile.userDemographics.dateOfBirth) : null);
    const [gender, setGender] = React.useState(profile.userDemographics && profile.userDemographics.gender ? profile.userDemographics.gender : 'male');
    const [zipcode, setZipcode] = React.useState(profile.userDemographics && profile.userDemographics.zipcode ? profile.userDemographics.zipcode : null);
    const [invalidDate, setInvalidDate] = React.useState(false);
    const [invalidZipcode, setInvalidZipcode] = React.useState(false);
    const [saveClicked, setSaveClicked] = React.useState(false);


    const handleGenderChange = (event, value) => {
        setGender(value);
        setSaveClicked(false);
    }

    const handleDateOfBirthChanged = (newValue) => {
        setDateOfBirth(newValue);
        setInvalidDate(false);
        setSaveClicked(false);
    }

    const onClickSave = () => {
        
        var validDate = isValidDate(dateOfBirth);
        var validZipcode = isValidZipcode(zipcode);
        setInvalidDate(!validDate)
        setInvalidZipcode(!validZipcode)

        if ((validDate) && (validZipcode)) {
            handleProfileUpdate({
                userDemographics: {
                    dateOfBirth: dateOfBirth.format('YYYY-MM-DD').toString(),
                    gender: gender,
                    zipcode: zipcode,
                },
            })
        }

        setSaveClicked(true);
    }

    const onZipcodeChange = (event) => {
        setInvalidZipcode(false);
        setZipcode(event.target.value);
        setSaveClicked(false);
    }

    return (
        
    <Box sx={{ width: '100%', maxWidth: '500px'}}>
      
        <div>
            <Grid container spacing={1}>
            <Grid item xs={4}>
                <div style={{ textAlign: 'right', paddingTop: '12px'}}><Typography variant="body1" gutterBottom>Date of birth:&nbsp;&nbsp;</Typography></div> 
            </Grid>
            <Grid item xs={8} sx={{ '.MuiInputBase-root': {height: '40px'}}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                inputFormat="MM/DD/YYYY"
                                value={dateOfBirth}
                                disableFuture
                                onChange={(newValue) => handleDateOfBirthChanged(newValue)}
                                renderInput={(params) => <TextField {...params} 
                                    error={invalidDate}
                                    helperText={invalidDate ? "Invalid date" : ""}    
                                /> }
                                
                                />
                </LocalizationProvider>
            </Grid>
            </Grid>
        </div>

        <p/>
        <div>
            <Grid container spacing={1}>
            <Grid item xs={4}>
                <div style={{ textAlign: 'right', paddingTop: '12px'}}><Typography variant="body1" gutterBottom>Biological sex:&nbsp;&nbsp;</Typography></div> 
            </Grid>
            <Grid item xs={8}>
            <ToggleButtonGroup
                color="primary"
                value={gender}
                exclusive
                onChange={handleGenderChange}
                aria-label="Platform"
                size="small"
                >
                <ToggleButton value="male" sx={{textTransform: "none"}}>Male</ToggleButton>
                <ToggleButton value="female" sx={{textTransform: "none"}}>Female</ToggleButton>
            </ToggleButtonGroup>
            </Grid>
            </Grid>
        </div>

        <p/>
        <Grid container spacing={1}>
            <Grid item xs={4}>
                <div style={{ textAlign: 'right', paddingTop: '12px'}}><Typography variant="body1" gutterBottom>Zipcode:&nbsp;&nbsp;</Typography></div> 
            </Grid>
            <Grid item xs={8}>
            <TextField 
                size="small"
                value={zipcode}
                sx={{width: "100px"}}
                onChange={onZipcodeChange}
                error={invalidZipcode}
                helperText={invalidZipcode ? "Invalid zipcode" : ""}
            />
            </Grid>
            </Grid>
      
      
      <p/>
      <Divider />
      <p/>
      <Grid container spacing={1}>
            <Grid item xs={6}>
            </Grid>
            <Grid item xs={6}>
                <Button size="large" variant="contained" onClick={onClickSave} sx={{textTransform: "none"}}>
                    Save
                </Button>

                <FormHelperText id="button-helper">
                {saveClicked && (!invalidZipcode) ? "Saved!" : ""}
                </FormHelperText>
            </Grid>
       </Grid>
      
    </Box>
    
    )
}