import React from 'react';
import Header from '../layouts/Header';
import SignInHandler from '../signin/SignInHandler';
import Box from '@mui/material/Box';
import { grey } from '@mui/material/colors';
import { useHistory } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { HOME_PAGE_CONTENTS } from './Contents';

const TemplateInfoPage = ({handleAuthSuccess, handleSignOut}) => {
    const history = useHistory();
    const pathname = history.location.pathname;
    const title = HOME_PAGE_CONTENTS[pathname].title;
    const body = HOME_PAGE_CONTENTS[pathname].body;

  return (
    <>
      <Header handleSignOut={handleSignOut} />
      <SignInHandler handleAuthSuccess={handleAuthSuccess} />
      <Box>
        <Grid container spacing={2} sx={{marginTop: 5}}>
          <Grid item xs={1}>
          </Grid>
          <Grid item xs={10}  sx={{background: grey[200]}}>
            <Grid container spacing={2} sx={{marginTop: 3, marginBottom: 4}}>
              <Grid item xs={1}>
              </Grid>
              <Grid item xs={10}>
                  <Typography variant='h6' sx={{ textAlign: "center"}}>
                   {title}
                  </Typography>
                  <p/>
                  <Typography variant='body1'>
                  {body}
                  </Typography>
                </Grid>
                <Grid item xs={1}>            
                </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1}>            
          </Grid>
      </Grid>
        </Box>
    </>    
  );
}

export default TemplateInfoPage;