import * as React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { getModalStyle } from '../utils/ModalUtil';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { UserContext } from '../../App';
import { getBioposyString, getTNMString, getPSAString } from '../utils/ProfileUtil';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/joy/CircularProgress';
import { getEndpoint } from '../utils/BackendAppConfig';
import { isValidEmail } from '../utils/Util';
import useMediaQuery from '@mui/material/useMediaQuery';

const buildLocationDisplayInfo = (location) => {
  if (location.contacts[0].name) {
    return `${location.instituteName}-${location.locationZip} (Contact: ${location.contacts[0].name})`;
  } else {
    return `${location.instituteName}-${location.locationZip}`;
  }
  
}

const buildDoBString = (userProfile) => {
  if (userProfile.userDemographics && userProfile.userDemographics.dateOfBirth) {
    const date1 = new Date(userProfile.userDemographics.dateOfBirth);
    const date2 = new Date();
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    const diffYears = Math.floor(diffDays / 365);

    return (
      <>Age: {diffYears}<br/></>
    )
  } 
}

const buildSexString = (userProfile) => {
  if (userProfile.userDemographics && userProfile.userDemographics.gender) {
    return (
      <>Biological Sex: {userProfile.userDemographics.gender}<br/></>
    )
  } 
}

const buildBiopsyString = (userProfile) => {
  if (userProfile.userBiopsies && userProfile.userBiopsies.length > 0) {
    return (
      <>{getBioposyString(userProfile)}<br/></>
    )
  }
}

const buildTumorStage = (userProfile) => {
  if (userProfile.userTNMs && userProfile.userTNMs.length > 0) {
    return (
      <>{getTNMString(userProfile)}<br/></>
    );
  }
}

const buildPSALevel = (userProfile) => {
  if (userProfile.userLabs && userProfile.userLabs.filter(lab => lab.test === 'TPSA').length > 0) {
    return (
      <>{getPSAString(userProfile)}<br/></>
    );    
  }
}

const buildTreatmentHistory = (userProfile) => {
  if ( (userProfile.userDrugs && userProfile.userDrugs.length > 0) ||
    (userProfile.userSurgeries && userProfile.userSurgeries.length > 0) ||
    (userProfile.userRadiotherapies && userProfile.userRadiotherapies.length > 0)
    ) {
      return (
        <>
        Treatment History: <br/>
        {userProfile.userDrugs && userProfile.userDrugs.map(drug => 
          <><span style={{marginLeft: '20px',display: 'inline-block', width: '450px'}}>(drug) {drug.name}</span><span>{drug.startDate}{drug.endDate && <> - {drug.endDate}</>}</span><br/></>
        )}
        {userProfile.userRadiotherapies && userProfile.userRadiotherapies.map(radio => 
          <><span style={{marginLeft: '20px',display: 'inline-block', width: '450px'}}>(Radiotherapy) {radio.name}</span><span>{radio.startDate}{radio.endDate && <> - {radio.endDate}</>}</span><br/></>
        )}
        {userProfile.userSurgeries && userProfile.userSurgeries.map(surgery => 
          <><span style={{marginLeft: '20px',display: 'inline-block', width: '450px'}}>(surgery) {surgery.name}</span><span>{surgery.date}</span></>
        )}
        <br/>
        </>
      )
  }    

}

const buildOtherConditions = (userProfile) => {
  const conditionsToIgnore = ["prostate cancer", "prostate carcinoma"];
  if (userProfile.userConditions) {
    const result = userProfile.userConditions.filter(condition => !conditionsToIgnore.includes(condition.name)).map(condition => condition.name);
    if (result.length > 0) {
      return (
        <>Other conditions: {result.join(',')}</>
      )
    }
  }
}



export default function ContactClinicModal({trial, bestLocationIndex, handleContactClinicClose}) {

    const userContext = React.useContext(UserContext);
    const userProfile = userContext ? userContext.userProfile : null;
    const [showModal, setShowModal] = React.useState(true);
    const [showCollectInfo, setShowCollectInfo] = React.useState(true);
    const [showPreview, setShowPreview] = React.useState(false);
    const [showSendEmailStatus, setShowSendEmailStatus] = React.useState(false);
    const [sendEmailComplete, setSendEmailComplete] = React.useState(false);
    const [healthInfoIncluded, setHealthInfoIncluded] = React.useState(true);
    const [addAdditionalInfo, setAddAdditionalInfo] = React.useState(false);
    const [emailCustomContent, setEmailCustomContent] = React.useState(""); 
    const [selectedLocationIndex, setSelectedLocationIndex] = React.useState(bestLocationIndex);
    const [invalidEmail, setIsInvalidEmail] = React.useState(false);
    const [email, setEmail] = React.useState(null);

    const isMobileView = useMediaQuery('(max-width:600px)');

    const onClickClose = () => {
      handleContactClinicClose();
    }

    const onIncludeHealthProfileChange = () => setHealthInfoIncluded(!healthInfoIncluded);
    const onAddAdditionalInfoChange = () => setAddAdditionalInfo(!addAdditionalInfo);
    const onAdditionalInfoTextChange = (event) => {
      setEmailCustomContent(event.target.value);
    }

    const onClickCancel = () => {
      handleContactClinicClose();
      setShowModal(false);
    }
    const onClickPreview = () => {
      if (!userContext) {
        if (!isValidEmail(email)) {
          setIsInvalidEmail(true);
          return;
        }        
      }
      setShowPreview(true);
      setShowCollectInfo(false);
    }

    const onEmailChange = (event) => {
      setIsInvalidEmail(false);
      setEmail(event.target.value);
    }

    const onClickSend = () => {
      setShowSendEmailStatus(true);
      setShowPreview(false);
      const requestBody = {
        trialNctId: trial.trialIdentification.nctId,
        trialLocationKey: trial.trialLocations[selectedLocationIndex].locationIdempotenceKey,
        healthInfoIncluded: healthInfoIncluded,
        emailCustomContent: emailCustomContent,
        emailType: 'PATIENT_REACH_OUT_TO_TRIAL_OWNER',
        email: email
      }
      
      let sendEmailEndpointKey = "sendEmail";

      const requestOptions = {
        method: 'POST',
        body: JSON.stringify(requestBody)
      };

      if (userContext) {
        requestBody['userProfileId'] = userProfile.userIdentification.userProfileId;
        requestOptions['headers'] = {
          Authorization: `Bearer ${userContext.jwtToken}`
        };
      } else {
        sendEmailEndpointKey = "sendunAuthenticatedEmail";
      }
 
      fetch(getEndpoint(sendEmailEndpointKey), requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.error) {
        } else {
          setSendEmailComplete(true);
        }
        
      })
      .catch(error => {
        //TODO: display error
        console.error(error); 
      });  

    }
    const onClickBack = () => {
      setShowPreview(false);
      setShowCollectInfo(true);
    }

    const buildContactInfo = () => {
      if (userContext) {
        return userProfile.userIdentification.email;
      } else {
        return email;
      }
    }

    return (
        <Modal
            open={showModal}
            onClose={onClickClose}
          >
            <Box sx={getModalStyle(isMobileView ? '90vw' : '550', true)}>

{
//////////////////////////////////////////////////////////////////////
//   showCollectInfo
//////////////////////////////////////////////////////////////////////
}

              {showCollectInfo && <>
                Send a customized message to the study team (please select): 
                <p/>
                <Divider />
                {!userContext && <>
                <p/>
                    <span style={{verticalAlign: "middle"}}>Email:&nbsp;&nbsp;</span>
                    <TextField 
                        size="small"
                        value={email}
                        sx={{width: "300px", verticalAlign: "middle"}}
                        onChange={onEmailChange}
                        error={invalidEmail}
                        helperText={invalidEmail ? "Invalid email" : ""}
                    />
                </>
                }
                <p/>
                <FormControlLabel control={<Switch checked={healthInfoIncluded} onChange={onIncludeHealthProfileChange} />}
                                label="Include health profile in email, including age, biological sex, biopsies, tumor stages, PSA level, and treatment history. Your health profile will help the clinic assess your eligibility for the trial."
                            />
                <p/>
                <FormControlLabel control={<Switch checked={addAdditionalInfo} onChange={onAddAdditionalInfoChange} />}
                                label="Include my own words. (This will allow the study team better understand/accomodate your needs, e.g. what you're looking for)"
                            />
                { addAdditionalInfo && 
                        <TextField
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        value={emailCustomContent}
                        placeholder="Enter the additional message here."
                        sx={{width: {
                          xs: '70vw', 
                          sm: '70vw',
                          md: 500, 
                          lg: 500, 
                          xl: 500, 
                        }, marginLeft: '40px'}}
                        onChange={onAdditionalInfoTextChange}
                      />
                }
                { trial.trialLocations.length > 1 && <>
                <p/>
                <Box sx={{marginLeft: '40px' }}>
                Select the institution you would like to contact 
                <br/>
                <FormControl sx={{ width: '500px'}} size="small">
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    sx={{fontSize: '12px'}}
                    value={selectedLocationIndex}
                    onChange={(event)=>setSelectedLocationIndex(event.target.value)}
                  >
                    {trial.trialLocations.map((location, index) => 
                      <MenuItem value={index}>{buildLocationDisplayInfo(location)}</MenuItem>
                    )}
                  </Select>
                </FormControl>
                </Box>
                </>
                }
                <p/>
                <Divider />
                <p/>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                    </Grid>
                    <Grid item xs={6}>
                        <Button size="large" variant="contained" sx={{textTransform: 'none'}} onClick={onClickPreview}>Preview</Button>&nbsp;&nbsp;
                        <Button size="large" variant="outlined" sx={{textTransform: 'none'}} onClick={onClickCancel}>Cancel</Button>&nbsp;&nbsp;
                    </Grid>
                </Grid>
                 
              </>
              }

{
//////////////////////////////////////////////////////////////////////
//   showPreview
//////////////////////////////////////////////////////////////////////
}
              {showPreview && 
                <>The following email will be sent to clinic trial {trial.trialIdentification.nctId} at {trial.trialLocations[selectedLocationIndex].instituteName}
                <p/>
                <Box
                  component="span"
                 sx={{ display: 'inline-block', p: 2, border: '1px dashed grey' }}
                >
                  Hi researchers/investigators,
                  <p/>
                  I’m a patient with prostate cancer. I’m writing to see if I could participate in your study 
                  titled <b>{trial.trialIdentification.briefTitle} (Study/Trial id: {trial.trialIdentification.nctId})</b>.

                  {userContext && healthInfoIncluded && <>
                    <p/>
                    Here is more information about me:
                    <p/>
                    <Box sx={{marginLeft: 5}}>
                    {buildDoBString(userProfile)}
                    {buildSexString(userProfile)}
                    {buildBiopsyString(userProfile)}
                    {buildTumorStage(userProfile)}
                    {buildPSALevel(userProfile)}
                    {buildTreatmentHistory(userProfile)}
                    {buildOtherConditions(userProfile)}
                  </Box></>}

                  {addAdditionalInfo && 
                    <><p/>Additional information:
                    <Box sx={{marginLeft: 5}}><pre>{emailCustomContent}</pre></Box></>
                  }

                  <p/>
                  I can be reached at {buildContactInfo()}. Thank you and I look forward to hearing from you.
                </Box>
                <p/>
                <Divider />
                <p/>
                <Typography variant="body2" gutterBottom>
                  <b>By sending the above message, I agree that any data included in the message will be sent to a representative for the clinical trial of my choice in accordance with InfoReach's privacy policy.</b>
                </Typography>
                <Grid container spacing={1}>
                    <Grid item xs={isMobileView? 3: 6}>
                    </Grid>
                    <Grid item xs={isMobileView? 9: 6}>
                        <Button size="large" variant="contained" sx={{textTransform: 'none'}} onClick={onClickSend}>Send</Button>&nbsp;&nbsp;
                        <Button size="large" variant="outlined" sx={{textTransform: 'none'}} onClick={onClickBack}>Back</Button>&nbsp;&nbsp;
                        <Button size="large" variant="outlined" sx={{textTransform: 'none'}} onClick={onClickCancel}>Cancel</Button>&nbsp;&nbsp;
                    </Grid>
                </Grid>
                </>
              }
{
//////////////////////////////////////////////////////////////////////
//   showSendEmailComplete
//////////////////////////////////////////////////////////////////////
}
              { showSendEmailStatus && 
                <Box sx={{height: '80px'}}>
                {!sendEmailComplete && 
                <CircularProgress variant="solid" color="neutral" thickness={5} sx={{ '--CircularProgress-size': '50px', marginLeft: '300px' }}/> }
                {sendEmailComplete && 
                <>
                Email has been sent to {buildLocationDisplayInfo(trial.trialLocations[selectedLocationIndex])}, and you have been cc'd at {buildContactInfo()}.
                <p/>
                <Grid container spacing={1}>
                    <Grid item xs={9}>
                    </Grid>
                    <Grid item xs={3}>
                      <Button size="small" variant="outlined" sx={{textTransform: 'none'}} onClick={onClickCancel}>Close</Button>
                    </Grid>
                </Grid>

                </>}
                </Box>
              }
              
                
            </Box>
          </Modal>
    );

}
