import * as React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { getModalStyle } from '../utils/ModalUtil';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

export default function TreatmentStudyModal({items, getItemKey, getItemContent, handleGenericDetailInfoModalClose}) {
    const [showModal, setShowModal] = React.useState(true);
    const [showFDAs, setShowFDAs] = React.useState(new Array(items.length).fill(false))
    const updatedItems = items.map(item =>  Object.assign(item, {fda: item.treatmentStudied + "xyz"}));
    const modalWidth = items.filter(item => item.about !== '').length > 0 ? '750px': '550px';    

    const onClickClose = () => {
        setShowModal(false);
        handleGenericDetailInfoModalClose();
    }
    const handleChange = (index) => {
        const newShowFDAs = []
        for(let i = 0; i<showFDAs.length; i++) {
            newShowFDAs.push(showFDAs[i]);
        }
        newShowFDAs[index] = !showFDAs[index];
        setShowFDAs(newShowFDAs);
      };

    

    return (
        <Modal
            open={showModal}
            onClose={onClickClose}
          >
            <Box sx={getModalStyle(modalWidth, true)}>
            <TableContainer>
         <Table sx={{ 'td, th': {fontSize: "1rem"} }}>
           <TableBody sx={{ 'td, th': { border: 0 } }}>
            
               { updatedItems.map((item, index) =>  <>
                    <TableRow key={`treatment-detail-${index}`}>
                        <TableCell align="left" component="th" scope="row" sx={{width: "50px"}}><LocalHospitalIcon/></TableCell>
                        <TableCell align="left" sx={{width: '300px'}}>{getItemContent(item)}</TableCell>
                        { item.about && <TableCell align="left" sx={{width: '300px'}}>
                            <FormControlLabel control={<Switch checked={showFDAs[index]} onChange={() => handleChange(index)} />}
                                label="Show regulatory status"
                            />
                        </TableCell>}
                    </TableRow>
                     {showFDAs[index] && <TableRow key={`treatment-about-${index}`}>
                        <TableCell colspan="3" align="left" component="th" scope="row">
                            <pre
                              style={{
                                overflowX: 'auto',
                                whiteSpace: 'pre-wrap',
                                wordWrap: 'break-word'
                              }}
                            >{item.about}</pre></TableCell>
                     </TableRow>
                    }
                     </>           
                )}
           </TableBody>
         </Table>
       </TableContainer>  
     
            </Box>
          </Modal>
    );

}