import * as React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { getModalStyle } from '../../utils/ModalUtil';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Divider from '@mui/material/Divider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { T_VALUES, N_VALUES } from '../../utils/Constants';
import { MEDICAL_GROUP } from './TreatmentList';
import { isValidDate } from '../../utils/Util';

const computeTnmStagingReportChoice = (selectedTreatmentInstance) => {
    if (selectedTreatmentInstance) {
        if (selectedTreatmentInstance.tnm != null) {
            return "Yes";
        }
    }
    return "No";
}

const computeLymphNodeChoice = (selectedTreatment, selectedTreatmentInstance) => {
    if (!selectedTreatmentInstance && selectedTreatment.isRadical) {
        return "Yes";
    }
    if (selectedTreatmentInstance) {
        if (selectedTreatmentInstance.hasLymphNodeDissection) {
            return "Yes";
        } else {
            return "No";
        }
    }
    return "No";
}

const getInitialTNMValue = (selectedTreatmentInstance, prefix, defaultValue) => {
    if (selectedTreatmentInstance != null && selectedTreatmentInstance.tnm != null) {
        return selectedTreatmentInstance.tnm[prefix + "_StagePrimary"] + selectedTreatmentInstance.tnm[prefix + "_StageSecondary"];
    } else {
        return defaultValue;
    }
}

const getInitialDate = (selectedTreatmentInstance) => {
    if (selectedTreatmentInstance != null){
        if (selectedTreatmentInstance.startDate) {
            return dayjs(selectedTreatmentInstance.startDate);
        } else {
            return dayjs(selectedTreatmentInstance.date);
        }
    } else {
        return null;
    }
}

const getInitialEndDate = (selectedTreatmentInstance) => {
    if (selectedTreatmentInstance != null){
        if (selectedTreatmentInstance.endDate) {
            return dayjs(selectedTreatmentInstance.endDate);
        } 
    } 
    return null;
}

const isSurgery = (selectedTreatment) => selectedTreatment.group === "Surgery / Procedure";
const isDrug = (selectedTreatment) => selectedTreatment.group === MEDICAL_GROUP;

export default function TreatmentModal({editMode, selectedTreatment, selectedTreatmentInstance, handleTreatmentEditSave, handleTreatmentEditCancel}) {

    const headerPrefix = selectedTreatmentInstance ? "Edit " : "Add a ";
    const headerSuffix = selectedTreatment.group.toLowerCase() + ": " + selectedTreatment.name;
    const dateText = isSurgery(selectedTreatment) ? "Date" : "Start date";
    

    const [date, setDate] = React.useState(getInitialDate(selectedTreatmentInstance));
    const [invalidDate, setInvalidDate] = React.useState(false);
    const [tnmStagingReportChoice, setTnmStagingReportChoice] = React.useState(computeTnmStagingReportChoice(selectedTreatmentInstance));
    const [lymphNodeChoice, setLymphNodeChoice] = React.useState(computeLymphNodeChoice(selectedTreatment, selectedTreatmentInstance));
    const [tValue, setTValue] = React.useState(getInitialTNMValue(selectedTreatmentInstance, "t", "T0"));
    const [nValue, setNValue] = React.useState(getInitialTNMValue(selectedTreatmentInstance, "n", "N0"));
    const initialEndDate = getInitialEndDate(selectedTreatmentInstance);
    const [endDate, setEndDate] = React.useState(initialEndDate);
    const [invalidEndDate, setInvalidEndDate] = React.useState(false);
    const [onGoingChoice, setOnGoingChoice] = React.useState(endDate ? "No": "Yes");
    const [isForRecurrence, setIsForRecurrence] = React.useState(selectedTreatmentInstance && selectedTreatmentInstance.isForRecurrence ? "Yes" : "No");
    const [isForResistance, setIsForResistance] = React.useState(selectedTreatmentInstance && selectedTreatmentInstance.isForResistance ? "Yes" : "No");
    const [reasonWithdrawal, setReasonWithdrawal] = React.useState(selectedTreatmentInstance ? selectedTreatmentInstance.reasonWithdrawal : null);

    const onClose = () => {
        handleTreatmentEditCancel();
    }

    const onReasonWithdrawalChange = (event) => {
        setReasonWithdrawal(event.target.value)
    }

    const onClickSave = () => {
        if (isValidDate(date)) {
            setInvalidDate(false);
        } else {
            setInvalidDate(true);
            return;
        }

        if (onGoingChoice === 'Yes' || (isValidDate(endDate) && date.isBefore(endDate))) {
            setInvalidEndDate(false);
        } else {
            setInvalidEndDate(true);
            return;
        }

        const info = {
            name: selectedTreatment.name,
            id: selectedTreatmentInstance ? selectedTreatmentInstance.id : new Date().getTime(),
            isForRecurrence: isForRecurrence === "Yes",
        };
        if (isDrug(selectedTreatment)) {
            info["isForResistance"] = isForResistance === "Yes"
        }

        if (isSurgery(selectedTreatment)) {
            info["date"] = date.format('YYYY-MM-DD').toString();
            info["hasLymphNodeDissection"] = lymphNodeChoice === "Yes";
            if (tnmStagingReportChoice === "Yes") {
                info["tnm"] = {
                    date: info["date"],
                    t_StagePrimary: tValue.substring(0, 2),
                    t_StageSecondary: tValue.substring(2),
                    n_StagePrimary: nValue.substring(0, 2),
                    n_StageSecondary: nValue.substring(2),
                    m_StagePrimary: "M",
                    m_StageSecondary: "0",
                    t_Method: 'p',
                    n_Method: 'p',
                    m_Method: 'p',
                }
            }
        } else {
            info["startDate"] = date.format('YYYY-MM-DD').toString();
            // endDate is allowed to be null. 
            if (endDate != null) {
                info["endDate"] = endDate.format('YYYY-MM-DD').toString();
            }
            if (isDrug(selectedTreatment)) {
                info["reasonWithdrawal"] = reasonWithdrawal;
            }            
        }
        handleTreatmentEditSave(info, selectedTreatmentInstance == null);
    };

    const onClickCancel = () => handleTreatmentEditCancel();

    const onLymphNodeChoiceChange = (event, value) => {
        setLymphNodeChoice(value);
    }

    const onIsForRecurrenceChange = (event, value) => {
        setIsForRecurrence(value);
    }

    const onIsForResistanceChange = (event, value) => {
        setIsForResistance(value);
    }

    const onTnmStagingReportChoice = (event, value) => {
        setTnmStagingReportChoice(value);
    }

    const onTValueChange = (event) => {
        setTValue(event.target.value);
    }

    const onNValueChange = (event) => {
        setNValue(event.target.value);
    }

    const onGoingChoiceChange = (event, value) => {
        setOnGoingChoice(value);
        setEndDate(null);
        setInvalidEndDate(false);
    }

    const getEndDateErrorMessage = () => {
        if (invalidEndDate) {
            if (!isValidDate(endDate)) {
                return "Invalid date";
            } else {
                return "End date should be after start date";
            }
        } else {
            return "";
        }
    }

    return (
        <Modal
            open={editMode}
            onClose={onClose}
          >
            <Box sx={getModalStyle(700)}>
                {headerPrefix + headerSuffix}
                <p/>
                <Divider />
                <TableContainer>
                    <Table sx={{'td, th': {fontSize: "1rem"} }}>
                        <TableBody sx={{ 'td, th': { border: 0 } }}>
                            <TableRow key="date">
                                <TableCell component="th" scope="row" align="right" sx={{width: "330px"}}>{dateText}:</TableCell>
                                <TableCell align="left" sx={{ '.MuiInputBase-root': {height: '40px'}}}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            inputFormat="MM/DD/YYYY"
                                            value={date}
                                            disableFuture
                                            onChange={
                                                (newValue) => {
                                                    setDate(newValue)
                                                    setInvalidDate(false)
                                                }
                                            }
                                            renderInput={(params) => 
                                                <TextField {...params} 
                                                    error={invalidDate}
                                                    helperText={invalidDate ? "Invalid date" : ""}
                                                /> 
                                            }
                                            sx={{'input': {height: '1rem'}}}
                                            />
                                    </LocalizationProvider>
                                </TableCell>
                            </TableRow>
                            <TableRow key="isForRecurrence">
                                <TableCell component="th" scope="row" align="right" >Was it initiated to treat recurrent cancer: </TableCell>
                                <TableCell align="left">
                                        <ToggleButtonGroup
                                            color="primary"
                                            value={isForRecurrence}
                                            exclusive
                                            onChange={onIsForRecurrenceChange}
                                            size="small"
                                        >
                                                <ToggleButton key="isForRecurrenceChoiceYes" value="Yes" sx={{textTransform: 'none'}}>Yes</ToggleButton>
                                                <ToggleButton key="isForRecurrenceChoiceNo" value="No" sx={{textTransform: 'none'}}>No</ToggleButton>
                                        </ToggleButtonGroup>
                                </TableCell>
                            </TableRow>
                            {
                            isDrug(selectedTreatment) &&
                            <TableRow key="isForResistance">
                                <TableCell component="th" scope="row" align="right" >Was it initiated due to resistance to the preceding treatment: </TableCell>
                                <TableCell align="left">
                                        <ToggleButtonGroup
                                            color="primary"
                                            value={isForResistance}
                                            exclusive
                                            onChange={onIsForResistanceChange}
                                            size="small"
                                        >
                                                <ToggleButton key="isForResistanceChoiceYes" value="Yes" sx={{textTransform: 'none'}}>Yes</ToggleButton>
                                                <ToggleButton key="isForResistanceChoiceNo" value="No" sx={{textTransform: 'none'}}>No</ToggleButton>
                                        </ToggleButtonGroup>
                                </TableCell>
                            </TableRow>
                            }
                            {selectedTreatment.group === "Surgery / Procedure" && selectedTreatment.isRadical && 
                            <TableRow key="lymphNode">
                                <TableCell component="th" scope="row" align="right" >Was lymph node dissection performed: </TableCell>
                                <TableCell align="left">
                                        <ToggleButtonGroup
                                            color="primary"
                                            value={lymphNodeChoice}
                                            exclusive
                                            onChange={onLymphNodeChoiceChange}
                                            size="small"
                                        >
                                                <ToggleButton key="tnmStagingReportChoiceYes" value="Yes" sx={{textTransform: 'none'}}>Yes</ToggleButton>
                                                <ToggleButton key="tnmStagingReportChoiceNo" value="No" sx={{textTransform: 'none'}}>No</ToggleButton>
                                        </ToggleButtonGroup>
                                </TableCell>
                            </TableRow>
                            } 
                            {
                            isSurgery(selectedTreatment) && selectedTreatment.isRadical && 
                            <TableRow key="tnmNode">
                                <TableCell component="th" scope="row" align="right" >Was pathological staging performed: </TableCell>
                                <TableCell align="left">
                                        <ToggleButtonGroup
                                            color="primary"
                                            value={tnmStagingReportChoice}
                                            exclusive
                                            onChange={onTnmStagingReportChoice}
                                            size="small"
                                            >                                   
                                        <ToggleButton key="tnmStagingReportChoiceYes" value="Yes" sx={{textTransform: 'none'}}>Yes</ToggleButton>
                                        <ToggleButton key="ltnmStagingReportChoiceNo" value="No" sx={{textTransform: 'none'}}>No</ToggleButton>
                                    </ToggleButtonGroup>
                                </TableCell>
                            </TableRow>
                            }
                            {tnmStagingReportChoice === 'Yes' && <>
                            <TableRow key="tValue">
                                <TableCell component="th" scope="row" align="right" >Primary tumor (T):</TableCell>
                                <TableCell align="left">
                                    <FormControl fullWidth 
                                        sx={{ '.MuiInputBase-root': {height: '40px', width: '80px'}, flex: 1}}>
                                        <Select
                                        value={tValue}
                                        onChange={onTValueChange}                                        
                                        >
                                        {T_VALUES.map(value => <MenuItem key={value} value={value}>{value}</MenuItem> )}
                                        </Select>
                                    </FormControl>
                                </TableCell>
                            </TableRow>
                            <TableRow key="nValue">
                                <TableCell component="th" scope="row" align="right">Regional lymph nodes (N) :</TableCell>
                                <TableCell align="left">
                                    <FormControl fullWidth 
                                        sx={{ '.MuiInputBase-root': {height: '40px', width: '80px'}, flex: 1}}>
                                        <Select
                                        value={nValue}
                                        onChange={onNValueChange}                                        
                                        >
                                        {N_VALUES.map(value => <MenuItem key={value} value={value}>{value}</MenuItem> )}
                                        </Select>
                                    </FormControl>
                                </TableCell>
                            </TableRow>  
                           </>}
                           {
                            !isSurgery(selectedTreatment) &&
                            <TableRow key="ongoing">
                                <TableCell component="th" scope="row" align="right">Is it ongoing treatment:</TableCell>
                                <TableCell align="left">
                                        <ToggleButtonGroup
                                            color="primary"
                                            value={onGoingChoice}
                                            exclusive
                                            onChange={onGoingChoiceChange}
                                            size="small"
                                            >                                   
                                        <ToggleButton key="onGoingChoiceYes" value="Yes" sx={{textTransform: 'none'}}>Yes</ToggleButton>
                                        <ToggleButton key="onGoingChoiceNo" value="No" sx={{textTransform: 'none'}}>No</ToggleButton>
                                    </ToggleButtonGroup>
                                </TableCell>
                            </TableRow>
                           }
                           {
                            !isSurgery(selectedTreatment) && onGoingChoice === "No" &&
                            <TableRow key="endDate">
                                <TableCell component="th" scope="row" align="right">End date:</TableCell>
                                <TableCell align="left" sx={{ '.MuiInputBase-root': {height: '40px'}}}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            inputFormat="MM/DD/YYYY"
                                            value={endDate}
                                            disableFuture
                                            onChange={(newValue) => {
                                                setEndDate(newValue);
                                                setInvalidEndDate(false);
                                            }}
                                            renderInput={(params) => 
                                                <TextField {...params} 
                                                    error={invalidEndDate}
                                                    helperText={getEndDateErrorMessage()}
                                                /> 
                                            }
                                            sx={{'input': {height: '1rem'}}}
                                            />                                            
                                    </LocalizationProvider>
                                </TableCell>
                            </TableRow>
                           }  
                           {
                            isDrug(selectedTreatment) && onGoingChoice === "No" &&
                            <TableRow key="withdrawReason">
                                <TableCell component="th" scope="row" align="right">Reason of withdrawal: </TableCell>
                                <TableCell align="left">
                                    <div style={{display: 'flex'}}>
                                    <FormControl fullWidth sx={{ '.MuiInputBase-root': {height: '40px', width: '230px'}, flex: 1, marginRight: '70px'}}>
                                        <Select
                                        value={reasonWithdrawal}
                                        onChange={onReasonWithdrawalChange}
                                        
                                        >
                                        <MenuItem value={"therapy completed"}>therapy completed</MenuItem>
                                        <MenuItem value={"disease progression"}>disease progression</MenuItem>
                                        <MenuItem value={"intolerance"}>intolerance</MenuItem>
                                        <MenuItem value={"cost"}>cost</MenuItem>
                                        <MenuItem value={"other"}>other</MenuItem>
                                        </Select>
                                    </FormControl>
                                    </div>
                                </TableCell>
                            </TableRow>

                            
                           }                 
                        </TableBody>
                    </Table>
                </TableContainer>
                <p/>

                <Divider />
                <p/>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                    </Grid>
                    <Grid item xs={6}>
                        <Button size="large" variant="contained" onClick={onClickSave} sx={{textTransform: "none"}}>Save</Button>&nbsp;&nbsp;
                        <Button size="large" variant="outlined" onClick={onClickCancel} sx={{textTransform: "none"}}>Cancel</Button>&nbsp;&nbsp;
                    </Grid>
                </Grid>
     
            </Box>
          </Modal>
    );

}