import * as React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { getModalStyle } from '../utils/ModalUtil';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import useMediaQuery from '@mui/material/useMediaQuery';


export default function GenericDetailInfoModal({items, getItemKey, getItemContent, handleGenericDetailInfoModalClose}) {
    const [showModal, setShowModal] = React.useState(true);

    const onClickClose = () => {
        setShowModal(false);
        handleGenericDetailInfoModalClose();
    }
    const isMobileView = useMediaQuery('(max-width:600px)');

    return (
        <Modal
            open={showModal}
            onClose={onClickClose}
          >
            <Box sx={getModalStyle(isMobileView ? '90vw' : '550', true)}>
                <List>
                    {items.map(item => 
                    <ListItem key={getItemKey(item)} >
                        {items.length > 1 && <ListItemIcon>
                            <LocalHospitalIcon />
                        </ListItemIcon>}
                        <ListItemText>{getItemContent(item)}</ListItemText>
                    </ListItem>
                    )}

                </List>
     
            </Box>
          </Modal>
    );

}