import * as React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { getModalStyle } from '../utils/ModalUtil';
import Typography from '@mui/material/Typography';
import { Divider } from '@mui/material';

export default function TooltipModal({ showToolTip, title, body, handleToolTipClose }) {

    return (
        <Modal
        open={showToolTip}
        onClose={handleToolTipClose}>
            <Box sx={getModalStyle(800, true)}>
                <Typography variant='h6' sx={{ textAlign: "center"}}>
                   {title}
                </Typography>
                <p/>
                <Divider />
                <p/>
                <Typography variant='body1'>
                  {body}
                </Typography>
            </Box>
        </Modal>

    )
}