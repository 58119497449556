import dayjs from 'dayjs';
import { CANCER_TYPES } from "./Constants";

export const splitItems = (items) => {
    let firstItem = null;
    let remainingItems = []
    if (items.length > 0) {
        firstItem = items[0];
        if (items.length > 1) {
            remainingItems = items.slice(-(items.length-1));
        }
    }
    return [firstItem, remainingItems];

}

export const checkProfileCompleteness = (profile) => {
    return {
        statusCheckComplete: true,
        profileComplete: true
    }
}

export const isValidDate = (date) => {
    if (date == null) {
        return false;
    }
    
    return date.isValid() && date.isBefore(dayjs());
}


export const isValidZipcode = (zipcode) => {
    if (isNaN(zipcode)) {
        return false;
    } else {
        var zipcodeNum = Number(zipcode)
        if (zipcodeNum > 99950 || zipcodeNum < 501) {
            return false;
        }
    }

    return true;
}

export const isValidEmail = (email) => {
    if (!email) {
        return false;
    }

    const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    return email.match(regex);

}

export const parseSelectedCondition = (history) => {
    if (history.location.search && history.location.search.length > 0) {
        const searchTerm = decodeURIComponent(history.location.search);
        const parts = searchTerm.split("=");
        if (parts.length === 2) {
            if (CANCER_TYPES.includes(parts[1])) {
                return parts[1];
            }       
        }
    }

    return CANCER_TYPES[0];
    
}