import * as React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { getModalStyle } from '../utils/ModalUtil';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { getFirstCell } from '../utils/UIUtils';
import Typography from '@mui/material/Typography';


const parseSideEffects = (studies) => {
    const sideEffects = [];
    studies.forEach(study => {
        if (study.adverseEvents.length < 1) {
            return;
        }
        const treatments = Object.keys(study.adverseEvents[0].frequency);
        const grades = Object.keys(study.adverseEvents[0].frequency[treatments[0]]);
        sideEffects.push({
            title: study.briefTitle,
            treatments: treatments,
            grades: grades,
            columnCount: grades.length * treatments.length,
            adverseEvents: study.adverseEvents,
        })
    })

    return sideEffects;
}

const getMaxEventLength = (studies) => {
    var maxLength = 0;
    studies.forEach(study => {
        study.adverseEvents.forEach(entry => {
            maxLength = Math.max(maxLength, entry.event.length)
        })
    })
    return maxLength;
}

export default function SideEffectModal({studies, handleSideEffectClose}) {
    const [showModal, setShowModal] = React.useState(true);
    const sideEffects = parseSideEffects(studies);
    const maxEventLength = getMaxEventLength(studies);

    var eventColumnWidth = 200;
    if (maxEventLength >= 40) {
        eventColumnWidth = 300;
    } else if (maxEventLength >= 20) {
        eventColumnWidth = 250;
    }

    const onClickClose = () => {
        setShowModal(false);
        handleSideEffectClose();
    }

    var treatmentColumnWidth = 600;
    const maxColumnCount = Math.max(sideEffects.map(effect => effect.columnCount));

    if (maxColumnCount >= 6) {
        treatmentColumnWidth = 700;
    }

    return (
        <Modal
            open={showModal}
            onClose={onClickClose}
          >
            <Box sx={getModalStyle(treatmentColumnWidth + eventColumnWidth, true)}>
                
                {
                    (sideEffects.length < 1) && <b>The study report of this trial did not include data on adverse events.</b>
                }
                {
                    sideEffects.map(sideEffect => <>
                        <b>Frequencies (%) of most common adverse events in {sideEffect.title}</b>
                        <Divider sx={{marginTop: 1, marginBottom: 1}}/>
                        <TableContainer sx={{ }}>
                            <Table key={Math.random()}  sx={{'.MuiTableCell-root':{verticalAlign: 'top'} }} aria-label="simple table">
                                <TableBody>
                                    <TableRow key={Math.random()}  sx={{height: 5}}>
                                            {getFirstCell("", null, eventColumnWidth)}
                                            {sideEffect.treatments.map(treatment => <>
                                                <TableCell sx={{ padding: 0}} colSpan={sideEffect.grades.length} align="center">
                                                    <Typography variant="body1" gutterBottom>{treatment}</Typography>                        
                                                </TableCell>
                                            </>)}
                                        </TableRow>
                                    <TableRow key={Math.random()}  sx={{height: 5}}>
                                        <TableCell 
                                            align="right" 
                                            sx={{paddingLeft: 0, paddingRight: '10px', paddingTop: 0, paddingBottom: 0, width: eventColumnWidth}}>
                                                <Typography variant="body1" gutterBottom>Event</Typography>
                                        </TableCell>
                                            {sideEffect.treatments.map(treatment => <>
                                                {sideEffect.grades.map(grade => <>
                                                    <TableCell sx={{padding: 0}} align="center">
                                                        <Typography variant="body1" gutterBottom>{grade}</Typography>                        
                                                    </TableCell>
                                                </>)}
                                            </>)}
                                    </TableRow>
                                    {
                                        sideEffect.adverseEvents.map(entry => <>
                                            <TableRow key={Math.random()}  sx={{height: 5}}>
                                                {getFirstCell(entry.event, null, eventColumnWidth)}
                                                {sideEffect.treatments.map(treatment => <>
                                                    { sideEffect.grades.map(grade => <>
                                                        <TableCell sx={{border: 0, padding: 0}} align="center">
                                                            <Typography variant="body1" gutterBottom>{entry.frequency[treatment][grade]}</Typography>                        
                                                        </TableCell>
                                                    </>)}
                                                </>)}
                                        </TableRow>
                                        </>)
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </>)
                }
                
                <Divider sx={{marginBottom: 1}}/>
                <Typography variant="body2" gutterBottom>
                Adverse events are graded on a 5-point scale based on severity: <br/>
                Grade 1: Mild and not bothersome<br/>
                Grade 2: Bothersome but not dangerous<br/>
                Grade 3: Severe<br/>
                Grade 4: Potentially life threatening<br/>
                Grade 5: Death<br/>
                </Typography>                        
     
            </Box>
          </Modal>
    );

}