import * as React from 'react';
import { Link } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { getModalStyle } from '../utils/ModalUtil';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { isValidDate } from '../utils/Util';

const getCancerCondition = (profile) => {
    if (profile.userConditions) {
        const result = profile.userConditions.filter(condition => condition.type === 'cancer' && condition.name === 'prostate cancer');
        if (result.length > 0) {
            return result[0];
        }
    }

    return null;
}

export default function StatusModalPage1({profile, handleStatusCheck1Update}) {
    const [open, setOpen] = React.useState(true);
    let saveButtonText = "Save";
    let showNoChangeButton = false;
    const cancerCondition = getCancerCondition(profile);
    const otherConditions = profile.userConditions ? profile.userConditions.filter(condition => condition.type === 'other'):[];
    if (cancerCondition) {
        saveButtonText = "Update";
        showNoChangeButton = true;
    }


    const [treatmentStatus, setTreatmentStatus] = React.useState(cancerCondition? cancerCondition.status : "pretreatment");
    const [metastasisStatus, setMetastasisStatus] = React.useState(cancerCondition && cancerCondition.isMetastatic ? 'metastatic' : 'not metastatic');
    const [showMetastasisDetails, setShowMetastasisDetails] = React.useState(cancerCondition && cancerCondition.metastasisDiagnosisDate? true: false);
    const [metastasisCheckDate, setMetastasisCheckDate] = React.useState(cancerCondition && cancerCondition.metastasisDiagnosisDate? dayjs(cancerCondition.metastasisDiagnosisDate): null);
    const [isInvalidDate, setIsInvalidDate] = React.useState(false);

    const onClose = () => {};
    const onTreatmentStatusChange = (event, value) => setTreatmentStatus(value);
    const onMetastasisStatusChange = (event, value) => {
        setMetastasisStatus(value);
        if (value === 'metastatic') {
            setShowMetastasisDetails(true);
        } else {
            setShowMetastasisDetails(false);
        }
        
    }

    const onClickNoChange = () => {
        setOpen(false);
        handleStatusCheck1Update({});
    }

    const isValidForm = () => {
        if (showMetastasisDetails && !isValidDate(metastasisCheckDate)) {
            setIsInvalidDate(true);
            return false;
        } else {
            return true;
        }
    }

    const onMetastasisCheckDateChange = (value) => {

        setIsInvalidDate(false);
        setMetastasisCheckDate(value);
    }

    const onClickUpdate = () => {
        if (isValidForm()) {
            const cancerConditions = [
                {
                    name: 'prostate cancer',
                    type: 'cancer',
                    status: treatmentStatus,
                    isMetastatic: metastasisStatus === 'metastatic',
                    metastasisDiagnosisDate: metastasisStatus === 'metastatic' ? metastasisCheckDate.format('YYYY-MM-DD').toString() : null,
                }
            ];
    
            cancerConditions.push.apply(cancerConditions, otherConditions);
            setOpen(false);
            handleStatusCheck1Update({
                userConditions: cancerConditions,
            });
        }
        
    };
   

    return (
        <Modal
            open={open}
            onClose={onClose}
          >
            <Box sx={getModalStyle(700)}>
            Welcome! Please check your <b>Prostate Cancer</b> status on file and update as needed.
                <p/>
                <Divider />
                <TableContainer>
                    <Table sx={{ minWidth: 500, 'td, th': {fontSize: "1rem"} }}>
                        <TableBody sx={{ 'td, th': { border: 0 } }}>
                            <TableRow key="treatment">
                                <TableCell component="th" scope="row" align="right" >Treatment status:</TableCell>
                                <TableCell align="left">
                                    <RadioGroup
                                        name="treatment-status-radio-group"
                                        value={treatmentStatus}
                                        onChange={onTreatmentStatusChange}
                                    >
                                        <FormControlLabel value="pretreatment" control={<Radio />} label="My cancer has never been treated" />
                                        <FormControlLabel value="in remission" control={<Radio />} label="My cancer is in remission after treatment" />
                                        <FormControlLabel value="recurrent" control={<Radio />} label="My cancer has relapsed" />
                                    </RadioGroup>
                                </TableCell>
                            </TableRow>
                            <TableRow key="metastasis">
                                <TableCell component="th" scope="row" align="right" >Metastasis status:</TableCell>
                                <TableCell align="left">
                                    <RadioGroup
                                        name="metastasis-status-radio-group"
                                        value={metastasisStatus}
                                        onChange={onMetastasisStatusChange}
                                    >
                                        <FormControlLabel value="not metastatic" control={<Radio />} label="My cancer has not spread to other body parts" />
                                        <FormControlLabel value="metastatic" control={<Radio />} label="My cancer has spread to other body parts" />
                                    </RadioGroup>
                                </TableCell>
                            </TableRow>
                            {showMetastasisDetails && <>
                                <TableRow key="metastasis-detail">
                                    <TableCell component="th" scope="row" align="right" >Metastatic since:</TableCell>
                                    <TableCell align="left" sx={{ '.MuiInputBase-root': {height: '40px'}}}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                inputFormat="MM/DD/YYYY"
                                                value={metastasisCheckDate}
                                                disableFuture
                                                onChange={onMetastasisCheckDateChange}
                                                renderInput={(params) => 
                                                    <TextField {...params} 
                                                        error={isInvalidDate}
                                                        helperText={isInvalidDate ? "Invalid date" : ""} 
                                                    />}
                                                sx={{'input': {height: '1rem'}}}
                                                />
                                        </LocalizationProvider>
                                    </TableCell>
                                </TableRow>
                            </>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <p/>
                <Divider />
                <p/>
                <div>
                    <Typography variant='body2' sx={{ textAlign: "center", marginTop: "10px"}}>
                        <span style={{marginRight: "20px"}}>
                        <Link to="termofservice" target="_blank" rel="Term of Service" >Term of Service</Link>
                        </span>
                        <span>
                        <Link to="privacypolicy" target="_blank" rel="Privacy Policy">Privacy Policy</Link>
                        </span>
                    </Typography>
                </div>
                <p/>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                    </Grid>
                    <Grid item xs={6}>
                        <Button size="large" variant="contained" onClick={onClickUpdate} sx={{textTransform: "none"}}>{saveButtonText}</Button>&nbsp;&nbsp;
                        {showNoChangeButton && <Button size="large" variant="outlined" onClick={onClickNoChange} sx={{textTransform: "none"}}>Next</Button>}
                    </Grid>
                </Grid>
            </Box>
          </Modal>
    );

}