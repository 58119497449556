export const SURGERY_GROUP = "Surgery / Procedure";
export const RADIOTHERAPY_GROUP = "Radiotherapy";
export const MEDICAL_GROUP = "Medical therapy";

export const TREATMENTS = [
    {
      "name": "prostatectomy",
      group: SURGERY_GROUP,
      "isRadical": true,
    },
    {
      "name": "bilateral orchiectomy",
      group: SURGERY_GROUP,
    },
    {
      "name": "HIFU (high-intensity focused ultrasound)",
      group: SURGERY_GROUP,
    },
    {
      "name": "cryotherapy (cryosurgery)",
      "conditions": [
        "prostate cancer",
        ""
      ],
      group: SURGERY_GROUP,
    },
      {
        "name": "abiraterone acetate (Yonsa)",
        "conditions": [
          "prostate cancer"
        ],
        group: MEDICAL_GROUP
      },
      {
        "name": "abiraterone acetate (Zytiga)",
        "conditions": [
          "prostate cancer"
        ],
        group: MEDICAL_GROUP
      },
      {
        "name": "apalutamide (Erleada)",
        "conditions": [
          "prostate cancer"
        ],
        group: MEDICAL_GROUP
      },
      {
        "name": "bicalutamide (Casodex)",
        "conditions": [
          "prostate cancer"
        ],
        group: MEDICAL_GROUP
      },
      {
        "name": "bilateral orchiectomy",
        "conditions": [
          "prostate cancer"
        ],
        group: MEDICAL_GROUP
      },
      {
        "name": "cabazitaxel (Jevtana)",
        "conditions": [
          "prostate cancer"
        ],
        group: MEDICAL_GROUP
      },
      {
        "name": "darolutamide (Nubeqa)",
        "conditions": [
          "prostate cancer"
        ],
        group: MEDICAL_GROUP
      },
      {
        "name": "degarelix (Firmagon)",
        "conditions": [
          "prostate cancer"
        ],
        group: MEDICAL_GROUP
      },
      {
        "name": "docetaxel (Taxotere)",
        "conditions": [
          "prostate cancer"
        ],
        group: MEDICAL_GROUP
      },
      {
        "name": "enzalutamide (Xtandi)",
        "conditions": [
          "prostate cancer"
        ],
        group: MEDICAL_GROUP
      },
      {
        "name": "flutamide (Eulexin)",
        "conditions": [
          "prostate cancer"
        ],
        group: MEDICAL_GROUP
      },
      {
        "name": "goserelin acetate (Zoladex)",
        "conditions": [
          "prostate cancer"
        ],
        group: MEDICAL_GROUP
      },
      {
        "name": "leuprolide acetate (Eligard)",
        "conditions": [
          "prostate cancer"
        ],
        group: MEDICAL_GROUP
      },
      {
        "name": "leuprolide acetate (Lupron Depot)",
        "conditions": [
          "prostate cancer"
        ],
        group: MEDICAL_GROUP
      },
      {
        "name": "177Lu-PSMA-617 (Pluvicto, Lutetium Lu 177 vipivotide tetraxetan)",
        "conditions": [
          "prostate cancer"
        ],
        group: MEDICAL_GROUP
      },
      {
        "name": "mitoxantrone (Novantrone)",
        "conditions": [
          "prostate cancer"
        ],
        group: MEDICAL_GROUP
      },
      {
        "name": "nilutamide (Nilandron)",
        "conditions": [
          "prostate cancer"
        ],
        group: MEDICAL_GROUP
      },
      {
        "name": "olaparib (Lynparza)",
        "conditions": [
          "prostate cancer"
        ],
        group: MEDICAL_GROUP
      },
      {
        "name": "pembrolizumab (Keytruda)",
        "conditions": [
          "prostate cancer"
        ],
        group: MEDICAL_GROUP
      },
      {
        "name": "radium-223 (Xofigo)",
        "conditions": [
          "prostate cancer"
        ],
        group: MEDICAL_GROUP
      },
      {
        "name": "relugolix (Orgovyx)",
        "conditions": [
          "prostate cancer"
        ],
        group: MEDICAL_GROUP
      },
      {
        "name": "relugolix (Relumina)",
        "conditions": [
          "prostate cancer"
        ],
        group: MEDICAL_GROUP
      },
      {
        "name": "rucaparib camsylate (Rubraca)",
        "conditions": [
          "prostate cancer"
        ],
        group: MEDICAL_GROUP
      },
      {
        "name": "sipuleucel-T (Provenge)",
        "conditions": [
          "prostate cancer"
        ],
        group: MEDICAL_GROUP
      },
      {
        "name": "talazoparib (Talzenna)",
        "conditions": [
          "prostate cancer"
        ],
        group: MEDICAL_GROUP
      },
      {
        "name": "triptorelin pamoate (Trelstar)",
        "conditions": [
          "prostate cancer"
        ],
        group: MEDICAL_GROUP
      },
      {
        "name": "prostatectomy",
        "conditions": [
          "prostate cancer"
        ],
        group: MEDICAL_GROUP
      },
    {
      "name": "external-beam radiation to pelvis",
      "conditions": [
        "prostate cancer",
        "colorectal cancer",
        "cervical cancer",
        "bladder cancer"
      ],
      "organs": [
        "prostate",
        "lymph node",
        "bone",
        "rectum",
        "colon",
        "cervix",
        "ovarian",
        "bladder"
      ],
      group: RADIOTHERAPY_GROUP,
    },
    {
      "name": "external-beam radiation to spine",
      "conditions": [
        "prostate cancer",
        "breast cancer",
        "lung cancer",
        "kidney cancer"
      ],
      "organs": [
        "vertebra",
        "spinal cord"
      ],
      group: RADIOTHERAPY_GROUP,
    },
    {
      "name": "brachytherapy",
      "conditions": [
        "prostate cancer",
        "cervical cancer"
      ],
      "organs": [],
      group: RADIOTHERAPY_GROUP,
    }
  ];
