import React from 'react';
import HomeMain from './HomeMain';
import Footer from '../layouts/Footer';

const Home = ({handleAuthSuccess, handleSignOut}) => {

  return (
    <>
      <HomeMain handleAuthSuccess={handleAuthSuccess} handleSignOut={handleSignOut} />
      <Footer />
    </>
  );
}

export default Home;