import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import useMediaQuery from '@mui/material/useMediaQuery';

const Footer = () => {
  const isMobileView = useMediaQuery('(max-width:600px)');

  return (
    <Box>
      
      <Divider sx={{marginTop: isMobileView? 5: 7}}/>
      <div>
        <Typography variant='body2' sx={{ textAlign: "center", marginTop: "20px"}}>
            <span style={{marginRight: 10}}>
              <Link to="termofservice" target="_blank" rel="Term of Service" >Term of Service</Link>
            </span>
            <span style={{marginRight: 10}}>
              <Link to="privacypolicy" target="_blank" rel="Privacy Policy">Privacy Policy</Link>
            </span>
        </Typography>
      </div>
      <div>
        <Typography variant='body2' sx={{ textAlign: "center", marginTop: "20px"}}>
            <span style={{marginRight: "20px"}}>
            <a href='https://facebook.com/inforeach.org' target="_blank" rel="Term of Service" ><FacebookIcon /></a>
            </span>
            <span style={{marginRight: "20px"}}>
            <a href='https://twitter.com/InfoReach_Org' target="_blank" rel="Term of Service" ><TwitterIcon /></a>
            </span>
            <span style={{marginRight: "20px"}}>
              <a href='https://youtube.com/@InfoReach_CancerTreatments' target="_blank" rel="Term of Service" ><YouTubeIcon /></a>
            </span>
        </Typography>
      </div>
      <p>&nbsp;</p>

    </Box>    


  );
}

export default Footer;