import * as React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { getModalStyle } from '../../utils/ModalUtil';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { isValidDate } from '../../utils/Util';



export default function BiopsiesModal({editMode, selectedBiopsy, handleBiopsyEditSave, handleBiopsyEditCancel}) {
    const headerText = selectedBiopsy ? "Edit prostate biopsy result" : "Add a prostate biopsy result";

    const [date, setDate] = React.useState(selectedBiopsy ? dayjs(selectedBiopsy.date) : null);
    const [invalidDate, setInvalidDate] = React.useState(false);
    const [gleasonPrimary, setGleasonPrimary] = React.useState(selectedBiopsy ? selectedBiopsy.gleasonPrimary : 1);
    const [gleasonSecondary, setGleasonSecondary] = React.useState(selectedBiopsy ? selectedBiopsy.gleasonSecondary : 1);
    const [histoType, setHistoType] = React.useState(selectedBiopsy ? selectedBiopsy.histoType : "adenocarcinoma");

    const onClose = () => {
        handleBiopsyEditCancel();
    }

    const onClickSave = () => {  
        
        var validDate = isValidDate(date);
        setInvalidDate(!validDate)

        if (validDate) {
            handleBiopsyEditSave(
            {
                id: selectedBiopsy ? selectedBiopsy.id : new Date().getTime(),
                date: date.format('YYYY-MM-DD').toString(),
                histoType: histoType,
                gleasonPrimary: gleasonPrimary,
                gleasonSecondary: gleasonSecondary,
            },
            selectedBiopsy == null);
        }
    };


    const onClickCancel = () => {
        handleBiopsyEditCancel();
    };

    const onPrimaryScoreChange = (event) => {
        setGleasonPrimary(event.target.value);
    }

    const onSecondaryScoreChange = (event) => {
        setGleasonSecondary(event.target.value);
    }

    const onHistoTypeChange = (event) => {
        setHistoType(event.target.value)
    }

    return (
        <Modal
            open={editMode}
            onClose={onClose}
          >
            <Box sx={getModalStyle(600)}>
                {headerText}
                <p/>
                <Divider />
                <TableContainer>
                    <Table sx={{ minWidth: 500, 'td, th': {fontSize: "1rem"} }}>
                        <TableBody sx={{ 'td, th': { border: 0 } }}>
                            <TableRow key="date">
                                <TableCell component="th" scope="row" align="right" sx={{width: "150px"}}>Date:</TableCell>
                                <TableCell align="left" sx={{ '.MuiInputBase-root': {height: '40px'}}}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            inputFormat="MM/DD/YYYY"
                                            value={date}
                                            disableFuture
                                            onChange={
                                                (newValue) => {
                                                    setDate(newValue)
                                                    setInvalidDate(false);
                                                }
                                            }
                                            renderInput={(params) => 
                                                <TextField {...params} 
                                                    error={invalidDate}
                                                    helperText={invalidDate ? "Invalid Date" : ""}
                                                /> 
                                            }
                                            sx={{'input': {height: '1rem'}}}
                                            />
                                    </LocalizationProvider>
                                </TableCell>
                            </TableRow>
                            <TableRow key="level">
                                <TableCell component="th" scope="row" align="right" >Gleason score:</TableCell>
                                <TableCell align="left">
                                    <div style={{display: 'flex'}}>
                                    <FormControl fullWidth sx={{ '.MuiInputBase-root': {height: '40px', width: '60px'}, flex: 1, marginRight: '70px'}}>
                                        <Select
                                        value={gleasonPrimary}
                                        onChange={onPrimaryScoreChange}
                                        
                                        >
                                        <MenuItem value={1}>1</MenuItem>
                                        <MenuItem value={2}>2</MenuItem>
                                        <MenuItem value={3}>3</MenuItem>
                                        <MenuItem value={4}>4</MenuItem>
                                        <MenuItem value={5}>5</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <div style={{verticalAlign: 'bottom', paddingTop: '10px', marginRight: '10px'}}>+</div>
                                    <FormControl fullWidth sx={{ '.MuiInputBase-root': {height: '40px', width: '60px'}}}>
                                        <Select
                                        value={gleasonSecondary}
                                        onChange={onSecondaryScoreChange}
                                        
                                        >
                                        <MenuItem value={1}>1</MenuItem>
                                        <MenuItem value={2}>2</MenuItem>
                                        <MenuItem value={3}>3</MenuItem>
                                        <MenuItem value={4}>4</MenuItem>
                                        <MenuItem value={5}>5</MenuItem>
                                        </Select>
                                    </FormControl>
                                    </div>
                                </TableCell>
                            </TableRow>
                            <TableRow key="level">
                                <TableCell component="th" scope="row" align="right" >Histopathologic type:</TableCell>
                                <TableCell align="left">
                                    <div style={{display: 'flex'}}>
                                    <FormControl fullWidth sx={{ '.MuiInputBase-root': {height: '40px', width: '230px'}, flex: 1, marginRight: '70px'}}>
                                        <Select
                                        value={histoType}
                                        onChange={onHistoTypeChange}
                                        
                                        >
                                        <MenuItem value={"adenocarcinoma"}>adenocarcinoma</MenuItem>
                                        <MenuItem value={"squamous carcinoma"}>squamous carcinoma</MenuItem>
                                        <MenuItem value={"sarcoma"}>sarcoma</MenuItem>
                                        <MenuItem value={"transitional cell (urothelial) carcinoma"}>transitional cell (urothelial) carcinoma</MenuItem>
                                        <MenuItem value={"other"}>other</MenuItem>
                                        </Select>
                                    </FormControl>
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <p/>
                <Divider />
                <p/>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                    </Grid>
                    <Grid item xs={6}>
                        <Button size="large" variant="contained" onClick={onClickSave} sx={{textTransform: "none"}}>Save</Button>&nbsp;&nbsp;
                        <Button size="large" variant="outlined" onClick={onClickCancel} sx={{textTransform: "none"}}>Cancel</Button>&nbsp;&nbsp;
                    </Grid>
                </Grid>
     
            </Box>
          </Modal>
    );

}