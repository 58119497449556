import * as React from 'react';
import { Auth } from 'aws-amplify';
import Button from '@mui/material/Button';
import { Hidden } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

export default function SignOutPopover({handleSignOut}) {
  
    const [logout, setLogout] = React.useState(false);

    React.useEffect(() => {
        
        async function fn() {
            await Auth.signOut();
            handleSignOut();
        }
        if (logout) {
            fn();            
        }
                
    },[logout, handleSignOut]);


    const onClickSignOut = () => setLogout(true);
    return (
        <>
            <Hidden mdDown>
                <Button variant="contained" onClick={onClickSignOut} sx={{textTransform: 'none'}}>Sign Out</Button>
            </Hidden>
            <Hidden mdUp>
                <ListItemButton onClick={onClickSignOut}>
                    <ListItemText primary="Log out" />
                </ListItemButton>
            </Hidden>
        </>
        
    );
}