import React, {useEffect} from 'react';
import Trial from './Trial';
import Pagination from '@mui/material/Pagination';
import Box from '@mui/material/Box';
import { buildStudyPhase, buildStudyType } from './Trial';
import { TREATMENT_TYPE_MAP } from './TrialsConstants';

export default function Trials({allTrials, treatmentName, filters, setFilteredTrialsCallBack}) {
    
    const [pages, setPages] = React.useState(null);    
    const [pageIndex, setPageIndex] = React.useState(0);

    

    // useEffect does the callback whenever allTrials state changes
    // Need to use callback because state change is async
    useEffect(() => {
        const filterTrials = () => {

            const eligibilityMatch = (trial) => {
                const eligibility = trial.eligibilityResult.eligibility;
                if (!filters.eligible && eligibility === 'PROFILE_MATCHED')
                    return false;
                if (!filters.noteligible && eligibility === 'NOT_ELIGIBLE')
                    return false;
                if (!filters.lackofinfo && eligibility === 'LACK_OF_INFO')
                    return false;
                return true;
            }
    
            const studyTypeMatch = (trial) => {
                
                const studyType = buildStudyType(trial.studyType);
                if (!filters.observational && studyType === "Observational")
                    return false;
                if (!filters.interventional && studyType === "Interventional")
                    return false;
                if (!filters.expandedaccess && studyType === "Expanded access")
                    return false;
                return true;
            };
    
            const phaseMatch = (trial) => {
                const studyPhase = buildStudyPhase(trial.studyPhases);
                if (!filters.phase1 && (studyPhase === "Phase 1" || studyPhase === "Early Phase 1" || studyPhase === "Phase 1/2"))
                    return false;
                if (!filters.phase2 && (studyPhase === "Phase 2" || studyPhase === "Phase 1/2"))
                    return false;
                if (!filters.phase3 && (studyPhase === "Phase 3" || studyPhase === "Phase 2/3"))
                    return false;
                if (!filters.phase4 && (studyPhase === "Phase 4" || studyPhase === "Phase 3/4"))
                    return false;
                if (!filters.phasena && studyPhase === "Not Applicable")
                    return false;
                return true;
            };
    
            const treatmentTypeMatch = (trial, treatmentTypeFilters) => {
                const includedTreatmentTypes = trial.treatmentDetails.map(detail => detail.treatmentType).filter(type => treatmentTypeFilters.includes(type.toLowerCase()));
                return includedTreatmentTypes.length > 0 || trial.treatmentDetails.length === 0;
            }

            const treatmentNameMatch = (trial) => {

                let result = false;
                trial.treatmentDetails.forEach(detail => {
                    detail.typeahead.forEach(ele => {
                      if(ele === treatmentName) {
                        result = true;
                      }
                    })
                  })
                return result;
            }

            const treatmentTypeFilters = Object.entries(TREATMENT_TYPE_MAP).filter( entry => filters[entry[0]]).map(entry => entry[1].rawName);
            

            return allTrials.filter(trial => {
                const filterResult1 = studyTypeMatch(trial) && phaseMatch(trial) && treatmentTypeMatch(trial, treatmentTypeFilters) && eligibilityMatch(trial);
                const filterResult2 = treatmentName ? treatmentNameMatch(trial) : true;
                
                return filterResult1 && filterResult2;
            })
        }
        const refreshPage = () => {
            if (allTrials)  { 
                const trials = filterTrials(); 
                updatePages(trials);
                
                setFilteredTrialsCallBack(trials);
            }
        }
        refreshPage()
    }, [allTrials, filters, treatmentName, setFilteredTrialsCallBack])

    const handlePaginationChange = (event, value) => setPageIndex(value - 1);
 

    const updatePages = (trials) => {
        const pageSize = 50;

        const tempPages = [];
        const pageCount = Math.ceil(trials.length / pageSize);
        for(let i = 0; i < pageCount; i++) {
            const itemCount = Math.min(pageSize, trials.length - i * pageSize);
            const page = [];
            for(let j=0; j<itemCount; j++) {
                const trial = trials[ i * pageSize + j];
                
                page.push(<><p/><Trial trial={trial} /><p/></>);
        
            }
            tempPages.push(page);
        }
        setPages(tempPages);
        setPageIndex(0);
    }

    return (
        <Box sx={{ width: '100%'}}>
        {pages && <Pagination count={pages.length} onChange={handlePaginationChange} />}
        <Box sx={{overflow: 'scroll', height: '800px'}} >
        {pages && pages[pageIndex]}
        </Box>
        </Box>
    );
}
