import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import TreatmentModal from './TreatmentModal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import { TREATMENTS, SURGERY_GROUP, RADIOTHERAPY_GROUP, MEDICAL_GROUP } from './TreatmentList';



export default function Treatments({profile, handleProfileUpdate}) {
    const [selectedTreatment, setSelectedTreatment] = React.useState(null);
    const [toAddTreatment, setToAddTreatment] = React.useState(false);
    const [editMode, setEditMode] = React.useState(false);
    const [selectedTreatmentInstance, setSelectedTreatmentInstance] = React.useState(null);
    const [surgeries, setSurgeries] = React.useState(profile.userSurgeries ? profile.userSurgeries : []);
    const [radiotherapies, setRadiotherapies] = React.useState(profile.userRadiotherapies ? profile.userRadiotherapies : []);
    const [drugs, setDrugs] = React.useState(profile.userDrugs ? profile.userDrugs : []);

    const onTreatmentChange = (event, value) => {
        setSelectedTreatment(value);
        setToAddTreatment(true);
    }

    const onClickAdd = () => {
        setToAddTreatment(false);
        setEditMode(true);
    }

    const handleTreatmentEditCancel = () => {
        setSelectedTreatment(null);
        setSelectedTreatmentInstance(null);
        setEditMode(false);
    }

    const handleTreatmentEditSave = (info, addNew) => {
        if (addNew) {            
            if (selectedTreatment.group === SURGERY_GROUP) {
                const newSurgeries = [...surgeries]
                newSurgeries.push(info);
                setSurgeries(newSurgeries);
                save(newSurgeries, radiotherapies, drugs);
            } else if (selectedTreatment.group === RADIOTHERAPY_GROUP) {
                const newRadiotherapies = [...radiotherapies];
                newRadiotherapies.push(info);
                setRadiotherapies(newRadiotherapies);
                save(surgeries, newRadiotherapies, drugs);
            } else {
                const newDrugs = [...drugs];
                newDrugs.push(info);
                setDrugs(newDrugs);
                save(surgeries, radiotherapies, newDrugs);
            }
        } else {
            if (selectedTreatment.group === SURGERY_GROUP) {
                const newSurgeries = surgeries.map(item => item.id === info.id ? info : item);
                setSurgeries(newSurgeries);
                save(newSurgeries, radiotherapies, drugs);
            } else if (selectedTreatment.group === RADIOTHERAPY_GROUP) {
                const newRadiotherapies = radiotherapies.map(item => item.id === info.id ? info : item);
                setRadiotherapies(newRadiotherapies);
                save(surgeries, newRadiotherapies, drugs);
            } else {
                const newDrugs = drugs.map(item => item.id === info.id ? info : item);
                setDrugs(newDrugs);
                save(surgeries, radiotherapies, newDrugs);
            }
        }
        setSelectedTreatment(null);
        setSelectedTreatmentInstance(null);
        setEditMode(false);
    }

    const onClickEdit = (type, treatment) => {
        setSelectedTreatmentInstance(treatment);
        setSelectedTreatment(TREATMENTS.filter(t => t.name === treatment.name)[0]);
        setEditMode(true);
    }

    const onClickDelete = (type, treatment) => {
        if (type === "surgery") {
            const newSurgeries = surgeries.filter(t => t.id !== treatment.id);
            setSurgeries(newSurgeries);
            save(newSurgeries, radiotherapies, drugs);
        } else if (type === "radiotherapy") {
            const newRadiotherapies = radiotherapies.filter(t => t.id !== treatment.id);
            setRadiotherapies(newRadiotherapies);
            save(surgeries, newRadiotherapies, drugs);
        } else {
            const newDrugs = drugs.filter(t => t.id !== treatment.id);
            setDrugs(newDrugs);
            save(surgeries, radiotherapies, newDrugs);
        }
    }

    const save = (surgeries, radiotherapies, drugs) => {
        handleProfileUpdate({
            userSurgeries: surgeries,
            userDrugs: drugs,
            userRadiotherapies: radiotherapies,
        });
    }
    
    return    (
        <Box sx={{ width: '100%', maxWidth: '1000px'}}>
        <Autocomplete
            disablePortal
            options={TREATMENTS.sort((a, b) => b.group.localeCompare(a.group))}
            groupBy={(option) => option.group}
            getOptionLabel={(option) => option.name || ""}
            onChange= {onTreatmentChange}
            value={selectedTreatment}
            sx={{ width: 300, display: 'inline-block'}}
            size="small"
            renderInput={(params) => <TextField {...params} label="Select a treatment to add" />}
        />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        { toAddTreatment && <Button size="small" variant="contained" onClick={onClickAdd} sx={{verticalAlign: 'bottom', textTransform: "none"}}>Add</Button>}

        {editMode && <TreatmentModal 
                editMode={editMode} 
                selectedTreatment={selectedTreatment} 
                selectedTreatmentInstance={selectedTreatmentInstance}
                handleTreatmentEditSave={handleTreatmentEditSave} 
                handleTreatmentEditCancel={handleTreatmentEditCancel}/>}
        <p/>
        { surgeries.length > 0 && 
         <>
         <div><GppMaybeIcon fontSize="small" /> Surgeries / Procedures</div>
         <TableContainer>
         <Table sx={{ width: 1000, 'td, th': {fontSize: "1rem"} }}>
           <TableHead>
             <TableRow key="dd">         
               <TableCell align="left" sx={{width: '200px'}}>Name</TableCell>
               <TableCell align="left" sx={{width: '200px'}}>Condition treated</TableCell>
               <TableCell align="left" sx={{width: '200px'}}>Date</TableCell>
               <TableCell sx={{width: '200px'}}></TableCell>
               <TableCell sx={{width: '200px'}}></TableCell>
             </TableRow>
           </TableHead>
           <TableBody sx={{ 'td, th': { border: 0 } }}>
               { surgeries.map(surgery =>                
                    <TableRow key={"surgery" + surgery.id}>
                        <TableCell align="left" component="th" scope="row">{surgery.name}</TableCell>
                        <TableCell align="left">prostate cancer</TableCell>
                        <TableCell align="left">{surgery.date}</TableCell>
                        <TableCell></TableCell>
                        <TableCell align="left">
                            <Button size="small" variant="contained" onClick={() => onClickEdit("surgery", surgery)} sx={{verticalAlign: 'bottom', textTransform: "none"}}>Edit</Button>&nbsp;&nbsp;
                            <Button size="small" variant="contained" onClick={() => onClickDelete("surgery", surgery)} sx={{verticalAlign: 'bottom', textTransform: "none"}}>Delete</Button>
                        </TableCell>
                    </TableRow>
                )}
           </TableBody>
         </Table>
       </TableContainer>  
        </>}
        { surgeries.length > 0 && radiotherapies.length > 0 && 
        <>
        <p />
        <Divider />
        <p/>
        </>        
        }

        { radiotherapies.length > 0 && 
         <>
         <div><GppMaybeIcon fontSize="small" /> Radiotherapies</div>
         <TableContainer>
         <Table sx={{ width: 1000, 'td, th': {fontSize: "1rem"} }}>
           <TableHead>
             <TableRow key="dd">         
               <TableCell align="left"  sx={{width: '200px'}}>Name</TableCell>
               <TableCell align="left"  sx={{width: '200px'}}>Condition treated</TableCell>
               <TableCell align="left"  sx={{width: '200px'}}>Start date</TableCell>
               <TableCell align="left"  sx={{width: '200px'}}>End date</TableCell>
               <TableCell align="left"  sx={{width: '200px'}}></TableCell>
             </TableRow>
           </TableHead>
           <TableBody sx={{ 'td, th': { border: 0 } }}>
               { radiotherapies.map(radio =>                
                    <TableRow key={"radio" + radio.id}>
                        <TableCell align="left" component="th" scope="row">{radio.name}</TableCell>
                        <TableCell align="left">prostate cancer</TableCell>
                        <TableCell align="left">{radio.startDate}</TableCell>
                        <TableCell align="left">{radio.endDate}</TableCell>
                        <TableCell align="left">
                            <Button size="small" variant="contained" onClick={() => onClickEdit("radiotherapy", radio)} sx={{verticalAlign: 'bottom', textTransform: "none"}}>Edit</Button>&nbsp;&nbsp;
                            <Button size="small" variant="contained" onClick={() => onClickDelete("radiotherapy", radio)} sx={{verticalAlign: 'bottom', textTransform: "none"}}>Delete</Button>
                        </TableCell>
                    </TableRow>
                )}
           </TableBody>
         </Table>
       </TableContainer>  
        </>}

        { radiotherapies.length > 0 && drugs.length > 0 && 
        <>
        <p />
        <Divider />
        <p/>
        </>        
        }

        { drugs.length > 0 && 
         <>
         <div><GppMaybeIcon fontSize="small" /> Medical therapies</div>
         <TableContainer>
         <Table sx={{ width: 1000, 'td, th': {fontSize: "1rem"} }}>
           <TableHead>
             <TableRow key="dd">         
               <TableCell sx={{width: '200px'}}>Name</TableCell>
               <TableCell sx={{width: '200px'}}>Condition treated</TableCell>
               <TableCell sx={{width: '200px'}}>Start date</TableCell>
               <TableCell sx={{width: '200px'}}>End date</TableCell>
               <TableCell sx={{width: '200px'}}></TableCell>
             </TableRow>
           </TableHead>
           <TableBody sx={{ 'td, th': { border: 0 } }}>
               { drugs.map(drug =>                
                    <TableRow key={"drug" + drug.id}>
                        <TableCell component="th" scope="row">{drug.name}</TableCell>
                        <TableCell align="left">prostate cancer</TableCell>
                        <TableCell>{drug.startDate}</TableCell>
                        <TableCell>{drug.endDate ? drug.endDate : ''}</TableCell>
                        <TableCell align="left">
                            <Button size="small" variant="contained" onClick={() => onClickEdit("drug", drug)} sx={{verticalAlign: 'bottom', textTransform: "none"}}>Edit</Button>&nbsp;&nbsp;
                            <Button size="small" variant="contained" onClick={() => onClickDelete("drug", drug)} sx={{verticalAlign: 'bottom', textTransform: "none"}}>Delete</Button>
                        </TableCell>
                    </TableRow>
                )}
           </TableBody>
         </Table>
       </TableContainer>  
        </>}

       </Box>
    ) 
}