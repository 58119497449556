import React from 'react';
import Chip from '@mui/material/Chip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { getFirstCell } from '../utils/UIUtils';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import GenericDetailInfoModal from './GenericDetailInfoModal';
import TreatmentStudyModal from './TreatmentStudyModal';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import EligiblityCriteriaModal from './EligiblityCriteriaModal';
import ContactClinicModal from './ContactClinicModal';
import { UserContext } from '../../App';
import { ThemeProvider } from '@mui/material/styles';
import { trialChipTheme, trialEligibilityTheme } from '../../components/MUIOverrides';
import { getRawToDisplayTreatmentTypeMapping } from './TrialsConstants';
import { styled } from '@mui/material/styles';
import { useHistory } from "react-router-dom";

const buildInstitution = (location) => {
    return `${location.instituteName}, ${location.locationCity}, ${location.locationState}`
}

const getBestLocationIndex = (locations, userContext) => {
    const zipcode = userContext && userContext.userProfile && userContext.userProfile.userDemographics && userContext.userProfile.userDemographics.zipcode ? 
     userContext.userProfile.userDemographics.zipcode: 98115;

    let bestLocationIndex = 0;
    let shortestDistance = 1000000;
    if (locations.length > 1) {
        locations.forEach((location, index) => {
            const distance = Math.abs(parseInt(location.locationZip) - zipcode);
            if ( distance < shortestDistance) {
                bestLocationIndex = index;
                shortestDistance = distance;
            }
        });
        return bestLocationIndex;
    } else if (locations.length === 1) {
        return 0;
    } else {
        return -1; // TODO: if data is clean
    }
}

const buildGroupInfo = (group) => {
    let text = "";
    if (group.groupName != null) {
        text += group.groupName + ". ";
    }
    if (group.groupIntervention != null) {
        text += group.groupIntervention.replace("\n", "; ");;
    }
    return text;
}

const buildTreatmentStudiedInfo = (treatment) => {
    return treatment.treatmentType + ": " + treatment.treatmentStudied;
}

const buildBriefSummary = (text) => {
    const arr = text.split(" ");
    let briefSummary = "";
    for (let i = 0; i < arr.length && i < 30; i++) {
        briefSummary += " " +    arr[i];
    }
    let hasMoreSummaryText = true;
    if (arr.length > 30) {
        hasMoreSummaryText = true;
        briefSummary += " ..."
    }
    return {briefSummary, hasMoreSummaryText}
}

export const buildStudyType = (studyType) => {
    return studyType.split(" ")[0];
}

export const buildStudyPhase = (studyPhases) => {
    if (studyPhases === null || studyPhases.length === 0 || studyPhases[0] === 'Not Applicable') {
        return "Not Applicable";
    }
    if (studyPhases.length === 1) {
        return studyPhases[0];
    } 

    // handle ["Phase 2", "Phase 3"]
    return studyPhases[0] + "/" + studyPhases[1].substring(6);

}


export const buildEligibility = (eligibilityResult) => {
    switch (eligibilityResult.eligibility) {
      case 'LACK_OF_INFO':
            return 'Eligiblity: Profile missing info';
        case 'PROFILE_MATCHED':
            if (eligibilityResult.eligibilityScore > 1) {
                return 'Eligiblity: ' + eligibilityResult.eligibilityScore + ' criteria met';
            } else {
                return 'Eligiblity: 1 criterion met';
            }
        case 'NOT_ELIGIBLE':
             return 'Eligiblity: Not eligible';
        default:            
            return 'Unexpected';
    }    
}

const getEligibilityColor = (eligibility, hasTherapeuticCriteria) => {
    if (eligibility === 'LACK_OF_INFO') {
        return "warning";
    } else if (eligibility === 'PROFILE_MATCHED') {
        return hasTherapeuticCriteria ? "primary" : "secondary";
    } else if (eligibility === 'NOT_ELIGIBLE') {
        return "error"
    } else {
        return "error";
    }
}

export default function Trial({trial}) {

    const userContext = React.useContext(UserContext);
    const history = useHistory();

    const bestLocationIndex = getBestLocationIndex(trial.trialLocations, userContext);
    const institutionLabel = trial.trialLocations.length > 1 ? 'Institutions:' : 'Institution:';
    const treatmentStudiedLabel = trial.treatmentDetails.length > 1 ? "Treatments:" : 'Treatment:';
    const showTreatmentModalOption =  trial.treatmentDetails.length > 1 || trial.treatmentDetails.filter(item => item.about !== '').length > 0;
    const showTreatmentModalText = trial.treatmentDetails.length > 1 ? `View all treatments (${trial.treatmentDetails.length})`: "View regulatory status";
    const {briefSummary, hasMoreSummaryText} = buildBriefSummary(trial.trialIdentification.briefTitle);
    const [showInstitutions, setShowInstitutions] = React.useState(false);
    const [showGroups, setShowGroups] = React.useState(false);
    const [showTreatments, setShowTreatments] = React.useState(false);
    const [showSummary, setShowSummary] = React.useState(false);
    const [showRawEligibilityCriteria, setShowRawEligibilityCriteria] = React.useState(false);
    const [showContactClinic, setShowContactClinic] = React.useState(false);

    const treatmentTypeDisplayNames = new Set(trial.treatmentDetails.map(detail => getRawToDisplayTreatmentTypeMapping()[detail.treatmentType.toLowerCase()]));

    const eligibilityColor = getEligibilityColor(trial.eligibilityResult.eligibility, trial.eligibilityCriteria.hasTherapeuticCriteria);


    const onClickSignIn = () => {
        history.push("/signin");
      }

    const onClickMoreInstitutions = () => {
        setShowInstitutions(true);
    }

    const onClickMoreTreatments = () => {
        setShowTreatments(true);
    }

    const onClickMoreGroups = () => setShowGroups(true);

    const onClickMoreSummary = () => setShowSummary(true);

    const handleInstitutionsClose = () =>  setShowInstitutions(false);

    const handleGroupsClose = () =>  setShowGroups(false);

    const handleTreatmentsClose = () => setShowTreatments(false);
    const handleSummaryClose = () => setShowSummary(false);

    const onClickEligibility = () => setShowRawEligibilityCriteria(true);
    const handleEligiblityClose = () => setShowRawEligibilityCriteria(false);

    const onClickContactTrial = () => setShowContactClinic(true);
    const handleContactClinicClose = () => setShowContactClinic(false);

    const TrialCard = styled(Card)(({ theme }) => ({
        [theme.breakpoints.down('md')]: {
            maxWidth: '90vw', 
        },
        [theme.breakpoints.up('md')]: {
          maxWidth: '600px',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: '750px',
        },
        [theme.breakpoints.up('xl')]: {
            maxWidth: '1100px',
        },
        marginLeft: 1, 
        padding: 10,
        boxShadow: '0px 1px 5px -1px rgba(0,0,0,3), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
      }));

    return (   
        <>
        <TrialCard>
            <ThemeProvider theme={trialChipTheme}>
                <Chip label={buildStudyType(trial.studyType)} color="primary" />&nbsp;&nbsp;
                <Chip label={buildStudyPhase(trial.studyPhases)} color="secondary" />&nbsp;&nbsp;
                <ThemeProvider theme={trialEligibilityTheme}>
                    {
                        userContext && <Chip sx={{float: 'right'}} 
                        color={eligibilityColor} 
                        label={buildEligibility(trial.eligibilityResult)}
                        onDelete={()=>{}} 
                        onClick={onClickEligibility}
                        variant="outlined"
                        deleteIcon={<InfoTwoToneIcon sx={{float: 'right'}} color={eligibilityColor}/>} />
                    }
                    {
                        !userContext && 
                        <><Chip sx={{float: 'right'}} 
                        color="warning"
                        label="Eligibility criteria"
                        onDelete={()=>{}} 
                        onClick={onClickEligibility}
                        variant="outlined"
                        deleteIcon={<InfoTwoToneIcon sx={{float: 'right'}} color="warning"/>} />
                        <Chip sx={{float: 'right', marginRight: 1}} 
                        color="primary" 
                        label="Sign in to view your eligibility"
                        onDelete={()=>{}} 
                        onClick={onClickSignIn}
                        variant="outlined"
                        deleteIcon={<PersonOutlineOutlinedIcon sx={{float: 'right'}} color="info" />} />
                        </>
                    }
                </ThemeProvider>
                {treatmentTypeDisplayNames.size > 0 && 
                    <>
                    {[...treatmentTypeDisplayNames].map(name => <><Chip label={name} color="info" />&nbsp;&nbsp;</>)}
                    </>
                }
            </ThemeProvider>
            
        <p/>

        <TableContainer sx={{ }}>
      <Table key={Math.random()}  sx={{'.MuiTableCell-root':{verticalAlign: 'top'} }} aria-label="simple table">
        <TableBody>
            <TableRow key={Math.random()}  sx={{height: 5}}>
                    {getFirstCell("Brief title:")}
                    <TableCell sx={{border: 0, padding: 0}}>
                        <Typography variant="body1" gutterBottom>{briefSummary}
                        { hasMoreSummaryText && <>&nbsp;&nbsp;<Button variant="text" 
                            onClick={onClickMoreSummary} sx={{textTransform: 'none', padding: 0}}>Show full summary</Button> </>}</Typography>                        
                        </TableCell>
                </TableRow>
            {trial.treatmentDetails.length >= 1 && <>
                <TableRow key={Math.random()}  sx={{height: 5}}>
                    {getFirstCell(treatmentStudiedLabel)}
                    <TableCell sx={{border: 0, padding: 0}}>
                        <Typography variant="body1" gutterBottom>{buildTreatmentStudiedInfo(trial.treatmentDetails[0])} 
                        { showTreatmentModalOption && <>&nbsp;&nbsp;<Button variant="text" 
                            onClick={onClickMoreTreatments} sx={{textTransform: 'none', padding: 0}}>{showTreatmentModalText}</Button> </>}</Typography>
                        </TableCell>
                </TableRow>
                
                </>
            }
            {bestLocationIndex !== -1 && <>
                <TableRow key={Math.random()}  sx={{height: 5}}>
                    {getFirstCell(institutionLabel)}
                    <TableCell sx={{border: 0, padding: 0}}>
                        <Typography variant="body1" gutterBottom>{buildInstitution(trial.trialLocations[bestLocationIndex])} 
                        { trial.trialLocations.length > 1 && <>&nbsp;&nbsp;<Button variant="text" 
                            onClick={onClickMoreInstitutions} sx={{textTransform: 'none', padding: 0}}>View all institutions ({trial.trialLocations.length})</Button> </>}</Typography>                        
                        </TableCell>
                </TableRow>
                </>
            }

        </TableBody>
      </Table>
    </TableContainer>
    <CardActions>
        <Button sx={{textTransform: 'none'}} onClick={onClickContactTrial}>Contact this trial</Button>
      </CardActions>
    </TrialCard>
    { showInstitutions && <GenericDetailInfoModal 
        items={trial.trialLocations} 
        getItemKey={(item) => {return item.institutionName}} 
        getItemContent={(item) => buildInstitution(item)}
        handleGenericDetailInfoModalClose={handleInstitutionsClose} />}
    { showGroups && <GenericDetailInfoModal 
        items={trial.studyGroups} 
        getItemKey={(item) => {return item.groupId}} 
        getItemContent={(item) => buildGroupInfo(item)}
        handleGenericDetailInfoModalClose={handleGroupsClose} />}

    { showTreatments && <TreatmentStudyModal 
        items={trial.treatmentDetails} 
        getItemKey={(item) => buildTreatmentStudiedInfo(item)}
        getItemContent={(item) => buildTreatmentStudiedInfo(item)}
        handleGenericDetailInfoModalClose={handleTreatmentsClose} />}

    { showSummary && <GenericDetailInfoModal 
        items={[trial.trialIdentification.briefSummary]} 
        getItemKey={() => {}}
        getItemContent={(item) => item}
        handleGenericDetailInfoModalClose={handleSummaryClose} />}

    { showRawEligibilityCriteria && <EligiblityCriteriaModal 
        hasTherapeuticCriteria={trial.eligibilityCriteria.hasTherapeuticCriteria}
        criteria={[trial.eligibilityCriteria.originalEligibilityCriteria]} 
        eligibilityResult={trial.eligibilityResult}
        handleEligiblityClose={handleEligiblityClose} />}

    { showContactClinic && <ContactClinicModal 
        trial={trial} 
        buildInstitution={buildInstitution}
        bestLocationIndex={bestLocationIndex}
        handleContactClinicClose={handleContactClinicClose} />}
        </>
    )
};