import React from 'react';
import Button from '@mui/material/Button';
import { useHistory, useLocation } from "react-router-dom";
import { Hidden } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { Image } from 'mui-image'


const LogoMenu = () => {

    const history = useHistory();
    const location = useLocation();
    
    const getPathname = () => {
        return location.pathname;
    }

    const onClickHome = () => {
      if (getPathname() !== "/") {
        history.push("/");
      }
  
    }
 
  
    const LogoButton = styled(Button)(({ theme }) => ({
      [theme.breakpoints.up('lg')]: {
        fontSize: '24px',
      },
      [theme.breakpoints.between('md', 'lg')]: {
        fontSize: '20px',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '18px',
      },
      padding: 0, 
      minWidth: 0,  
      textTransform: 'none'
    }));

    return (
        <Table>
              <TableBody sx={{ 'td, th': { border: 0, padding: 0 } }}>
                  <TableRow key="logosection">
                      <Hidden mdUp>
                      
                      <TableCell component="th" scope="row" align="left"  >              
                        <Button onClick={onClickHome} sx={{padding: 0, minWidth: 0}}><Image src="logo_only.png" height="40%" width="40%" fit="scale-down" duration="0"/></Button>
                      </TableCell>
                      </Hidden>
                      <TableCell align="left">
                      <Hidden mdDown>
                        <LogoButton color="success" onClick={onClickHome}><Image src="logo_with_name.png" height="60%" width="60%" fit="scale-down" duration="0" /></LogoButton>
                      </Hidden>
                      
                      </TableCell>
                  </TableRow>
              </TableBody>
          </Table>   
    )
}

export default LogoMenu;