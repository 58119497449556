import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';

export const getFirstCell = (text, toolTipText=null, width="110px") => {
    if (toolTipText) {
        text = text.substring(0, text.length - 2);
    }
    return <TableCell 
                align="right" 
                sx={{border: 0, paddingLeft: 0, paddingRight: '10px', paddingTop: 0, paddingBottom: 0, width: width}}>
                    <Typography variant="body1" gutterBottom>{text} {toolTipText && <Tooltip title={toolTipText}><InfoOutlinedIcon fontSize="small" sx={{verticalAlign: "bottom"}}/></Tooltip>} </Typography>
              </TableCell>
}