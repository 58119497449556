import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import Demographics from './demographics/Demographics';
import Biopsies from './biopsies/Biopsies';
import Conditions from './conditions/Conditions';
import ImageTests from './imageTests.js/ImageTests';
import LabTests from './labTests/LabTests';
import Treatments from './treatments/Treatments';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import Header from '../layouts/Header';
import StatusModalPage1 from './StatusModalPage1';
import StatusModalPage2 from './StatusModalPage2';
import { useHistory } from "react-router-dom";
import { UserContext } from '../../App';
import SignInHandler from '../signin/SignInHandler';
import CircularProgress from '@mui/joy/CircularProgress';
import { getEndpoint } from '../utils/BackendAppConfig';
import { Hidden } from '@mui/material';
import { lime } from '@mui/material/colors';
import { hasSignedIn } from '../utils/CognitoUtil';


const drawerWidth = 240;

function ProfileMain({handleAuthSuccess, handleSignOut}) {
  const history = useHistory();
  const userContext = React.useContext(UserContext);


  if (!userContext && !hasSignedIn()) {
    history.push('/');
  } 

  const [selectedNav, setSelectedNav] = React.useState('Demographics');
  const [showStatusCheck1, setShowStatusCheck1] = React.useState(true);
  const [showStatusCheck2, setShowStatusCheck2] = React.useState(false);

  const handleClick = (text) => {
    setSelectedNav(text);
  }

  const handleStatusCheck1Update = (newProfile) => {
    setShowStatusCheck1(false);
    setShowStatusCheck2(true);
    handleProfileUpdate(newProfile);
  }

  const handleStatusCheck2Update = (newProfile) => {
    setShowStatusCheck2(false);
    handleProfileUpdate(newProfile);
  }

  const handleStatusCheckBackTo1 = () => {
    setShowStatusCheck1(true);
    setShowStatusCheck2(false);
  }

  const handleProfileUpdate = (newProfile) => {
    const updatedProfile = Object.assign(userContext.userProfile, newProfile)
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${userContext.jwtToken}`
      },
      body: JSON.stringify(updatedProfile)
    };
    fetch(getEndpoint("updateUserProfile"), requestOptions)
      .then(response => response.json())
      .then(data => {
        // if token expired, refresh token
        if (!data.error) {
          handleAuthSuccess({
            userProfile: updatedProfile,
          });
        } else {
          // TODO: display error message
          console.error(data);
        }      
      })
      .catch(error => console.error(error));    
    
   
  }

  const conditionalRendering = () => {
    switch (selectedNav) {
      case 'Demographics':
        return <Demographics profile={userContext.userProfile} handleProfileUpdate={handleProfileUpdate} />
      case 'Conditions':
        return <Conditions profile={userContext.userProfile} handleProfileUpdate={handleProfileUpdate} />
      case 'Lab tests': 
        return <LabTests profile={userContext.userProfile} handleProfileUpdate={handleProfileUpdate} />
      case 'Tumor stage':
        return <ImageTests profile={userContext.userProfile} handleProfileUpdate={handleProfileUpdate} />
      case 'Tumor biopsy':
        return <Biopsies profile={userContext.userProfile} handleProfileUpdate={handleProfileUpdate} />
      case 'Treatment history':
        return <Treatments profile={userContext.userProfile} handleProfileUpdate={handleProfileUpdate} />
      default:
         return <div>error</div>
      }
  }
  return (
    <>
    <Header handleSignOut={handleSignOut} />
    <SignInHandler handleAuthSuccess={handleAuthSuccess} />
    <Hidden smUp>
          <Box sx={{backgroundColor: lime[500], margin: 1, maxWidth: '90vw', padding: 1}}>
          This page is not accessible in mobile device. Please edit profile using a tablet or a larger screen.
          </Box>
    </Hidden>
    <Hidden smDown>
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer
        sx={{
          width: drawerWidth,
          top: 'none',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            top: 'auto',
          },
          '& .MuiToolbar-root': {
            display: 'none',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar />
        <Divider />
        <List>
          {['Demographics', 'Conditions', 'Lab tests', 'Tumor stage', 'Tumor biopsy', 'Treatment history'].map((text, index) => (
            <ListItem key={text} disablePadding>
              {text === selectedNav && (
              <ListItemButton selected onClick={() => handleClick(text)}>
                <ListItemIcon>
                  <ArrowCircleRightIcon />
                </ListItemIcon>
                <ListItemText selected primary={text} sx={{ 'span': {fontWeight: 'bold'}}}/>
              </ListItemButton>
              )
              }
              {text !== selectedNav && (
                <ListItemButton onClick={() => handleClick(text)}>
                  <ListItemIcon>
                    <ArrowCircleRightOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText selected primary={text} sx={{}}/>
                </ListItemButton>
                )
              }
            </ListItem>
          ))}
        </List>
      </Drawer>

      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3, marginLeft: '100px'}}
      >
        {!userContext && <CircularProgress variant="solid" color="neutral" thickness={5} sx={{ '--CircularProgress-size': '50px', marginLeft: '50px' }}/> }
        {userContext && conditionalRendering()}
      </Box>
      {userContext && showStatusCheck1 && <StatusModalPage1 profile={userContext.userProfile} handleStatusCheck1Update={handleStatusCheck1Update} />}
      {userContext && showStatusCheck2 && <StatusModalPage2 profile={userContext.userProfile} handleStatusCheck2Update={handleStatusCheck2Update} handleStatusCheckBackTo1={handleStatusCheckBackTo1} />}
    </Box>
    </Hidden>
    </>
  );
}

export default ProfileMain;
