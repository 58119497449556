const endpoints = {
    "prod": {
        "queryUserProfile" : "https://ivtp7bta1l.execute-api.us-west-2.amazonaws.com/prod/query/userProfiles",
        "queryClinicalTrials" : "https://ivtp7bta1l.execute-api.us-west-2.amazonaws.com/prod/query/clinicalTrials",
        "listClinicalTrials" : "https://ivtp7bta1l.execute-api.us-west-2.amazonaws.com/prod/list/clinicalTrials",
        "queryStandardOfCares" : "https://ivtp7bta1l.execute-api.us-west-2.amazonaws.com/prod/query/socs",
        "listStandardOfCares" : "https://ivtp7bta1l.execute-api.us-west-2.amazonaws.com/prod/list/socs",
        "updateUserProfile" : "https://ivtp7bta1l.execute-api.us-west-2.amazonaws.com/prod/update/userProfiles",
        "sendEmail" : "https://ivtp7bta1l.execute-api.us-west-2.amazonaws.com/prod/send/email",
        "sendunAuthenticatedEmail" : "https://ivtp7bta1l.execute-api.us-west-2.amazonaws.com/prod/send/unAuthenticatedEmail"
    },
    "staging": {
        "queryUserProfile" : "https://m47cqumk83.execute-api.us-west-2.amazonaws.com/beta/query/userProfiles",
        "queryClinicalTrials" : "https://m47cqumk83.execute-api.us-west-2.amazonaws.com/beta/query/clinicalTrials",
        "listClinicalTrials" : "https://m47cqumk83.execute-api.us-west-2.amazonaws.com/beta/list/clinicalTrials",
        "queryStandardOfCares" : "https://m47cqumk83.execute-api.us-west-2.amazonaws.com/beta/query/socs",
        "listStandardOfCares" : "https://m47cqumk83.execute-api.us-west-2.amazonaws.com/beta/list/socs",
        "updateUserProfile" : "https://m47cqumk83.execute-api.us-west-2.amazonaws.com/beta/update/userProfiles",
        "sendEmail" : "https://m47cqumk83.execute-api.us-west-2.amazonaws.com/beta/send/email",
        "sendunAuthenticatedEmail" : "https://m47cqumk83.execute-api.us-west-2.amazonaws.com/beta/send/unAuthenticatedEmail"
    },
    "local": {
        "queryUserProfile" : "https://ivtp7bta1l.execute-api.us-west-2.amazonaws.com/prod/query/userProfiles",
        "queryClinicalTrials" : "https://ivtp7bta1l.execute-api.us-west-2.amazonaws.com/prod/query/clinicalTrials",
        "listClinicalTrials" : "https://ivtp7bta1l.execute-api.us-west-2.amazonaws.com/prod/list/clinicalTrials",
        "queryStandardOfCares" : "https://ivtp7bta1l.execute-api.us-west-2.amazonaws.com/prod/query/socs",
        "listStandardOfCares" : "https://ivtp7bta1l.execute-api.us-west-2.amazonaws.com/prod/list/socs",
        "updateUserProfile" : "https://ivtp7bta1l.execute-api.us-west-2.amazonaws.com/prod/update/userProfiles",
        "sendEmail" : "https://ivtp7bta1l.execute-api.us-west-2.amazonaws.com/prod/send/email",
        "sendunAuthenticatedEmail" : "https://ivtp7bta1l.execute-api.us-west-2.amazonaws.com/prod/send/unAuthenticatedEmail"
    }
};

const getEndpoint = (key) => endpoints[process.env.REACT_APP_ENV_STAGE][key];


export {getEndpoint};