import React from 'react';
import Chip from '@mui/material/Chip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { getFirstCell } from '../utils/UIUtils';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import GenericDetailInfoModal from '../trials/GenericDetailInfoModal';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { UserContext } from '../../App';
import { ThemeProvider } from '@mui/material/styles';
import { trialChipTheme, trialEligibilityTheme } from '../../components/MUIOverrides';
import { getRawToDisplayTreatmentTypeMapping } from '../trials/TrialsConstants';
import { styled } from '@mui/material/styles';
import { useHistory } from "react-router-dom";
import { RECOMMENDATION_MAP } from './StandardOfCaresConstants';
import SideEffectModal from './SideEffectModal';
import GuidelineModel from './GuidelineModal';
import Link from '@mui/material/Link';

export const buildEligibility = (eligibilityResult) => {
    if (!eligibilityResult) {
        return 'Unexpected';
    }

    switch (eligibilityResult.eligibility) {
      case 'LACK_OF_INFO':
            return 'Profile missing info';
        case 'PROFILE_MATCHED':
            return 'Profile matched';
        case 'NOT_ELIGIBLE':
             return 'Not suitable';
        default:            
            return 'Unexpected';
    }    
}

const getEligibilityColor = (eligibility, hasTherapeuticCriteria) => {
    if (eligibility === 'LACK_OF_INFO') {
        return "warning";
    } else if (eligibility === 'PROFILE_MATCHED') {
        return "primary";
    } else if (eligibility === 'NOT_ELIGIBLE') {
        return "error"
    } else {
        return "error";
    }
}

export default function StandardOfCare({standardOfCare}) {

    const userContext = React.useContext(UserContext);
    const history = useHistory();

    const treatmentTypeDisplayNames = new Set(standardOfCare.treatmentDetails.map(detail => getRawToDisplayTreatmentTypeMapping()[detail.treatmentType]));
    const eligibilityColor = getEligibilityColor(
        standardOfCare.eligibilityResult ? standardOfCare.eligibilityResult.eligibility : null,
        standardOfCare.eligibilityResult ? standardOfCare.eligibilityResult.hasTherapeuticCriteria : null);
    const [showDataSources, setShowDataSources] = React.useState(false);
    const [showSupportingStudies, setShowSupportingStudies] = React.useState(false);
    const [showSideEffect, setShowSideEffect] = React.useState(false);   
    const [showGuideline, setShowGuideline] = React.useState(false);


    const onClickSignIn = () => {
        history.push("/signin");
    }

    const onClickMoreDataSources = () => {
        setShowDataSources(true);
    }

    const handleDataSourcesClose = () => {
        setShowDataSources(false);
    }

    const onClickMoreSupportingStudies = () => {
        setShowSupportingStudies(true);
    }

    const handleSupportingStudiesClose = () => {
        setShowSupportingStudies(false);
    }

    const onClickViewSideEffect = () => {
        setShowSideEffect(true);
    }

    const handleSideEffectClose = () => {
        setShowSideEffect(false);
    }

    const onClickEligibility = () => {
        setShowGuideline(true);
    }

    const handleGuidelineClose = () => {
        setShowGuideline(false);
    }

    const StandardOfCareCard = styled(Card)(({ theme }) => ({
        [theme.breakpoints.down('md')]: {
            maxWidth: '90vw',
        },
        [theme.breakpoints.up('md')]: {
          maxWidth: '600px',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: '750px',
        },
        [theme.breakpoints.up('xl')]: {
            maxWidth: '1100px',
        },
        marginLeft: 1, 
        padding: 10,
        boxShadow: '0px 1px 5px -1px rgba(0,0,0,3), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
      }));

    const dataSources = standardOfCare.guidelines.map(g => g.organization + " (" + g.publicationDate.substring(0, 4) + ")");

    return (   
        <>
        <StandardOfCareCard>
            <ThemeProvider theme={trialChipTheme}>
                { userContext && standardOfCare.eligibilityResult && standardOfCare.eligibilityResult.eligibility === 'PROFILE_MATCHED' && 
                    <><Chip label={RECOMMENDATION_MAP[standardOfCare.recommendation]} color="primary" />&nbsp;&nbsp; </>
                }
                {treatmentTypeDisplayNames.size > 0 && 
                    <>
                    {[...treatmentTypeDisplayNames].map(name => <><Chip label={name} color="info" />&nbsp;&nbsp;</>)}
                    </>
                }
                <ThemeProvider theme={trialEligibilityTheme}>
                    {
                        userContext && standardOfCare.eligibilityResult && <Chip sx={{float: 'right'}} 
                        color={eligibilityColor} 
                        label={buildEligibility(standardOfCare.eligibilityResult)}
                        onDelete={()=>{}} 
                        onClick={onClickEligibility}
                        variant="outlined"
                        deleteIcon={<InfoTwoToneIcon sx={{float: 'right'}} color={eligibilityColor}/>} />
                    }
                    {
                        (!userContext || !standardOfCare.eligibilityResult) && 
                        <>
                        <Chip sx={{float: 'right', marginRight: 1}} 
                        color="primary" 
                        label="Sign in to view your eligibility"
                        onDelete={()=>{}} 
                        onClick={onClickSignIn}
                        variant="outlined"
                        deleteIcon={<PersonOutlineOutlinedIcon sx={{float: 'right'}} color="info" />} />
                        </>
                    }
                </ThemeProvider>

            </ThemeProvider>
            
        <p/>

        <TableContainer sx={{ }}>
      <Table key={Math.random()}  sx={{'.MuiTableCell-root':{verticalAlign: 'top'} }} aria-label="simple table">
        <TableBody>
            <TableRow key={Math.random()}  sx={{height: 5}}>
                    {getFirstCell("Treatment:")}
                    <TableCell sx={{border: 0, padding: 0}}>
                        <Typography variant="body1" gutterBottom>{standardOfCare.treatmentDetails.map(detail => detail.treatmentStudied).join(' + ')}</Typography>                        
                        </TableCell>
                </TableRow>
            <TableRow key={Math.random()}  sx={{height: 5}}>
                    {getFirstCell("Data source:")}
                    <TableCell sx={{border: 0, padding: 0}}>
                        <Typography variant="body1" gutterBottom>{dataSources[0]}{ dataSources.length > 1 && <>&nbsp;&nbsp;<Button variant="text" 
                            onClick={onClickMoreDataSources} sx={{textTransform: 'none', padding: 0}}>View all data sources ({dataSources.length})</Button> </>}</Typography>                        
                    </TableCell>
            </TableRow>
            <TableRow key={Math.random()}  sx={{height: 5}}>
                    {getFirstCell("Studies:")}
                    <TableCell sx={{border: 0, padding: 0}}>
                        <Typography variant="body1" gutterBottom>
                            { standardOfCare.studies.length > 0 ? 
                                <Link href={"https://doi.org/" + standardOfCare.studies[0].doi} underline="none" target="_blank" rel="noopener">
                                    {standardOfCare.studies[0].briefTitle}
                                </Link> : "N/A"}
                            { standardOfCare.studies.length > 1 && <>&nbsp;&nbsp;(<Button variant="text" 
                            onClick={onClickMoreSupportingStudies} sx={{textTransform: 'none', padding: 0}}>View all supporting studies </Button>) </>}</Typography>                        
                    </TableCell>
            </TableRow>

        </TableBody>
      </Table>
    </TableContainer>
    <CardActions>
        {userContext && standardOfCare.studies && standardOfCare.studies.length > 0 && 
            <Button sx={{textTransform: 'none'}} onClick={onClickViewSideEffect}>View side effects</Button>
        }
        {
            !userContext && standardOfCare.studies && standardOfCare.studies.length > 0 && 
            <Button sx={{textTransform: 'none'}} onClick={onClickSignIn}>Sign in to view side effects</Button>
        }
      </CardActions>

    </StandardOfCareCard>
    { showDataSources && <GenericDetailInfoModal 
        items={dataSources} 
        getItemKey={item => item} 
        getItemContent={item => item}
        handleGenericDetailInfoModalClose={handleDataSourcesClose} />}
    { showSupportingStudies && <GenericDetailInfoModal 
        items={standardOfCare.studies} 
        getItemKey={item => item.briefTitle} 
        getItemContent={item => <Link href={"https://doi.org/" + item.doi} underline="none" target="_blank" rel="noopener">
        {item.briefTitle}
      </Link> }
        handleGenericDetailInfoModalClose={handleSupportingStudiesClose} />}
    {
        showSideEffect && <SideEffectModal studies={standardOfCare.studies} handleSideEffectClose={handleSideEffectClose}></SideEffectModal>
    }
    {
        showGuideline && <GuidelineModel guideline={standardOfCare.guidelines[0]} handleGuidelineClose={handleGuidelineClose} eligibility={standardOfCare.eligibilityResult.eligibility}></GuidelineModel>
    }

        </>
    )
};
