import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import TreatmentSelection from './TreatmentSelection';
import Trials from './Trials';
import CircularProgress from '@mui/joy/CircularProgress';
import TrialFilter from './TrialFilter';
import Header from '../layouts/Header';
import { UserContext } from '../../App';
import SignInHandler from '../signin/SignInHandler';
import { getEndpoint } from '../utils/BackendAppConfig';
import { Hidden } from '@mui/material';
import { styled } from '@mui/material/styles';
import { lime } from '@mui/material/colors';
import { TREATMENT_TYPE_MAP } from './TrialsConstants';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import { parseSelectedCondition } from '../utils/Util';

export default function TrialsMain({handleAuthSuccess, handleSignOut}) {
      const history = useHistory();
      const conditionName = parseSelectedCondition(history);
      const conditionNameQueryValue = conditionName.replace(/\s+/g, '_')

      const initialFilterValues = {
        eligible: true,
        noteligible: true,
        lackofinfo: true,
        observational: true,
        interventional: true,
        expandedaccess: true,
        phase1: true,
        phase2: true,
        phase3: true,
        phase4: true,
        phasena: true,
    }

    Object.keys(TREATMENT_TYPE_MAP).forEach( treatmentShortName => initialFilterValues[treatmentShortName] = true );

    const userContext = React.useContext(UserContext);
  
    const TrialsBox = styled(Box)(({ theme }) => ({
      [theme.breakpoints.down('lg')]: {
        marginLeft: '10px',
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '20px',
    },
      flexGrow: 1, 
      bgcolor: 'background.default', 
      p: 3,
    }));

    React.useEffect(() => {
      const fetchTrials = (requestOptions, uriPrefix, trialsHolder, pageNumber=0, retryCount=0) => {
        fetch(
          `${uriPrefix}pageSize=200&pageNumber=${pageNumber}`,
          requestOptions,
        )
        .then(response => response.json())
        .then(data => {
          if (!data.clinicalTrialList && retryCount < 3) {
            console.log("Encounters timeout. Fetching trials again. ");
            setTimeout(() => { fetchTrials(requestOptions, uriPrefix, trialsHolder,  pageNumber, retryCount+1); }, 1000);          
          }
          trialsHolder.push.apply(trialsHolder, data.clinicalTrialList);
          if (data.hasNextPage) {
            fetchTrials(requestOptions, uriPrefix, trialsHolder,  pageNumber + 1);
          } else {
            const treatmentTypes = new Set();
            trialsHolder.forEach(trial => {
              trial.treatmentDetails.forEach( detail => {
                treatmentTypes.add(detail.treatmentType);
              })

            })
            parseAndSetTreatmentNames(trialsHolder);
            setTrials(trialsHolder);
            setFilteredTrials(trialsHolder);
          }        
  
        })
        .catch(error => {
          if (retryCount < 3) {
            console.log("Encounters timeout. Fetching trials again. ");
            setTimeout(() => { fetchTrials(requestOptions, uriPrefix, trialsHolder,  pageNumber, retryCount+1); }, 1000);
          }      
          console.log(error);
        })
  
      }

      if (userContext) {
        console.log("fetching with user id");
        const username = userContext.userProfile.userIdentification.userProfileId;
        const requestOptions = {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${userContext.jwtToken}`
          },
        };
        const trialsHolder = []
        fetchTrials(
          requestOptions, 
          `${getEndpoint("queryClinicalTrials")}?userProfileId=${username}&condition=${conditionNameQueryValue}&`,
          trialsHolder,
          0,
        );

      } else {
        console.log("fetching without user id");
        const requestOptions = {
          method: 'GET',
        };
        const trialsHolder = []
        fetchTrials(
          requestOptions, 
          `${getEndpoint("listClinicalTrials")}?condition=${conditionNameQueryValue}&`,
          trialsHolder,
          0,
        );
      }

    }, [userContext, conditionNameQueryValue]);

    const [treatmentName, setTreatmentName] = React.useState(null);
    const [trials, setTrials] = React.useState(null);
    const [treatmentNames, setTreatmentNames] = React.useState([]);
    const [filters, setFilters] = React.useState(initialFilterValues);
    const [filteredTrials, setFilteredTrials] = React.useState(null);

    const parseAndSetTreatmentNames = (trials) => {
      const result = [];
      trials.forEach(trial => {
        trial.treatmentDetails.forEach(detail => {
          detail.typeahead.forEach(ele => {
            result.push(ele);
          })
        })
      });

      setTreatmentNames([...new Set(result)].sort());

    }

    const setFilteredTrialsCallBack = (trialList) => {
        if (trialList && filteredTrials && trialList.length !== filteredTrials.length) {
            setFilteredTrials(trialList)
        }
    }

    const handleTreatmentChange = (name) => {
      setTreatmentName(name);
    }

    const handleFiltersUpdate = (updatedFilters) => setFilters(updatedFilters);

    const onClickInterestedIn = () => {
      history.push({
        pathname: 'standardOfCare',
        search: '?condition=' + conditionName
      })
    }

    return (
      <>
      
      <Header handleSignOut={handleSignOut} />
      <SignInHandler handleAuthSuccess={handleAuthSuccess} />
      <Grid container spacing={0}>
          <Grid item lg={1} xs={0} />
          <Grid item lg={10} xs={12}>
            <Box sx={{ display: 'flex'}}>
              <CssBaseline />
              <Hidden smDown>
             
                  <TrialFilter filters={filters} handleFiltersUpdate={handleFiltersUpdate} />
                
              </Hidden>

        
      
            <TrialsBox
              component="main"
            >
              <Hidden smUp>
              <Box sx={{backgroundColor: lime[500], margin: 1, maxWidth: '90vw', padding: 1}}>
              This page is not optimized for mobile devices. For the best experience and access to advanced filtering options, please use a tablet or laptop.
              </Box>
              </Hidden>
            { (treatmentNames.length === 0 || trials == null) && <>
              <CircularProgress variant="solid" color="neutral" thickness={5} sx={{ '--CircularProgress-size': '50px', marginLeft: '50px' }}/>
              <p/>Loading clinical trials ...
              </>}
              {treatmentNames.length > 0 && filteredTrials && <>
              <Box sx={{margin: 1, width: {
                xs: '90vw', 
                sm: '90vw',
                md: 400, 
                lg: 800, 
                xl: 800, 
              }}}>
                <Typography variant='body1'>Found <b>{filteredTrials.length}</b> recruiting studies on <b>{conditionName}.</b>&nbsp;&nbsp;
                <Button variant="text" 
                            onClick={onClickInterestedIn} sx={{textTransform: 'none', padding: 0, marginTop: -0.3, fontSize: '1rem'}}>Interested in standard of care options?</Button>
                </Typography>
              </Box>
              <TreatmentSelection treatmentName={treatmentName} handleTreatmentChange={handleTreatmentChange} treatmentNames={treatmentNames}/>
              <p/>
              <Trials allTrials={trials} treatmentName={treatmentName} filters={filters} setFilteredTrialsCallBack={setFilteredTrialsCallBack}/>
              </>}

            </TrialsBox>
          </Box>
      </Grid>
      <Grid item lg={1} xs={0}/>
    </Grid>
      
  </>
    );
  }
  