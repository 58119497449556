export const TREATMENT_TYPE_MAP = {
    "surgery": {
        rawName: "procedure",
        displayName: "Procedure/Surgery",
    },
    "radiation": {
        rawName: "radiation",
        displayName: "Radiation",
    },
    "drug": {
        rawName: "drug",
        displayName: "Drug",
    },
    "biological": {
        rawName: "biological",
        displayName: "Biological Product",
    },
    "diagnostic": {
        rawName: "diagnostic test",
        displayName: "Diagnostic Test",
    },
    "device": {
        rawName: "device",
        displayName: "Medical Device",
    },
    "genetic": {
        rawName: "genetic",
        displayName: "Genetic Test",
    },
    "dietary": {
        rawName: "dietary supplement",
        displayName: "Dietary Supplement",
    },
    "behavioral": {
        rawName: "behavioral",
        displayName: "Behavioral",
    },
    "combination": {
        rawName: "combination product",
        displayName: "Combination Product",
    },
    "other": {
        rawName: "other",
        displayName: "Other",
    },
}

let rawToDisplayTreatmentTypeMapping = {};
export const getRawToDisplayTreatmentTypeMapping = () => {
    if (Object.keys(rawToDisplayTreatmentTypeMapping).length === 0) {
        Object.entries(TREATMENT_TYPE_MAP).forEach(entry => 
            rawToDisplayTreatmentTypeMapping[entry[1].rawName] = entry[1].displayName);
    }
    return rawToDisplayTreatmentTypeMapping
};

