import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import awsExports from '../../aws-exports';
import awsExports_staging from '../../aws-exports-staging';
import awsExports_local from '../../aws-exports-local';
import { useHistory } from "react-router-dom";

const stage = process.env.REACT_APP_ENV_STAGE;

if (stage === 'prod') {
    Amplify.configure(awsExports);
} else if (stage === 'staging') {
    Amplify.configure(awsExports_staging);
} else {
    // local
    Amplify.configure(awsExports_local);
}

// TODO: cognito sign in does not handle callback properly
// google sign in works fine. cognito email does not
function SignInWizard({signOut, user}) {
    const history = useHistory();
    history.push({
        pathname: '/'
    })
   
}

export default withAuthenticator(SignInWizard);